export const FilterdData = (state, data, filed) => {
    
    if (state?.ACTIVEFILTER == "All") {
        const sortedData = data.sort((a, b) => {
            const timestampA = parseInt(a[filed]);
            const timestampB = parseInt(b[filed]);
        
            // Validate timestamps
            if (isNaN(timestampA) || isNaN(timestampB)) return 0;
        
            // Convert timestamps to Date objects
            const dateA = new Date(timestampA.toString().length > 10 ? timestampA : timestampA * 1000);
            const dateB = new Date(timestampB.toString().length > 10 ? timestampB : timestampB * 1000);
        
            // Validate dates
            if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) return 0;
        
            return dateB - dateA; // Descending order
        });
        
        return sortedData
    }
    else if (state?.ACTIVEFILTER == "Today") {
        const today = new Date().toISOString().split('T')[0];
        const filteredData = data?.filter(item => {
            const timestamp = parseInt(item[filed]);
            if (isNaN(timestamp)) return false; // Skip invalid timestamps

            const date = new Date(timestamp.toString().length > 10 ? timestamp : timestamp * 1000);
            return !isNaN(date.getTime()) && date.toISOString().split('T')[0] === today;
        });

        const sortedData = filteredData.sort((a, b) => {
            const dateA = new Date(parseInt(a[filed]));
            const dateB = new Date(parseInt(b[filed]));

            return (isNaN(dateB.getTime()) ? 0 : dateB) - (isNaN(dateA.getTime()) ? 0 : dateA);
        });

        return sortedData
        return [];
    }
    else if (state?.ACTIVEFILTER == "Yeasterday") {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        const yesterdayStr = yesterday.toISOString().split('T')[0];
        const filteredData = data?.filter(item => {
            const timestamp = parseInt(item[filed]);
            if (isNaN(timestamp)) return false; // Skip invalid timestamps

            const date = new Date(timestamp.toString().length > 10 ? timestamp : timestamp * 1000);
            return !isNaN(date.getTime()) && date.toISOString().split('T')[0] === yesterdayStr;
        });

        const sortedData = filteredData.sort((a, b) => {
            const dateA = new Date(parseInt(a[filed]));
            const dateB = new Date(parseInt(b[filed]));

            return (isNaN(dateB.getTime()) ? 0 : dateB) - (isNaN(dateA.getTime()) ? 0 : dateA);
        })
        return sortedData
    }
    else if (state?.ACTIVEFILTER == "Last 7 Days") {
        const sevenDaysAgo = new Date();
        sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 6); // Get date 7 days ago
        const sevenDaysAgoStr = sevenDaysAgo?.toISOString().split('T')[0];
        const filteredData = data?.filter(item => {
            const timestamp = parseInt(item[filed]);
            if (isNaN(timestamp)) return false; // Skip invalid timestamps

            const date = new Date(timestamp.toString().length > 10 ? timestamp : timestamp * 1000);
            if (isNaN(date.getTime())) return false; // Skip invalid dates

            return date.toISOString().split('T')[0] >= sevenDaysAgoStr;
        });

        const sortedData = filteredData.sort((a, b) => {
            const dateA = new Date(parseInt(a[filed]));
            const dateB = new Date(parseInt(b[filed]));

            return (isNaN(dateB.getTime()) ? 0 : dateB) - (isNaN(dateA.getTime()) ? 0 : dateA);
        });

        return sortedData
    }
    else if (state?.ACTIVEFILTER == "This Month") {
        const today = new Date();
        const currentMonth = today.getMonth(); // Get current month (0-based index)
        const currentYear = today.getFullYear(); // Get current year
        const filteredData = data?.filter(item => {
            const timestamp = parseInt(item[filed]);
            if (isNaN(timestamp)) return false; // Skip invalid timestamps

            const date = new Date(timestamp.toString().length > 10 ? timestamp : timestamp * 1000);
            if (isNaN(date.getTime())) return false; // Skip invalid dates

            return date.getMonth() === currentMonth && date.getFullYear() === currentYear;
        });

        const sortedData = filteredData.sort((a, b) => {
            const dateA = new Date(parseInt(a[filed]));
            const dateB = new Date(parseInt(b[filed]));

            return (isNaN(dateB.getTime()) ? 0 : dateB) - (isNaN(dateA.getTime()) ? 0 : dateA);
        });

        return sortedData
    }
    else if (state?.ACTIVEFILTER == "Last Month") {
        const today = new Date();
        let lastMonth = today.getMonth() - 1; // Get last month
        let lastMonthYear = today.getFullYear(); // Get current year

        if (lastMonth < 0) {
            // If it's January (0), move to December (11) of the previous year
            lastMonth = 11;
            lastMonthYear--;
        }
        const filteredData = data?.filter(item => {
            const timestamp = parseInt(item[filed]);
            if (isNaN(timestamp)) return false; // Skip invalid timestamps

            const date = new Date(timestamp.toString().length > 10 ? timestamp : timestamp * 1000);
            if (isNaN(date.getTime())) return false; // Skip invalid date conversions

            return date.getMonth() === lastMonth && date.getFullYear() === lastMonthYear;
        });

        const sortedData = filteredData.sort((a, b) => {
            const dateA = new Date(parseInt(a[filed]));
            const dateB = new Date(parseInt(b[filed]));

            return (isNaN(dateB.getTime()) ? 0 : dateB) - (isNaN(dateA.getTime()) ? 0 : dateA);
        });

        return sortedData
    }
    else if (state?.ACTIVEFILTER?.includes("Custom")) {
        const startDate = new Date(parseInt(state?.ACTIVEFILTER?.split(",")[1]));
        const endDate = new Date(parseInt(state?.ACTIVEFILTER?.split(",")[2]));

        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);
        const filteredData = data?.filter(item => {
            const timestamp = parseInt(item[filed]);
            if (isNaN(timestamp)) return false; // Skip invalid timestamps

            const date = new Date(timestamp.toString().length > 10 ? timestamp : timestamp * 1000);
            if (isNaN(date.getTime())) return false; // Skip invalid dates

            return date >= startDate && date <= endDate;
        });

        const sortedData = filteredData.sort((a, b) => {
            const dateA = new Date(parseInt(a[filed]));
            const dateB = new Date(parseInt(b[filed]));

            return (isNaN(dateB.getTime()) ? 0 : dateB) - (isNaN(dateA.getTime()) ? 0 : dateA);
        });

        return sortedData
    }
    else {
        const sortedData = data.sort((a, b) => {
            const timestampA = parseInt(a[filed]);
            const timestampB = parseInt(b[filed]);

            // Validate timestamps
            if (isNaN(timestampA) || isNaN(timestampB)) return 0;

            // Convert timestamps to Date objects
            const dateA = new Date(timestampA.toString().length > 10 ? timestampA : timestampA * 1000);
            const dateB = new Date(timestampB.toString().length > 10 ? timestampB : timestampB * 1000);

            // Validate dates
            if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) return 0;

            return dateB - dateA; // Descending order
        });

        return sortedData
    }
}