import { getDatabase } from "firebase/database";
import { ref, get, set } from "firebase/database";

const generateSessionId = () => Math.floor(Math.random() * 9000000000000) + 1;

export const detectBrowser = (userAgent) => {
  if (userAgent.includes("Firefox")) return "Mozilla Firefox";
  if (userAgent.includes("SamsungBrowser")) return "Samsung Internet";
  if (userAgent.includes("Opera") && userAgent.includes("OPR")) return "Opera";
  if (userAgent.includes("Edge")) return "Microsoft Edge (Legacy)";
  if (userAgent.includes("Edg")) return "Microsoft Edge (Chromium)";
  if (userAgent.includes("Chrome")) return "Google Chrome or Chromium";
  if (userAgent.includes("Safari")) return "Apple Safari";
  return "Unknown";
};

export const storeSessionData = async (userId) => {
  const db = getDatabase();
  const sessionId = generateSessionId();
  const sessionRef = ref(db, `invoiceGenerator/users/${userId}/session`);

  const browserName = detectBrowser(navigator.userAgent);
  const deviceName = detectBrowser(navigator.userAgent);
  const createdDate = Date.now().toString();
  const updateDate = Date.now().toString();

  try {
    const sessionSnapshot = await get(sessionRef);
    if (sessionSnapshot.exists()) {
      const sessions = sessionSnapshot.val();
      for (const existingSessionId in sessions) {
        if (sessions[existingSessionId].browserName === browserName) {
          await set(
            ref(db, `invoiceGenerator/users/${userId}/session/${existingSessionId}`),
            { ...sessions[existingSessionId], updateDate }
          );
          return;
        }
      }
    }
    await set(ref(db, `invoiceGenerator/users/${userId}/session/${sessionId}`), {
      browserName,
      deviceName,
      deviceType: "Website",
      createdDate,
      sessionId,
      updateDate,
    });
  } catch (error) {
    console.error("Error storing session data: ", error);
  }
};
