import React, { useState, useContext } from "react";
import BusinessDataValidation from "../helper/BusinessDataValidation";
import { ConfigContext } from "../context/GlobalContext";
import { useNavigate } from "react-router-dom";
import EditBusinessData from "../data/Firebase/Business/EditBusinessDataInFirebase";
import { Icon } from "@iconify/react/dist/iconify.js";
import { getImage } from "../Utils/image_helper";
import { toast } from "react-toastify";
import CustomerDataValidation from "../helper/CustomerDataValidation";
import { EditCustomer } from "../data";

const CustomerEditForm = () => {

  const { state, dispatch } = useContext(ConfigContext);

  const navigate = useNavigate();

  const customer_data = state?.SELECTEDCUSTOMER;

  const [customerData, setCustomerData] = useState({
    customer: customer_data?.customer,
    customerGSTNo: customer_data?.customerGSTNo,
    phone: customer_data?.phone,
    email: customer_data?.email,
    address: customer_data?.address,
    invoices: customer_data?.invoices,
    srNo: customer_data?.srNo
  });

  const [error, setError] = useState({
    customer: null,
    email: null,
    phone: null
  })

  const HandleChangeInBusinessData = (field, value) => {
    setCustomerData((prevData) => ({
      ...prevData,
      [field]: value,
    }));

    setError((prevData) => ({
      ...prevData,
      [field]: null,
    }));
  };

  const OnUpdatePress = async () => {
    const { isValid, errors } = CustomerDataValidation(customerData);

    if (isValid) {
      const { success, message } = await EditCustomer(state, dispatch, customerData);
      if (success && message == "Customer data updated successfully!") {
        navigate(-1)
        toast.success("Customer successfully updated!")
      }
      else {
        toast.error("Failed to update the customer, Please try again!")
      }

    }
    else {
      setError(errors)
    }

  }
  const onBack = async () => {
    navigate(-1);
  }


  return (
    <div className="card shadow-lg p-4 mt-3">
      <div className="card-header">

        <div className="d-flex flex-wrap align-items-center justify-content-between gap-2">
          {/* Left Side - Back/Close Button */}
          <div className="d-flex align-items-center gap-2">
            <button
              onClick={() => { navigate(-1) }}
            >
              <i class="fa fa-arrow-left" style={{ fontSize: 18 }}></i>
            </button>
            <p className='fw-semibold mb-0' style={{ fontSize: 20 }}>Edit Customer</p>
          </div>

          {/* Right Side - Other Buttons */}
          <div className="d-flex gap-2">
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="row">
          <form>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-label">Customer Name <span className="text-danger"> *</span></label>
                <input
                  type="text"
                  className={`form-control wizard-required ${error.customer ? "border-danger" : ""
                    }`}
                  placeholder="Enter Business Name"
                  value={customerData.customer}
                  onChange={(e) =>
                    HandleChangeInBusinessData(
                      "customer",
                      e.target.value
                    )
                  }
                />
                {error.customer && (
                  <div className="text-danger small mt-1">
                    {error.customer}
                  </div>
                )}
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label">Phone Number <span className="text-danger"> *</span></label>
                <input
                  type="text"
                  className={`form-control wizard-required ${error.phone ? "border-danger" : ""
                    }`}
                  placeholder="Enter Business Name"
                  value={customerData.phone}
                  onChange={(e) =>
                    HandleChangeInBusinessData(
                      "phone",
                      e.target.value
                    )
                  }
                />
                {error.phone && (
                  <div className="text-danger small mt-1">
                    {error.phone}
                  </div>
                )}
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label">Email <span className="text-danger"> *</span></label>
                <input
                  type="text"
                  className={`form-control wizard-required ${error.email ? "border-danger" : ""
                    }`}
                  placeholder="Enter Business Name"
                  value={customerData.email}
                  onChange={(e) =>
                    HandleChangeInBusinessData(
                      "email",
                      e.target.value
                    )
                  }
                />
                {error.email && (
                  <div className="text-danger small mt-1">
                    {error.email}
                  </div>
                )}
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label">GST Number</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter GST Number"
                  value={customerData.customerGSTNo}
                  onChange={(e) =>
                    HandleChangeInBusinessData(
                      "customerGSTNo",
                      e.target.value
                    )
                  }
                />
              </div>
              <div className="col-md-12 mb-3">
                <label className="form-label">Address</label>
                <textarea
                  className="form-control"
                  rows="3"
                  placeholder="Enter address"
                  value={customerData.address}
                  onChange={(e) =>
                    HandleChangeInBusinessData("address", e.target.value)
                  }
                ></textarea>
              </div>
            </div>

            <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mt-5">
              <div className="d-flex gap-2">
                <button
                  type="reset"
                  onClick={onBack}
                  className="border btn-sm border-danger-600 bg-hover-danger-200 text-danger-600 text-md px-40 py-6 radius-8"
                >
                  Back
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-primary-600 radius-8 d-inline-flex align-items-center px-40 py-6 radius-8 gap-2"
                  onClick={OnUpdatePress}
                >
                  Update
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CustomerEditForm;
