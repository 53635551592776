import { Icon } from '@iconify/react/dist/iconify.js';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import InvoiceTableDataLayer from './InvoiceTableDataLayer';
import { ConfigContext } from '../context/GlobalContext';
import * as actionType from "../context/actions";

const InvoiceListLayer = ({ businessData, invoiceData }) => {
    const { state, dispatch } = useContext(ConfigContext)
    const [selectedBusiness, setSelectedBusiness] = useState("null");
    
    useEffect(() => {
        setSelectedBusiness(state?.SELECTEDBUSINESS || "null");
        dispatch({ type: actionType?.SELECTEDBUSINESS, payload: state?.SELECTEDBUSINESS || 'null'});  
    }, [])

    const handleSelection = (businessId) => {
        setSelectedBusiness(businessId);
        dispatch({ type: actionType?.SELECTEDBUSINESS, payload: businessId });
    };

    return (
        <div className="card basic-data-table">
            <div className="card-header d-flex flex-wrap align-items-center justify-content-between gap-3">
                <div className="d-flex flex-wrap align-items-center gap-3">
                    <div className="dropdown">
                        <button
                            className="btn btn-primary-600 bg-primary-50 border-primary-50 text-primary-600 hover-text-primary not-active px-18 py-11 dropdown-toggle toggle-icon"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            {(state?.SELECTEDBUSINESS == null || state?.SELECTEDBUSINESS == "null") ? "All Business" : businessData?.find(business => business.companyId == state?.SELECTEDBUSINESS)?.companyName}
                        </button>
                        <ul className="dropdown-menu">
                            {businessData?.length > 0 && (<li>
                                <Link
                                    onClick={() => handleSelection("null")}
                                    className={`dropdown-item px-16 py-8 rounded text-secondary-light bg-hover-neutral-200 text-hover-neutral-900 ${(state?.SELECTEDBUSINESS == null || state?.SELECTEDBUSINESS == "null") && "bg-primary-50"}`}
                                    to="#"
                                >
                                    All Business
                                </Link>
                            </li>)}
                            {businessData?.length > 0 ? (
                                businessData?.map((business) => (
                                    <li key={business?.companyId}>
                                        <Link
                                            onClick={() => handleSelection(business?.companyId)}
                                            className={`dropdown-item px-16 py-8 rounded text-secondary-light bg-hover-neutral-200 text-hover-neutral-900 ${business?.companyId == selectedBusiness && "bg-primary-50"}`}
                                            to="#"
                                        >
                                            {business?.companyName}
                                        </Link>
                                    </li>
                                ))
                            ) : (
                                <li className="dropdown-item text-muted">No businesses available</li>
                            )}
                        </ul>
                    </div>

                </div>
                <div className="d-flex flex-wrap align-items-center gap-3">

                    <Link
                       to={state?.BUSINESSES?.length > 0 || state?.PREMIUM?.length>0 ? "/invoice-add" : "/business-add"}
                         className="btn btn-primary-600 bg-primary border-primary not-active px-18 py-11 d-flex align-items-center"
                    >
                        <i className="ri-add-line" /> <span className='hide-text'>Create Invoice </span>
                    </Link>
                </div>
            </div>

            {/* Table */}
            <InvoiceTableDataLayer InvoicesData={invoiceData} selectedBusiness={selectedBusiness} />
        </div >


    );
};

export default InvoiceListLayer;