import GetInvoiceDataLocal from "./GetInvoiceDataLocal";

const EditInvoiceDataInLocal = async (state, dispatch, id, invoiceData) => {

    try {

        // Retrieve existing invoices from localStorage
        const existingData = JSON.parse(localStorage.getItem("LOCAL_INVOICES")) || [];
        const invoiceIndex = existingData.findIndex(invoice => invoice.uniqueID == id);
        
        const formatItems = (rawItems) => {
            return rawItems.map(i => ({
                amount: i?.amount?.toString() || "0.00",
                billId: invoiceData?.id?.toString() || "",
                companyId: invoiceData?.companyId?.toString() || "",
                creationDate: new Date(parseInt(invoiceData.creationDate)).getTime().toString() || new Date().getTime().toString(),
                isSalesReturnKey: "",
                itemId: "",
                name: i?.name || "",
                price: i?.price?.toString() || "",
                quantity: i?.quantity?.toString() || "",
                subtitle: i.subtitle || "",
                updateDate: new Date().getTime().toString()
            }));
        };

        
        const data = {
            uniqueID: id,
            address: invoiceData?.address?.toString() || "",
            companyId: invoiceData?.companyId?.toString() || "",
            creationDate: new Date(invoiceData.creationDate).getTime().toString() || new Date().getTime().toString(),
            customInvoiceId: "",
            customer: invoiceData?.customer?.toString() || "",
            customerGSTNo: invoiceData?.customerGSTNo?.toString() || "",
            date: new Date(invoiceData.creationDate).getTime().toString() || new Date().getTime().toString(),
            discountAmount: invoiceData?.discountAmount?.toString() || "0.00",
            discountPercetage: invoiceData?.discountPercentage?.toString() || "0",
            dueDate: new Date(parseInt(invoiceData?.dateDue)).getTime().toString() || new Date().getTime().toString(),
            email: invoiceData?.email.toString() || "",
            gstAmount: invoiceData?.gstAmount?.toString() || "0.00",
            gstPercetage: invoiceData?.gstPercentage?.toString() || "0",
            id: invoiceData?.id?.toString() || "",
            invoiceId: invoiceData?.invoiceId?.toString() || "",
            isigst: invoiceData?.isigst?.toString() || "false",
            items: formatItems(invoiceData?.items),
            note: invoiceData?.note?.toString() || "",
            paidFlagKey: invoiceData?.isPaid?.toString() || "false",
            phone: invoiceData?.phone?.toString() || "",
            totalAmount: invoiceData?.totalAmount?.toString() || "",
            updateDate: new Date().getTime().toString()
        }   
        
        if (invoiceIndex !== -1) {
            console.log(data);
            existingData[invoiceIndex] = data;
        } else {
            // If invoice doesn't exist, add it
            // existingData.push(data);
        }

        // Save updated invoices back to localStorage
        localStorage.setItem("LOCAL_INVOICES", JSON.stringify(existingData));


        // Fetch updated invoice data
        await GetInvoiceDataLocal(state, dispatch);

        return { success: true, message: "Invoice updated successfully!" };
    } catch (error) {
        return { success: false, message: error };
    }
};

export default EditInvoiceDataInLocal;
