export const formatDate = (dateInput) => {
    if (!dateInput) {
        console.error("Invalid date input:", dateInput);
        return "Invalid Date";
    }

    const timestamp = isNaN(dateInput) ? Date.parse(dateInput) : Number(dateInput.length > 10 ? dateInput : dateInput * 1000);

    if (isNaN(timestamp)) {
        console.error("Invalid timestamp after conversion:", dateInput);
        return "Invalid Date";
    }

    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.toLocaleString("en-US", { month: "short" }); // "Mon"
    const year = date.getFullYear();

    return `${day} ${month} ${year}`; // Example output: "26 Feb 2025"
};
