import { push, ref } from "firebase/database";
import { database } from "../../../Utils/firebase";
import UploadImageOnFirebase from "../UploadImage";
import GetBusinessDataFirebase from "./GetBusinessDataFirebase";

const AddBusinessDataInFirebase = async (state, dispatch, businessdata, userId, fromLocal) => {

    if ((!state?.USERID || state?.USERID == null) && !userId) return null;

    const USERID = state?.USERID || userId
    try {
        const file = fromLocal ? {} : businessdata?.companyPhoto;
        let fileURL = fromLocal ? businessdata?.companyPhoto : null;

        // Upload image if file exists
        if (file && !fromLocal) {
            const res = await UploadImageOnFirebase(USERID, file);
            fileURL = res?.downloadURL;
        }
        
        // Reference to user's company data
        const userRef = ref(database, `invoiceGenerator/users/${USERID}/company`);
        
        // Push new company data
        await push(userRef, {
            companyName: businessdata?.companyName?.toString(),
            companyId: businessdata?.companyId,
            address: businessdata?.address?.toString(),
            gstNumber: businessdata?.gstNumber?.toString(),
            terms: businessdata?.terms?.toString(),
            companyPhoto: fileURL,
            creationDate: businessdata?.creationDate?.toString(),
            isArchive: businessdata?.isArchive?.toString(),
            updateDate: businessdata?.updateDate?.toString(),
        });

        // // Fetch updated company data
        await GetBusinessDataFirebase(state, dispatch);

        return { success: true, message: "New company added successfully!" };
    } catch (error) {
        return { success: false, message: error.message || "An error occurred while adding the company" };
    }
};

export default AddBusinessDataInFirebase;
