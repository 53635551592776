import React, { useContext } from "react";

import BusinessView from "../components/BusinessView";
import { ConfigContext } from "../context/GlobalContext";
import MasterLayout from "../masterLayout/MasterLayout";
import Breadcrumb from "../components/Breadcrumb";

const BusinessViewPage = ({ }) => {
  const { state } = useContext(ConfigContext);


  return (
    <>
      <MasterLayout>
        <BusinessView state={state} />
      </MasterLayout></>

  );
};

export default BusinessViewPage;
