import GetInvoiceDataLocal from "./GetInvoiceDataLocal";

const AddInvoiceDataInLocal = async (state, dispatch, invoiceData) => {
    try {
        // Retrieve existing invoices from localStorage
        const existingData = JSON.parse(localStorage.getItem("LOCAL_INVOICES")) || [];

        const formatItems = (rawItems) => {
            return rawItems.map(i => ({
                amount: i?.amount?.toString() || "0.00",
                billId: invoiceData?.id?.toString() || "",
                companyId: invoiceData?.companyId?.toString() || "",
                creationDate: new Date(invoiceData.creationDate).getTime().toString() || new Date().getTime().toString(),
                isSalesReturnKey: "",
                itemId: "",
                name: i?.name || "",
                price: i?.price?.toString() || "",
                quantity: i?.quantity?.toString() || "",
                subtitle: i.subtitle || "",
                updateDate: new Date(invoiceData.creationDate).getTime().toString() || new Date().getTime().toString(),
            }));
        };

        // New invoice data
        const newInvoice = {
            uniqueID: (new Date().getTime() / 1000).toFixed(0),
            address: invoiceData?.address?.toString() || "",
            companyId: invoiceData?.companyId?.toString() || "",
            creationDate: new Date(invoiceData.creationDate).getTime().toString() || new Date().getTime().toString(),
            customInvoiceId: "",
            customer: invoiceData?.customer?.toString() || "",
            customerGSTNo: invoiceData?.customerGSTNo?.toString() || "",
            date: new Date(invoiceData?.creationDate).getTime().toString() || new Date().getTime().toString(),
            discountAmount: invoiceData?.discountAmount?.toString() || "0.00",
            discountPercetage: invoiceData?.discountPercentage?.toString() || "0",
            dueDate: new Date(parseInt(invoiceData?.dateDue)).getTime().toString() || new Date().getTime().toString(),
            email: invoiceData?.email.toString() || "",
            gstAmount: invoiceData?.gstAmount?.toString() || "0.00",
            gstPercetage: invoiceData?.gstPercentage?.toString() || "0",
            id: invoiceData?.id?.toString() || "",
            invoiceId: invoiceData?.invoiceId?.toString() || "",
            isigst: invoiceData?.isigst?.toString() || "false",
            items: formatItems(invoiceData?.items),
            note: invoiceData?.note?.toString() || "",
            paidFlagKey: invoiceData?.isPaid?.toString() || "false",
            phone: invoiceData?.phone?.toString() || "",
            totalAmount: invoiceData?.totalAmount?.toString() || "",
            updateDate: new Date(invoiceData?.creationDate).getTime().toString() || new Date().getTime().toString()
        };

        // Append new invoice to existing data
        existingData.push(newInvoice);

        // Save updated invoices back to localStorage
        localStorage.setItem("LOCAL_INVOICES", JSON.stringify(existingData));

        // Fetch updated invoice data
        await GetInvoiceDataLocal(state, dispatch);

        return { success: true, message: "New invoice added successfully!" };
    } catch (error) {
        return { success: false, message: error.message || error };
    }
};

export default AddInvoiceDataInLocal;
