import { GetAllData } from "../..";
import { AddLocalDataToFirebase } from "../../AddLocalDataToFirebase";
import { storeSessionData } from "../Session/AddSession";
import { LoginWithAppel } from "./LoginWithAppel"
import { LoginWithFacebook } from "./LoginWithFacebook"
import { LoginWithGoogle } from "./LoginWithGoogle"
import * as actionType from '../../../context/actions'
import { auth, database } from "../../../Utils/firebase";
import { signOut } from "firebase/auth";
import GetBusinessDataFirebase from "../Business/GetBusinessDataFirebase";
import { get, ref } from "firebase/database";

export const LoginFunc = async (state, dispatch, providerType, DisplayPopup) => {
    try {
        const localBusinessData = JSON.parse(localStorage.getItem("LOCAL_BUSINESSES")) || [];

        let Authentication_Status = false;
        let Authentication_Data = null;
        let Authentication_Message = null;

        if (providerType === "google") {
            const { sucess, message, data } = await LoginWithGoogle();
            Authentication_Status = sucess;
            Authentication_Message = message;
            Authentication_Data = data;
        }
        else if (providerType === "facebook") {
            const { sucess, message, data } = await LoginWithFacebook();
            Authentication_Status = sucess;
            Authentication_Message = message;
            Authentication_Data = data;
        }
        else if (providerType === "apple") {
            const { sucess, message, data } = await LoginWithAppel();
            Authentication_Status = sucess;
            Authentication_Message = message;
            Authentication_Data = data;
        }

        if (Authentication_Status) {
            const userId = Authentication_Data?.user?.providerData[0]?.uid;
            dispatch({ type: actionType.SET_USER, payload: Authentication_Data?.user });
            const companyRef = ref(database, `invoiceGenerator/users/${userId}/company`);
            const companySnapshot = await get(companyRef);
            const companyData = companySnapshot.val();

            const premiumRef = ref(database, `invoiceGenerator/users/${userId}/premium`);
            const premiumSnapshot = await get(premiumRef);
            const premiumRefData = premiumSnapshot.val();

            let FirebaseBusinessData = [];

            if (companyData) {
                FirebaseBusinessData = Object.entries(companyData);
            }

            const FirebasePrimiumData = premiumRefData;

            const premiumDate = new Date(
                FirebasePrimiumData?.premiumLastDate?.length > 10
                    ? parseInt(FirebasePrimiumData?.premiumLastDate)
                    : parseInt(FirebasePrimiumData?.premiumLastDate * 1000)
            );

            // Get today's date at 00:00:00 to compare only the date (ignoring time)
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            // Check if the premiumDate is today or in the future
            const isValidDate = premiumDate.getTime() >= today.getTime();


            if (localBusinessData?.length > 0) {
                if (FirebaseBusinessData?.length > 0) {

                    if (isValidDate == true) {

                        await AddLocalDataToFirebase(state, dispatch, userId);

                        setTimeout(async () => {
                            localStorage.setItem("AccessUserId", userId);
                            localStorage.removeItem("isLocal");
                            localStorage.removeItem("LOCAL_BUSINESSES");
                            localStorage.removeItem("LOCAL_INVOICES");

                            await storeSessionData(userId);
                            await GetAllData(state, dispatch);

                            // ✅ Redirect after login
                            window.location.replace("/dashboard");
                        }, [1000])

                    } else {
                        DisplayPopup();
                    }
                } else {
                    await AddLocalDataToFirebase(state, dispatch, userId);
                    setTimeout(async () => {
                        localStorage.setItem("AccessUserId", userId);
                        localStorage.removeItem("isLocal");
                        localStorage.removeItem("LOCAL_BUSINESSES");
                        localStorage.removeItem("LOCAL_INVOICES");

                        await storeSessionData(userId);
                        await GetAllData(state, dispatch);

                        // ✅ Redirect after login
                        window.location.replace("/dashboard");
                    }, [1000])
                }
            } else {
                localStorage.setItem("AccessUserId", userId);
                localStorage.removeItem("isLocal");
                localStorage.removeItem("LOCAL_BUSINESSES");
                localStorage.removeItem("LOCAL_INVOICES");

                await storeSessionData(userId);
                await GetAllData(state, dispatch);

                // ✅ Redirect after login
                window.location.replace("/dashboard");
            }
        } else {

        }
    } catch (error) {
        console.error("Error in LoginFunc:", error);
    }
};

export const LogoutUser = async (dispatch) => {
    try {
        await signOut(auth);
        localStorage.removeItem("AccessUserId");
        localStorage.removeItem("appState");
        dispatch({ type: actionType.EMPTY_ALL_STATE });

        // ✅ Redirect after logout
    } catch (error) {
        console.error("Logout Error:", error.message);
    }
};