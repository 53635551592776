import { Icon } from '@iconify/react/dist/iconify.js';
import React from "react";
import { Link } from "react-router-dom";

const DownloadAppLayer = () => {
  return (
    <div className='custom-bg min-vh-100 d-flex align-items-center'>
      <div className='container container--xl'>
        <div className='d-flex align-items-center justify-content-between py-16'>
          <Link to='/'>
            <img src='assets/images/billnama-logo_dark.png' alt='Logo' style={{ width: 150, height: 40 }} />
          </Link>
          <Link to='/' className='btn btn-outline-primary-600 text-sm'>
            Go To Home
          </Link>
        </div>

        <div className='py-80'>
          <div className='row align-items-center'>
            <div className='col-lg-6'>
              <h3 className='mb-16 max-w-800-px'>
                Download Our App
              </h3>
              <p className='text-neutral-500 max-w-600-px text-md'>
                Commerce through prospective manufactured products intrinsicly myocardinata team building methodologies rather than proactive vortals. Credibly deploy accurate infrastructures and synergy. Competently foster multidisciplinary.
              </p>

              <div className='mt-16 text-start d-inline-flex align-items-center gap-2'>
                <a
                  target='_blank'
                  href="https://apps.apple.com/app/billnama-invoice-maker-gst/id1444998056"
                >
                  <button type="button" className="btn btn-sm btn-primary-600 radius-8 d-inline-flex align-items-center gap-1">
                    <Icon icon="mdi:apple" className="text-lg" />
                    App Store
                  </button>
                </a>

                <a
                  target='_blank'
                  href='https://play.google.com/store/apps/details?id=com.softnoesis.invoice&pli=1'
                >
                  <button type="button" className="btn btn-sm btn-primary-600 radius-8 d-inline-flex align-items-center gap-1">
                    <Icon icon="mdi:google-play" className="text-lg" />
                    Play Store
                  </button>
                </a>
              </div>
            </div>

            {/* Right side image container */}
            <div className='col-lg-6 d-lg-flex d-none justify-content-end'>
              <div className='w-100 d-flex justify-content-end'>
                <img
                  src="https://app.billnama.com/static/media/paymentscreen.37a8b0edf0e7b91d032f.png"
                  alt='Coming Soon'
                  className='img-fluid w-250-px' // Adjusted image size
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadAppLayer;
