import React, { forwardRef } from "react";
import InvoicePreview1 from "../preview/PreviewDesign1"
import InvoicePreview2 from "../preview/PreviewDesign2";
import InvoicePreview3 from "../preview/PreviewDesign3";
import InvoicePreview4 from "../preview/PreviewDesign4";
import InvoicePreview5 from "../preview/PreviewDesign5";
import InvoicePreview6 from "../preview/PreviewDesign6";
import InvoicePreview7 from "../preview/PreviewDesign7";
import PreviewHeader from "./PreviewHeader";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { useContext } from "react";
import { ConfigContext } from "../../context/GlobalContext";
import { DownloadDocument } from "./previewaction";
import LoaderView from "../LoaderView";




const MainPreview = ({ selectedTemplate, selectedInvoice }) => {
    // Map template IDs to components
    const navigate = useNavigate();
    const invoiceRef = useRef(null);
    const [loading, setLoading] = useState(false);


    const handlePrint = useReactToPrint({
        contentRef: invoiceRef, // ✅ Use contentRef instead of content callback
        documentTitle: "Invoice",
        removeAfterPrint: true,
        onBeforeGetContent: () => console.log("🔄 Preparing content for print..."),
        onAfterPrint: () => console.log("✅ Print complete"),
    });

    const onPrintClick = () => {
        handlePrint();
    };

    const invoiceTemplates = {
        1: InvoicePreview1,
        2: InvoicePreview2,
        3: InvoicePreview3,
        4: InvoicePreview4,
        5: InvoicePreview5,
        6: InvoicePreview6,
        7: InvoicePreview7,
    };

    // Get the selected template component, default to InvoicePreview1
    const SelectedTemplate = invoiceTemplates[selectedTemplate] || InvoicePreview1;

    // ✅ ForwardRef component to correctly attach ref
    const PrintableComponent = forwardRef((props, ref) => {
        return (
            <div
                ref={ref}
                id="invoice"
                style={{ transformOrigin: "center center" }}
            >
                <SelectedTemplate selectedInvoice={selectedInvoice} invoiceRef={invoiceRef} />
            </div>
        );
    });

    return (
        <>
            {loading && (
                <LoaderView />
            )}

            <div className="row bg-white  text-black  ms-1 overflow-x-hidden align-items-center">
            <button
                type="button"
                className="no-printme sidebar-close-btn text-black "
              onClick={() => { navigate(-1) }}
            >
              <i class="fa fa-arrow-left" style={{ fontSize: 20}}></i>
            </button>
            <div className="display_button row justify-content-end  lg:hidden  gap-3 no-printme"  >

            
            
                <button
                    onClick={() => navigate("/invoice-edit")}
                    className="btn col-3  btn-sm btn-success radius-8 flex items-center  w-auto md:w-90"
                >
                    <Icon icon="uil:edit" className="text-xl" />
                    <span className="d-none d-md-inline">Edit</span>
                </button>


                <button
                    onClick={() => onPrintClick()}
                    type="button"
                    className="btn col-3 btn-sm btn-danger radius-8 flex items-center gap-1 w-auto md:w-90"
                >
                    <Icon icon="basil:printer-outline" className="text-xl" />
                    <span className="d-none d-md-inline">Print</span>

                </button>

                <button
                    onClick={() => DownloadDocument(setLoading)}
                    className="btn  col-3 btn-sm btn-warning radius-8 flex items-center gap-1 w-auto md:w-90 "
                >
                    <Icon icon="solar:download-linear"  className="text-xl"/>

                    <span className="d-none d-md-inline">Download</span>
                </button>

            </div>
            </div>

            <div className="row" >
                {/* <PreviewHeader /> */}

                <div className="back_button no-printme col-1" >
                    <Link
                        onClick={() => { navigate(-1) }}
                        to="#"
                        className="btn back-button btn-sm btn-secondary radius-8 d-inline-flex align-items-center -1" style={{ marginTop: "15%" }}
                    >
                        <Icon icon="ic:baseline-arrow-back" className="text-xl" />
                        Back
                    </Link>
                </div>

                <div className="layer  col-lg-10 col-md-12">
                    <PrintableComponent ref={invoiceRef} />
                </div>

                <div className="action_button no-printme col-1 p-0 text-end" style={{ display: "flex", flexDirection: "column" }}>
                    <button
                        onClick={() => { navigate("/invoice-edit") }}
                        className="btn btn-sm btn-success radius-8 d-inline-flex align-items-center gap-1 mt-3 w-90 "

                    >
                        <Icon icon="uil:edit" className="text-xl" />
                        Edit
                    </button>
                    <button
                        onClick={() => onPrintClick()}
                        type="button"
                        className="btn btn-sm btn-danger radius-8 d-inline-flex align-items-center gap-1 mt-3 w-90"

                    >
                        <Icon icon="basil:printer-outline" className="text-xl" />
                        Print
                    </button>
                    <button

                        className="btn btn-sm btn-warning radius-8 d-inline-flex align-items-center gap-1 mt-3 w-90"
                        onClick={() => DownloadDocument(setLoading)}

                    >
                        <Icon icon="solar:download-linear" className="text-xl" />
                        Download
                    </button>
                </div>

            </div>
        </>
    );
};

export default MainPreview;
