export const USERID = "USERID";
export const BUSINESSES = "BUSINESSES";
export const INVOICES = "INVOICES";
export const CUSTOMERS = "CUSTOMERS";
export const SOLDITEMS = "SOLDITEMS";
export const TOTALEARNING = "TOTALEARNING";
export const TOTALPAIDEARNING = "TOTALPAIDEARNING";
export const TOTALUNPAIDEARNING = "TOTALUNPAIDEARNING";
export const SET_USER = "SET_USER";
export const SELECTEDINVOICE = "SELECTEDINVOICE";
export const SELECTEDBUSINESS = "SELECTEDBUSINESS";
export const SELECTEDCUSTOMER = "SELECTEDCUSTOMER";
export const EMPTY_ALL_STATE = "EMPTY_ALL_STATE";
export const SESSIONS = "SESSIONS";
export const PAYMENT = "PAYMENT";
export const SYMBOL = "SYMBOL";
export const CURRENCYCODE = "CURRENCYCODE";
export const CURRENCYNAME = "CURRENCYNAME";
export const FLAG = "FLAG";
export const TAXTYPE = "TAXTYPE";
export const UNIT = "UNIT";
export const PREMIUM = "PREMIUM";
export const PREMIUMLASTDATE = "PREMIUMLASTDATE";
export const SELECTEDPREVIEW = "SELECTEDPREVIEW";
export const ACTIVEFILTER = "ACTIVEFILTER";
export const ACTIVEPAGE = "ACTIVEPAGE";







