import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useRef, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ConfigContext } from "../../context/GlobalContext";
import "../../../src/style/previewdesign7.css";
import { formatDate } from "../../Utils/formate_date";
const InvoicePreview7 = ({ selectedInvoice }) => {

  const inputRef = useRef(null);
  const { state } = useContext(ConfigContext);


  const BusinessData = state?.BUSINESSES.find(
    (business) => business.companyId == selectedInvoice?.companyId
  );

  // const selectedInvoice = datas?.find(invo_data => invoice_id === selectedInvoice.invoiceId);

  return (
    <>
      {selectedInvoice ? (
        <div className="Invoice7">
         
          <div className="Invoices7" ref={inputRef}>
            <div className="Invoice-details" id="divToPrint" ref={inputRef}>
              <div style={{ display: "flex", width: "102.5%" }}>
                <b>
                  <span
                    style={{
                      fontSize: "25px",
                    }}
                  >
                    INVOICE
                  </span>
                </b>
                <div className="box_section">
                  <div className="box"></div>
                  <div className="box"></div>
                  <div className="box"></div>
                  <div className="box"></div>
                  <div className="box"></div>
                </div>
              </div>

              <div className="bussiness-info">
                <div className="bussiness-info-details">
                  <p style={{ fontSize: "13px" }}>INVOICE NUMBER</p>
                  <p style={{ fontSize: "14px" }} className="adress">
                    {" "}
                    {selectedInvoice.id}
                  </p>
                </div>
                <div
                  className="Dates"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "-100px",
                  }}
                >
                  <span>DATE OF ISSUE </span>
                  <span>{formatDate(selectedInvoice.date)}</span>
                </div>
                <div className="bussiness-info-logo">
                  <img src={BusinessData.companyPhoto} />
                </div>
              </div>

              {/* Company details code start  */}

              {/* Company details code End  */}
              {/* customer-info start */}

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <div className="customer-info">
                  <div className="Cusname-invoice-number">
                    <div className="Cus-name">
                      <p style={{ margin: "0px" }}>BILL TO</p>

                      <span style={{ color: "black" }}>
                        {selectedInvoice.customer}
                      </span>
                    </div>
                    <div className="invoice-number">
                      {/* <span>Invoice: <span>{selectedInvoice.cus_invoice_number}</span></span> */}
                    </div>
                  </div>
                  <div className="Adress">
                    <p>{selectedInvoice.address}</p>
                  </div>
                  <div className="number">
                    <span>
                      <span>{selectedInvoice.phone}</span>
                    </span>
                  </div>
                  <div className="Email">
                    <span>
                      <span>{selectedInvoice.email}</span>
                    </span>
                  </div>
                </div>

                <div className="bussiness-info">
                  <div className="bussiness-info-detailes">
                    <h6>{BusinessData.companyName}</h6>

                    <p className="adress"> {BusinessData.address}</p>
                  </div>
                </div>
              </div>
              <div className="purshcing-info">
                <table
                  style={{
                    textAlign: "left",
                    width: "100%",
                    fontSize: "14px",
                    borderCollapse: "collapse",
                    marginBottom: "10px",
                    borderBottom: "1px solid black",
                  }}
                >
                  <thead>
                    <tr
                      style={{
                        backgroundColor: "black",
                        color: "white",
                        fontWeight: "500",
                      }}
                    >
                      <th
                        style={{
                          padding: " 3px",
                          width: "20px",
                          fontWeight: "500",
                          paddingBottom: "5px",
                        }}
                      >
                        No
                      </th>
                      <th
                        style={{
                          padding: " 3px",
                          width: "40%",
                          fontWeight: "500",
                          paddingBottom: "5px",
                        }}
                      >
                        Item
                      </th>
                      <th
                        style={{
                          padding: " 3px",
                          fontWeight: "500",
                          paddingBottom: "5px",
                        }}
                      >
                        {state.UNIT}
                      </th>

                      <th
                        style={{
                          padding: " 3px",
                          fontWeight: "500",
                          paddingBottom: "5px",
                        }}
                      >
                        Price
                      </th>
                      <th
                        style={{
                          padding: " 3px",
                          fontWeight: "500",
                          paddingBottom: "5px",
                        }}
                      >
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedInvoice.items.map((items, i) => (
                      <tr
                        key={i}
                        style={{
                          backgroundColor: "lightgray",
                          borderTop: "0.5px solid gray",
                        }}
                      >
                        <td
                          style={{
                            padding: "5px 3px",
                            textAlign: "center",
                          }}
                          valign="top"
                        >
                          {i + 1}
                        </td>
                        <td style={{ padding: "5px 3px" }} valign="top">
                          <div>{items.name}</div>
                          <div
                            style={{
                              color: "dimgray",
                              fontSize: "12px",
                            }}
                          >
                            {items.subtitle}
                          </div>
                        </td>
                        <td style={{ padding: "5px 3px" }} valign="top">
                          {items.quantity}
                        </td>
                        <td style={{ padding: "5px 3px" }} valign="top">
                          {state.SYMBOL} {items.price}
                        </td>
                        <td style={{ padding: "5px 3px" }} valign="top">
                          {state.SYMBOL} {items.amount}
                        </td>
                      </tr>
                    ))}
                    {/* <tr
                                  style={{
                                    borderTop: "1px solid #4BA2FF",
                                    borderBottom: "1px solid #4BA2FF",
                                  }}
                                 >
                                  <td
                                    style={{ padding: " 3px 3px 10px 3px" }}
                                  ></td>
                                  <td
                                    style={{ padding: " 3px 3px 10px 3px" }}
                                  ></td>
                                  <td style={{ padding: "3px 3px 10px 3px" }}>
                                    {selectedInvoice.items.reduce(
                                      (total, item) =>
                                        total +
                                        parseFloat(item.quantity || 0),
                                      0
                                    )}
                                  </td>
                                  <td
                                    style={{ padding: "3px 3px 10px 3px" }}
                                  ></td>

                                  <td style={{ padding: "3px 3px 10px 3px" }}>
                                    {" "}
                                    {selectedInvoice.items
                                      .reduce(
                                        (total, item) =>
                                          total +
                                          parseFloat(item.amount || 0),
                                        0
                                      )
                                      .toFixed(2)}
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ padding: "5px 3px" }}></td>
                                  <td style={{ padding: "5px 3px" }}></td>
                                  <td style={{ padding: "5px 3px" }}></td>
                                  <td style={{ padding: "5px 3px" }}>
                                    {Tax} ({selectedInvoice.gstPercetage}.0 %):
                                  </td>
                                  <td style={{ padding: "5px 3px" }}>
                                    &nbsp;{selectedInvoice.gstAmount}
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ padding: "5px 3px" }}></td>
                                  <td style={{ padding: "5px 3px" }}></td>
                                  <td style={{ padding: "5px 3px" }}></td>
                                  <td style={{ padding: "5px 3px" }}>
                                    Disc ({selectedInvoice.discountPercetage}
                                    .0%):
                                  </td>
                                  <td style={{ padding: " 3px" }}>
                                     -{selectedInvoice.discountAmount}
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ padding: " 3px" }}></td>
                                  <td style={{ padding: " 3px" }}></td>
                                  <td style={{ padding: " 3px" }}></td>
                                  <td
                                    style={{
                                      padding: " 3px",
                                      fontSize: "15px",
                                      background: "rgb(179, 216, 255)",
                                    }}
                                  >
                                    <b>Payable Amount:</b>
                                  </td>
                                  <td
                                    style={{
                                      padding: " 3px",
                                      fontSize: "15px",
                                      background: "rgb(179, 216, 255)",
                                    }}
                                  >
                                    <b>
                                       {selectedInvoice.totalAmount}
                                    </b>
                                  </td>
                                </tr> */}
                  </tbody>{" "}
                </table>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "20px",
                  }}
                >
                  <div>
                    <p
                      style={{ color: "gray", fontSize: "12px", margin: "0px" }}
                    >
                      INVOICE TOTAL
                    </p>
                    <span style={{ fontSize: "25px" }}>
                      <b>
                        {" "}
                        {state.SYMBOL}{" "}
                        {selectedInvoice.items
                          .reduce(
                            (total, item) =>
                              total + parseFloat(item.amount || 0),
                            0
                          )
                          .toFixed(2)}
                      </b>
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span style={{ marginRight: "30px" }}> DISCOUNT</span>
                      <span>
                        -{state.SYMBOL} {selectedInvoice.discountAmount}
                      </span>
                    </div>
                    <div style={{ display: "flex", paddingTop: "10px" }}>
                      <span style={{ marginRight: "23px" }}>(TAX RATE) </span>
                      <span>{selectedInvoice.gstPercetage}%</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingTop: "10px",
                      }}
                    >
                      <span style={{ marginRight: "14px" }}>TAX </span>
                      <span style={{ marginRight: "9px" }}>
                        {" "}
                        {state.SYMBOL}&nbsp;{selectedInvoice.gstAmount}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingTop: "10px",
                      }}
                    >
                      <span style={{ marginRight: "14px" }}>TOTAL</span>
                      <span>
                        {state.SYMBOL}{" "}
                        {selectedInvoice.items
                          .reduce(
                            (total, item) =>
                              total + parseFloat(item.amount || 0),
                            0
                          )
                          .toFixed(2)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Reciver-sig">
                <p
                  style={{
                    color: "black",
                    fontSize: "15px",
                    marginTop: "10%",
                    marginLeft: "75%",
                  }}
                >
                  <b>Receiver Signature</b>
                </p>
              </div>
              <div
                style={{
                  marginTop: "30px",
                  padding: "10px",
                  fontSize: "15px",
                  color: "black",
                }}
              >
                <b>Note:</b>
                {selectedInvoice.note}
              </div>

              {/* customer-info Code End */}
              <div className="Tearms-sections">
                <div className="Tearmes" style={{ marginTop: "10px" }}>
                  <div className="bussiness-info">
                    <div
                      className="bussiness-info-detailees"
                      style={{ width: "100%" }}
                    >
                      <p
                        style={{
                          background: "#ededed",
                          color: "black",
                          padding: "10px",
                          fontSize: "15px",
                        }}
                      >
                        <b>
                          <span>Terms:</span>
                        </b>
                        {BusinessData.terms}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <p
                style={{
                  width: "100%",
                  left: "0px",
                  padding: "5px",
                  color: "dimgray",
                  fontSize: "14px",
                }}
              >
                Powered by BillNama.com
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default InvoicePreview7;
