import { signInWithPopup } from "firebase/auth";
import { appleProvider, auth } from "../../../Utils/firebase";

export const LoginWithAppel = async () => {
    try {
        const result = await signInWithPopup(auth, appleProvider);
        return { sucess: true, message: "Login Successfully", data: result };
    }
    catch (error) {
        console.log("Google Login Error:", error.message);
        return { sucess: false, message: error.message, data: null };
    }
}