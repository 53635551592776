import React, { useContext, useEffect, useState } from "react";
import Country from "../Utils/countries.json";
import Currancy from "../Utils/currency.json";
import { ConfigContext } from "../context/GlobalContext";
import * as actionType from "../context/actions";
import "../style/currency.css";

export default function CurrencyView() {
  const { state, dispatch } = useContext(ConfigContext);
  const [selectedItem, setSelectedItem] = useState(state?.CURRENCYNAME || "");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredCountries, setFilteredCountries] = useState(
    Currancy.CountryCurrencyResponse
  );

  // ✅ Auto Detect TaxType Based on Currency
  const TaxType = () => {
    let taxType = "TAX";
    if (["EUR", "CZK", "DKK", "HUF", "PLN", "RON", "SEK", "GBP"].includes(state?.CURRENCYCODE)) {
      taxType = "VAT";
    } else if (["INR", "AUD"].includes(state?.CURRENCYCODE)) {
      taxType = "GST";
    }
    dispatch({ type: actionType.TAXTYPE, payload: taxType });
  };

  useEffect(() => {
    TaxType();
  }, [state?.CURRENCYCODE]);

  useEffect(() => {
    const storedSelectedItem = state?.CURRENCYNAME;
    if (storedSelectedItem) {
      setSelectedItem(storedSelectedItem);
    } else {
      const defaultCurrency = Currancy.CountryCurrencyResponse.find(
        (currency) => currency.currencyCode === "INR"
      );
      if (defaultCurrency) {
        setSelectedItem(defaultCurrency.name);
      }
    }
    setFilteredCountries(Currancy.CountryCurrencyResponse);
  }, []);

  // ✅ Handle Click Event to Select Currency
  const handleItemClick = (result) => {
    setSelectedItem(result.name);

    dispatch({ type: actionType.SYMBOL, payload: result.currencytSymbol });
    dispatch({ type: actionType.CURRENCYCODE, payload: result.currencyCode });
    dispatch({ type: actionType.CURRENCYNAME, payload: result.name });

    const country = Country.CurrencyResponse.find((res) => res.isoAlpha2 === result.countryCode);
    if (country) {
      dispatch({ type: actionType.FLAG, payload: country.flag });
    }
  };

  // ✅ Handle Search Functionality
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    const filtered = Currancy.CountryCurrencyResponse.filter(
      (country) =>
        country.name.toLowerCase().includes(event.target.value.toLowerCase()) ||
        country.currencyCode.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setFilteredCountries(filtered);
  };

  return (

    <div className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            {/* ✅ Search Bar */}
            <div className="mb-3">
              <div className="input-group">

                <input
                  type="search"
                  className="form-control"
                  placeholder="Search by Country or Currency Code"
                  value={searchQuery}
                  onChange={handleSearch}
                />
              </div>
            </div>

            {/* ✅ Custom Table */}
            <div className="table-responsive currency_table">
            <table className="currency_table_inner">
                <thead>
                  <tr>
                    <th
                    >Country Name</th>
                    <th>Currency Code</th>
                    <th>Symbol</th>
                    <th>Flag</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredCountries.map((row) => (
                    <tr
                      key={row.name}

                      onClick={() => handleItemClick(row)}
                      style={{ cursor: "pointer" }}
                    >
                      <td
                        className={selectedItem === row.name ? "selected-item fw-bold" : ""}>
                        {row.name}
                      </td>
                      <td className={selectedItem === row.name ? "selected-item fw-bold" : ""}>{row.currencyCode}</td>
                      <td className={selectedItem === row.name ? "selected-item fw-bold" : ""}>{row.currencytSymbol}</td>
                      <td className={selectedItem === row.name ? "selected-item fw-bold" : ""}>
                        <img
                          src={`data:image/png;base64,${Country.CurrencyResponse.find(
                            (res) => res.isoAlpha2 === row.countryCode
                          )?.flag
                            }`}
                          alt="flag"
                          style={{ width: "30px", height: "20px" }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>
    </div>

  );
}
