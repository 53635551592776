import { Icon } from '@iconify/react/dist/iconify.js';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { ConfigContext } from '../context/GlobalContext';
import InvoiceDataValidation from '../helper/InvoiceDataValidation';
import { toast } from 'react-toastify';
import { EditInvoice } from '../data';
import { Modal } from 'react-bootstrap';
import * as actionType from '../context/actions';

const InvoiceEditLayer = () => {

    const { state, dispatch } = useContext(ConfigContext);

    const [selectedCustomer, setSelectedCustomer] = useState(null);

    const Invoice_Data = state?.INVOICES?.find(invoice => invoice.invoiceId == state?.SELECTEDINVOICE);


    const [searchTerm, setSearchTerm] = useState("");
    const [showPopup, setShowpopup] = useState(false);

    const navigate = useNavigate();

    const [invoiceData, setInvoiceData] = useState({
        id: Invoice_Data?.id,
        creationDate: new Date(parseInt(Invoice_Data?.creationDate.length > 10 ? Invoice_Data?.creationDate : Invoice_Data?.creationDate * 1000)).getTime(),
        dateDue: new Date(parseInt(Invoice_Data?.dueDate.length > 10 ? Invoice_Data?.dueDate : Invoice_Data?.dueDate * 1000)).getTime(),
        customer: Invoice_Data?.customer,
        address: Invoice_Data?.address,
        phone: Invoice_Data?.phone,
        email: Invoice_Data?.email,
        customerGSTNo: Invoice_Data?.customerGSTNo,
        items: Invoice_Data?.items,
        totalAmount: Invoice_Data?.totalAmount,
        gstPercentage: Invoice_Data?.gstPercetage,
        totalAmount: Invoice_Data?.totalAmount,
        discountPercentage: Invoice_Data?.discountPercetage,
        discountAmount: Invoice_Data?.discountAmount,
        isPaid: Invoice_Data?.paidFlagKey == "true" ? true : false,
        note: Invoice_Data?.note,
        invoiceId: Invoice_Data?.invoiceId,
        companyId: Invoice_Data?.companyId,
        updatedDate: new Date().getTime(),
        isigst: Invoice_Data?.isigst == "true" ? true : false,
        subtotal: 0.00
    });

    const [selectedName, setSelectedName] = useState(invoiceData.customer || "Select Customer");

    const [newCustomerData, setNewCustomerData] = useState({
        name: null,
        email: null,
        phone: null,
        gstNumber: null,
        address: null
    })

    const [error, setError] = useState({
        dateDue: null,
        customer: null,
        phone: null,
        email: null,
        items: [],
    })

    const [customerError, setCustomerError] = useState({
        name: null,
        email: null,
        phone: null
    })

    const calculateTotals = () => {
        const totalAmount = invoiceData.items.reduce((sum, item) => sum + (parseFloat(item.amount) || 0), 0);

        // Ensure discountPercentage is valid
        const discountPercentage = invoiceData.discountPercentage || 0;
        const discountAmount = (totalAmount * discountPercentage) / 100;

        // Subtotal after discount
        const subtotalAfterDiscount = totalAmount - discountAmount;

        // Ensure gstPercentage is valid
        const gstPercentage = invoiceData.gstPercentage || 0;
        const gstAmount = (subtotalAfterDiscount * gstPercentage) / 100;

        // Final grand total after applying GST
        const grandTotal = subtotalAfterDiscount + gstAmount;

        // Update state with new calculated values
        setInvoiceData(prevData => ({
            ...prevData,
            discountAmount: discountAmount.toFixed(2),
            gstAmount: gstAmount.toFixed(2),
            totalAmount: grandTotal.toFixed(2),
            subtotal: totalAmount.toFixed(2)
        }));
    };

    useEffect(() => {
        const Customer = state.CUSTOMERS?.filter(
            (item) => item.companyId === Invoice_Data?.companyId
        );
        setSelectedCustomer(Customer);
    }, []);

    // Auto-update totalAmount and gstAmount when items or gstPercentage change
    useEffect(() => {
        calculateTotals();
    }, [invoiceData.items, invoiceData.gstPercentage, invoiceData.discountPercentage]);

    const handleChange = (field, value) => {
        setInvoiceData({ ...invoiceData, [field]: value });
        setError({ ...error, [field]: null })
    };

    const addInvoiceItem = () => {
        setInvoiceData(prevState => ({
            ...prevState,
            items: [
                ...prevState.items,
                {
                    srNo: prevState.items.length + 1,
                    name: null,
                    subtitle: null,
                    quantity: null,
                    price: null,
                    amount: null,
                    creationDate: new Date().getTime()
                }
            ]
        }));
    };

    const handleItemChange = (index, field, value) => {
        setInvoiceData((prevData) => ({
            ...prevData,
            items: prevData.items.map((item, i) =>
                i === index ? { ...item, [field]: value } : item
            ),
        }));
        setError((prevError) => ({
            ...prevError,
            items: prevError.items.map((error, i) =>
                i === index ? { ...error, [field]: null } : error
            ),
        }))
    };

    const RemoveInvoiceItem = (srNo) => {
        setInvoiceData(prevState => ({
            ...prevState,
            items: prevState.items
                .filter((item, index) => index !== srNo)
                .map((item, index) => ({ ...item, srNo: index + 1 }))
        }));
    };

    const handleSelectCustomer = (customers) => {
        setInvoiceData((prevData) => ({
            ...prevData,
            customer: customers.customer,
            email: customers.email,
            phone: customers.phone,
            customerGSTNo: customers.customerGSTNo,
            address: customers.address
        }));

        setError((prevError) => ({
            ...prevError,
            customer: null,
            email: null,
            phone: customers.null,
        }));
    };

    const handleChangeCustomerDetails = (field, value) => {
        setNewCustomerData({ ...newCustomerData, [field]: value });
        setCustomerError({ ...customerError, [field]: null });
    }

    const handleSaveCustomer = () => {
        let Error = {
            name: null,
            email: null,
            phone: null
        };

        let isValid = true;

        if (!newCustomerData.name || newCustomerData?.name.trim() == "") {
            Error.name = "Please Enter Valid Name "
            isValid = false
        }
        if (!newCustomerData.email || !/^\S+@\S+\.\S+$/.test(newCustomerData?.email)) {
            Error.email = "Please Enter Valid Email "
            isValid = false

        }
        if (!newCustomerData.phone || newCustomerData?.phone.trim() == "") {
            Error.phone = "Please Enter Valid Phone "
            isValid = false

        }
        if (isValid) {
            setSelectedName(newCustomerData.name)
            setInvoiceData({
                ...invoiceData,
                customer: newCustomerData.name,
                phone: newCustomerData.phone,
                email: newCustomerData.email,
                customerGSTNo: newCustomerData.gstNumber,
                address: newCustomerData.address
            })
            setShowpopup(false);
        }
        else {
            setCustomerError(Error)
        }
    };

    const handleSelect = (customer) => {
        handleSelectCustomer(customer);
        setSelectedName(customer.customer);
    };

    const OnPressSave = async () => {
        const { isValid, errors } = await InvoiceDataValidation(invoiceData);

        if (isValid) {
            if (invoiceData.companyId == "" || invoiceData.companyId == null) {
                alert("Company id not found")
            }
            else {
                const { success, message } = await EditInvoice(state, dispatch, Invoice_Data?.uniqueID, invoiceData)
                if (success && message == "Invoice updated successfully!") {
                    navigate(-1)
                    toast.success("Invoice successfully updated!");

                }
                else {
                    toast.error("Failed to update the invoice, Please try again!")
                }
            }

        }
        else {
            setError(errors)
        }
    }

    return (
        <div className="card">
            <div className="card-header">
                <div className="d-flex flex-wrap align-items-center justify-content-between gap-2">
                    {/* Left Side - Back/Close Button */}
                    <div className="d-flex align-items-center gap-2">
                        <button
                            onClick={() => { navigate(-1) }}
                        >
                            <i class="fa fa-arrow-left" style={{ fontSize: 18 }}></i>
                        </button>
                        <p className='fw-semibold mb-0' style={{ fontSize: 20 }}>Edit Invoice</p>
                    </div>
                </div>
            </div>
            <div className="card-body py-20">
                <div className="row justify-content-center" id="invoice">
                    <div className="col-lg-10">
                        <div className="shadow-4 border radius-8">
                            <div className="px-20 py-10 border-bottom">
                                <div className="d-flex justify-content-between align-items-center mb-4">
                                    {/* Invoice ID - Left */}
                                    <h3 className="text-xl mb-0">
                                        Invoice #{invoiceData.id}
                                    </h3>

                                    {/* Due Date - Right */}
                                    <div>
                                        <label className="floting-label">Due Date<span className="text-danger">*</span></label>
                                        <input
                                            type="date"
                                            className={`form-control ${error.dateDue ? "border-danger" : ""}`}
                                            id="dueDateInput"
                                            value={invoiceData.dateDue && new Date(parseInt(invoiceData.dateDue)).toISOString().split("T")[0]}
                                            onChange={(e) => handleChange("dateDue", new Date(e.target.value).getTime())}
                                            placeholder="Due Date"
                                            style={{ fontSize: 14 }}
                                        />
                                    </div>
                                    {/* {error.dateDue && (
                                        <span className="tooltip-container text-danger ms-2">
                                            <i className="fa-solid fa-circle-exclamation"></i>
                                            <span className="tooltip-text">Please select date!</span>
                                        </span>
                                    )} */}
                                </div>

                                <div className="row justify-content-between g-3 mt-2">
                                    {/* <h6 className="text-md">Customer Details:</h6> */}

                                    <div className="col-12">
                                        <div className="row mt-2">
                                            <div className="col-sm-6 col-md-4 mb-3 d-flex align-items-center gap-3">
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        position: "relative",
                                                    }}
                                                >
                                                    <label className="floting-label">Customer Name<span className="text-danger">*</span></label>

                                                    {/* Dropdown with Button Inside */}
                                                    <div
                                                        className={`form-control d-flex align-items-center p-0 ${error.customer ? "border-danger" : ""
                                                            }`}

                                                    >
                                                        {/* Dropdown Button */}
                                                        <div className="dropdown w-100">
                                                            <button
                                                                className="form-control border-0"
                                                                type="button"
                                                                data-bs-toggle="dropdown"
                                                                aria-expanded="false"
                                                                style={{
                                                                    background: "transparent",
                                                                    outline: "none",
                                                                    fontSize: 14,
                                                                    paddingLeft: 20,
                                                                }}
                                                            >
                                                                {selectedName}
                                                            </button>

                                                            {/* Dropdown Menu */}
                                                            <ul
                                                                className="dropdown-menu w-100"
                                                                style={{
                                                                    maxHeight: "300px",
                                                                    overflowY: "auto",
                                                                    padding: "10px",
                                                                }}
                                                            >
                                                                {/* Search Box */}
                                                                <li className="px-2 mb-4">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Search Customer..."
                                                                        value={searchTerm}
                                                                        onChange={(e) =>
                                                                            setSearchTerm(e.target.value)
                                                                        }
                                                                    />
                                                                </li>

                                                                {/* Filtered Options */}
                                                                {selectedCustomer
                                                                    ?.filter((customer) =>
                                                                        customer.customer
                                                                            .toLowerCase()
                                                                            .includes(searchTerm.toLowerCase())
                                                                    )
                                                                    .map((customer, index) => (
                                                                        <li
                                                                            key={index}
                                                                            className={`dropdown-item py-6 bg-hover-primary-100 ${customer.customer == selectedName ? "bg-primary-100 " : ""}`}
                                                                            onClick={() => handleSelect(customer)}
                                                                            style={{ cursor: "pointer", marginTop: '5px', borderRadius: 6, color: localStorage.getItem("theme") == 'dark' ? "#fff" : "#000" }}
                                                                        >
                                                                            {customer.customer}
                                                                        </li>
                                                                    ))}

                                                                {/* No Data Found */}
                                                                {selectedCustomer?.filter((customer) =>
                                                                    customer.customer
                                                                        .toLowerCase()
                                                                        .includes(searchTerm.toLowerCase())
                                                                ).length === 0 && (
                                                                        <li className="dropdown-item text-muted">
                                                                            No Customer Found
                                                                        </li>
                                                                    )}
                                                            </ul>
                                                        </div>

                                                        {/* Inline Button Inside Border */}
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary-600 text-light border-0"
                                                            style={{
                                                                borderLeft: "1px solid #dee2e6",
                                                                borderRadius: "0 4px 4px 0",
                                                                height: "100%",
                                                                padding: "0 12px",
                                                            }}
                                                            onClick={() => {
                                                                setNewCustomerData({
                                                                    name: null,
                                                                    email: null,
                                                                    phone: null,
                                                                    address: null,
                                                                    gstNumber: null
                                                                })
                                                                setCustomerError({
                                                                    name: null,
                                                                    email: null,
                                                                    phone: null
                                                                })
                                                                setShowpopup(true)
                                                            }}
                                                        >
                                                            +
                                                        </button>
                                                    </div>
                                                </div>

                                                {/* Error Message */}
                                                {error.customer && (
                                                    <span
                                                        className="tooltip-container text-danger ms-2"
                                                        style={{ zIndex: 1 }}
                                                    >
                                                        <i className="fa-solid fa-circle-exclamation"></i>
                                                        <span className="tooltip-text">
                                                            Please enter valid name!
                                                        </span>
                                                    </span>
                                                )}
                                            </div>
                                            <div
                                                className="col-sm-6 col-md-4 mb-3 d-flex align-items-center gap-3"
                                            >
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        position: "relative",
                                                    }}
                                                >
                                                    <label className="floting-label" >Email<span className="text-danger">*</span></label>
                                                    <input
                                                        type="email"
                                                        className={`form-control ms-1 ${error.email ? "border-danger" : ""
                                                            }`}
                                                        value={invoiceData.email}
                                                        onChange={(e) =>
                                                            handleChange("email", e.target.value)
                                                        }
                                                        placeholder="Enter Email"
                                                        style={{ fontSize: 14 }}
                                                    />
                                                </div>
                                                {error.email && (
                                                    <span
                                                        className="tooltip-container text-danger ms-2"
                                                        style={{ zIndex: 1 }}
                                                    >
                                                        <i className="fa-solid fa-circle-exclamation"></i>
                                                        <span className="tooltip-text">
                                                            Please enter valid email!
                                                        </span>
                                                    </span>
                                                )}
                                            </div>
                                            <div className="col-sm-6 col-md-4 mb-3 d-flex align-items-center gap-3">
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        position: "relative",
                                                    }}
                                                >
                                                    <label className="floting-label" >Phone Number<span className="text-danger">*</span></label>
                                                    <input
                                                        type="tel"
                                                        className={`form-control ms-1 ${error.phone ? "border-danger" : ""
                                                            }`}
                                                        value={invoiceData.phone}
                                                        onChange={(e) =>
                                                            handleChange("phone", e.target.value)
                                                        }
                                                        placeholder="Enter Phone Number"
                                                        style={{ fontSize: 14 }}
                                                    />
                                                </div>
                                                {error.phone && (
                                                    <span
                                                        className="tooltip-container text-danger ms-2"
                                                        style={{ zIndex: 1 }}
                                                    >
                                                        <i
                                                            className="fa-solid fa-circle-exclamation"
                                                            style={{ fontSize: 20 }}
                                                        ></i>
                                                        <span className="tooltip-text">
                                                            Please enter valid phone number!
                                                        </span>
                                                    </span>
                                                )}
                                            </div>

                                            <div className="col-sm-6 col-md-4 mb-1">
                                                <label className="floting-label" >GST Number</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={invoiceData.customerGSTNo}
                                                    onChange={(e) =>
                                                        handleChange("customerGSTNo", e.target.value)
                                                    }
                                                    placeholder="Enter GST Number"
                                                    style={{ fontSize: 14, width: "100%" }}
                                                />
                                            </div>

                                            <div className="col-sm-12 col-md-8 mb-1">
                                                <label className="floting-label" >Address</label>
                                                <input
                                                    className="form-control flex-grow-1 ms-1"
                                                    value={invoiceData.address}
                                                    onChange={(e) =>
                                                        handleChange("address", e.target.value)
                                                    }
                                                    placeholder="Enter Address"
                                                    style={{ fontSize: 14 }}
                                                ></input>
                                            </div>

                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-12">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="py-10 px-20">
                                <div className="mb-4">
                                    <div className="table-responsive scroll-sm">
                                        <table className="table bordered-table text-sm w-100" id="invoice-table">
                                            <thead>
                                                <tr>
                                                    <th className="text-sm">SL.</th>
                                                    <th className="text-sm">Items</th>
                                                    <th className="text-sm">Description</th>
                                                    <th className="text-sm">
                                                        {state?.UNIT}
                                                        <Icon
                                                            icon="mdi:pencil"
                                                            className="text-md cursor-pointer"
                                                            onClick={() => {
                                                                if (state?.UNIT == "Qty") {
                                                                    dispatch({ type: actionType.UNIT, payload: "Hours" })
                                                                }
                                                                else {
                                                                    dispatch({ type: actionType.UNIT, payload: "Qty" })
                                                                }
                                                            }}
                                                        />
                                                    </th>
                                                    <th className="text-sm">Price</th>
                                                    <th className="text-sm">Total</th>
                                                    <th className="text-center text-sm">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {invoiceData?.items?.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                placeholder="Enter Item"
                                                                value={item.name}
                                                                onChange={(e) => {
                                                                    handleItemChange(
                                                                        index,
                                                                        "name",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                style={{ width: "100%", maxWidth: "150px" }}
                                                            />
                                                            {error.items[index]?.name && (
                                                                <span className="tooltip-container text-danger ms-2">
                                                                    <i className="fa-solid fa-circle-exclamation"></i>
                                                                    <span className="tooltip-text">Please enter valid item!</span>
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                placeholder="Enter Description"
                                                                value={item.subtitle}
                                                                onChange={(e) => {
                                                                    handleItemChange(
                                                                        index,
                                                                        "subtitle",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                style={{ width: "100%", maxWidth: "200px" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                placeholder="0"
                                                                value={item.quantity}
                                                                onChange={(e) => {
                                                                    handleItemChange(
                                                                        index,
                                                                        "quantity",
                                                                        e.target.value
                                                                    );
                                                                    if (item.price) {
                                                                        handleItemChange(
                                                                            index,
                                                                            "amount",
                                                                            e.target.value * item.price
                                                                        );
                                                                    }
                                                                }}
                                                                style={{ width: "80px" }}
                                                            />
                                                            {error.items[index]?.quantity && (
                                                                <span className="tooltip-container text-danger ms-2">
                                                                    <i className="fa-solid fa-circle-exclamation"></i>
                                                                    <span className="tooltip-text">Please enter valid quantity!</span>
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                placeholder="0.00"
                                                                value={item.price}
                                                                onChange={(e) => {
                                                                    handleItemChange(
                                                                        index,
                                                                        "price",
                                                                        e.target.value
                                                                    );
                                                                    if (item.quantity) {
                                                                        handleItemChange(
                                                                            index,
                                                                            "amount",
                                                                            e.target.value * item.quantity
                                                                        );
                                                                    }
                                                                }}
                                                                style={{ width: "100px" }}
                                                            />
                                                            {error.items[index]?.price && (
                                                                <span className="tooltip-container text-danger ms-2">
                                                                    <i className="fa-solid fa-circle-exclamation"></i>
                                                                    <span className="tooltip-text">Please enter valid price!</span>
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td>{state?.SYMBOL} {item.amount || "0.00"}</td>
                                                        <td className="text-center">
                                                            <button type="button" className="remove-row">
                                                                <Icon icon="ic:twotone-close" className="text-danger-main text-xl" onClick={() => RemoveInvoiceItem(index)} />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="d-flex flex-wrap justify-content-between gap-2">
                                        <div>
                                            <div>
                                                <button
                                                    type="button"
                                                    id="addRow"
                                                    className="btn btn-sm btn-primary-600 radius-8 d-inline-flex align-items-center gap-1"
                                                    onClick={addInvoiceItem}
                                                >
                                                    <Icon icon="simple-line-icons:plus" className="text-xl" />
                                                    Add New
                                                </button>
                                            </div>

                                            <div className="mt-3">
                                                <label className="floting-label">Note: </label>
                                                <textarea
                                                    name="#0"
                                                    className="form-control"
                                                    rows={3}
                                                    cols={40}
                                                    placeholder="Enter a description..."
                                                    value={invoiceData.note}
                                                    onChange={(e) => {
                                                        handleChange("note", e.target.value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <table className="text-sm">
                                                <tbody>
                                                    <tr>
                                                        <td className="pe-64">
                                                            <div className="mb-1 d-flex align-items-center">
                                                                <label className="me-2 fw-600">
                                                                    Subtotal:{" "}
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td className="pe-16">
                                                            <span className="text-primary-light fw-semibold">
                                                                {state?.SYMBOL} {invoiceData.subtotal}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="pe-64">
                                                            <div className="d-flex align-items-center">
                                                                <label className="me-2 fw-600">
                                                                    Discount:{" "}
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control-plaintext ms-1 form-text-color"
                                                                    value={invoiceData.discountPercentage}
                                                                    onChange={(e) =>
                                                                        handleChange(
                                                                            "discountPercentage",
                                                                            e.target.value
                                                                        )
                                                                    }
                                                                    placeholder="0"
                                                                    style={{
                                                                        fontSize: 14,
                                                                        width: 20,
                                                                        textAlign: "center",
                                                                    }}
                                                                />
                                                                {"(%)"}
                                                            </div>
                                                        </td>
                                                        <td className="pe-16">
                                                            <span className="text-primary-light fw-semibold">
                                                                {state?.SYMBOL} {invoiceData.discountAmount || "0.00"}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="pe-64 border-bottom pb-4">
                                                            <div className="d-flex align-items-center">
                                                                <label className="me-2 fw-600">{state?.TAXTYPE == "GST" ? "GST" : "Tax"}: </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control-plaintext ms-1 form-text-color"
                                                                    value={invoiceData.gstPercentage}
                                                                    onChange={(e) => {
                                                                        handleChange(
                                                                            "gstPercentage",
                                                                            e.target.value
                                                                        );
                                                                    }}
                                                                    placeholder="0"
                                                                    style={{
                                                                        fontSize: 14,
                                                                        width: 20,
                                                                        textAlign: "center",
                                                                    }}
                                                                />
                                                                {"(%)"}
                                                            </div>
                                                        </td>
                                                        <td className="pe-16 border-bottom">
                                                            <span className="text-primary-light fw-semibold">
                                                                {state?.SYMBOL} {invoiceData.gstAmount || "0.00"}
                                                            </span>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="pe-64 pt-4">
                                                            <span className="text-primary-light fw-semibold">
                                                                Payable Amount:
                                                            </span>
                                                        </td>
                                                        <td className="pe-16 pt-4">
                                                            <span className="text-primary-light fw-semibold">
                                                                {state?.SYMBOL}{" "}
                                                                {invoiceData.totalAmount || "0.00"}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-4" style={{ marginTop: 10 }}>
                                                                {/* Checkbox */}
                                                                {state?.TAXTYPE == "GST" && (<div className="form-check checked-primary d-flex align-items-center gap-2">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        id="igstCheckbox"
                                                                        checked={invoiceData.isigst}
                                                                        onChange={() => { handleChange("isigst", !invoiceData.isigst) }}
                                                                    />
                                                                    <label
                                                                        className="form-check-label line-height-1 fw-medium text-secondary-light"
                                                                        htmlFor="igstCheckbox"
                                                                    >
                                                                        IGST
                                                                    </label>
                                                                </div>)}

                                                                {/* Switch */}
                                                                <div className="form-switch switch-success d-flex align-items-center gap-3">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        role="switch"
                                                                        id="horizontal3"
                                                                        checked={invoiceData.isPaid}
                                                                        onChange={() => { handleChange("isPaid", !invoiceData.isPaid) }}
                                                                    />
                                                                    <label
                                                                        className="form-check-label line-height-1 fw-medium text-secondary-light"
                                                                        htmlFor="horizontal3"
                                                                        style={{ width: 80 }}
                                                                    >
                                                                        {invoiceData.isPaid ? "Paid  " : "Unpaid"}
                                                                    </label>
                                                                </div>
                                                            </div>

                                                        </td>
                                                    </tr>
                                                </tbody >
                                            </table >
                                        </div >
                                    </div >
                                </div >

                                <div
                                    className="d-flex gap-2 justify-content-end"
                                    style={{ marginTop: 20 }}
                                >
                                    <button
                                        type="reset"
                                        onClick={() => {
                                            navigate(-1);
                                        }}
                                        className="border btn-sm border-danger-600 bg-hover-danger-200 text-danger-600 text-md px-40 py-6 radius-8"
                                    >
                                        Cancel
                                    </button>

                                    <button
                                        type="button"
                                        className="btn btn-sm btn-primary-600 radius-8 d-inline-flex align-items-center px-40 py-6 radius-8 gap-2"
                                        onClick={OnPressSave}
                                    >
                                        <Icon icon="simple-line-icons:check" className="text-xl" />
                                        Save
                                    </button>
                                </div>
                            </div >
                        </div >
                    </div >
                </div >
            </div >

            <Modal show={showPopup} centered size="xl" style={{ marginLeft: '8%' }}>
                <Modal.Header closeButton onClick={() => setShowpopup(false)}>
                    <Modal.Title>Add Customer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="row">
                            <div className="col-md-6 col-sm-12">
                                <div className="mb-3">
                                    <label className="me-2 fw-600">
                                        Customer Name<span className="text-danger-600">*</span>
                                    </label>
                                    <div className="d-flex align-items-center gap-2">
                                        <input
                                            type="name"
                                            className={`form-control ms-1 ${customerError.name ? "border-danger" : ""
                                                }`}
                                            value={newCustomerData.name}
                                            onChange={(e) =>
                                                handleChangeCustomerDetails("name", e.target.value)
                                            }
                                            placeholder="Enter Name"
                                            style={{ fontSize: 14 }}
                                        />
                                        {/* Error Message */}
                                        {customerError.name && (
                                            <span
                                                className="tooltip-container text-danger ms-2"
                                            >
                                                <i className="fa-solid fa-circle-exclamation"></i>
                                                <span className="tooltip-text">
                                                    Please enter valid name!
                                                </span>
                                            </span>
                                        )}
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="mb-3">
                                    <label className="me-2 fw-600">
                                        Email <span className="text-danger-600">*</span>
                                    </label>
                                    <div className="d-flex align-items-center gap-2">
                                        <input
                                            type="name"
                                            className={`form-control ms-1 ${customerError.email ? "border-danger" : ""
                                                }`}
                                            value={newCustomerData.email}
                                            onChange={(e) =>
                                                handleChangeCustomerDetails("email", e.target.value)
                                            }
                                            placeholder="Enter Email"
                                            style={{ fontSize: 14 }}
                                        />
                                        {/* Error Message */}
                                        {customerError.email && (
                                            <span
                                                className="tooltip-container text-danger ms-2"
                                            >
                                                <i className="fa-solid fa-circle-exclamation"></i>
                                                <span className="tooltip-text">
                                                    Please enter valid email!
                                                </span>
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="mb-3">
                                    <label className="me-2 fw-600">
                                        Phone<span className="text-danger-600">*</span>
                                    </label>
                                    <div className="d-flex align-items-center gap-2">
                                        <input
                                            type="name"
                                            className={`form-control ms-1 ${customerError.phone ? "border-danger" : ""
                                                }`}
                                            value={newCustomerData.phone}
                                            onChange={(e) =>
                                                handleChangeCustomerDetails("phone", e.target.value)
                                            }
                                            placeholder="Enter Phone Number"
                                            style={{ fontSize: 14 }}
                                        />
                                        {/* Error Message */}
                                        {customerError.phone && (
                                            <span
                                                className="tooltip-container text-danger ms-2"
                                            >
                                                <i className="fa-solid fa-circle-exclamation"></i>
                                                <span className="tooltip-text">
                                                    Please enter valid phone!
                                                </span>
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="mb-3">
                                    <label className="me-2 fw-600">
                                        GST Number
                                    </label>
                                    <input
                                        type="name"
                                        className="form-control ms-1"
                                        value={newCustomerData.gstNumber}
                                        onChange={(e) =>
                                            handleChangeCustomerDetails("gstNumber", e.target.value)
                                        }
                                        placeholder="Enter GST Number"
                                        style={{ fontSize: 14 }}
                                    />
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-12">
                                <label className="me-2 fw-600">Address: </label>
                                <textarea
                                    name="#0"
                                    className="form-control"
                                    rows={4}
                                    cols={50}
                                    placeholder="Enter a address..."
                                    value={newCustomerData.address}
                                    onChange={(e) => {
                                        handleChangeCustomerDetails("address", e.target.value);
                                    }}
                                />
                            </div>
                        </div>

                        <div
                            className="d-flex gap-2 justify-content-end mt-3"
                        >
                            <button
                                type="reset"
                                onClick={() => {
                                    setShowpopup(false)
                                }}
                                className="border btn-sm border-danger-600 bg-hover-danger-200 text-danger-600 text-md px-40 py-6 radius-8"
                            >
                                Cancel
                            </button>

                            <button
                                type="button"
                                className="btn btn-sm btn-primary-600 radius-8 d-inline-flex align-items-center px-40 py-6 radius-8 gap-2"
                                onClick={handleSaveCustomer}
                            >
                                <Icon icon="simple-line-icons:check" className="text-xl" />
                                Save
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

        </div >

    );
};

export default InvoiceEditLayer;