import PropTypes from "prop-types";
import React, { createContext, useReducer, useEffect } from "react";
import * as actionType from "./actions";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../Utils/firebase";
import FetchAllDataFromFirebase from "../data/Firebase/FetchAllDataFromFirebase";
import { GetAllData } from "../data";

const initialState = JSON.parse(localStorage.getItem("appState")) || {
  SET_USER: null,
  USERID: null,
  BUSINESSES: [],
  INVOICES: [],
  CUSTOMERS: [],
  SOLDITEMS: [],
  TOTALEARNING: 0,
  TOTALPAIDEARNING: 0,
  TOTALUNPAIDEARNING: 0,
  SELECTEDINVOICE: null,
  SELECTEDBUSINESS: null,
  SELECTEDCUSTOMER: null,
  SESSIONS: [],
  PAYMENT: [],
  SYMBOL: "₹",
  CURRENCYCODE: "INR",
  CURRENCYNAME: "India",
  TAXTYPE: "GST",
  PREMIUM: false,
  PREMIUMLASTDATE: null,
  UNIT: "Qty",
  ACTIVEFILTER: "All",
  ACTIVEPAGE: "Dashboard",
  SELECTEDPREVIEW: 1
};

const ConfigContext = createContext();
const { Provider } = ConfigContext;

const configReducer = (state, action) => {
  let newState;
  switch (action.type) {
    case actionType.SET_USER:
      newState = { ...state, SET_USER: action.payload };
      break;

    case actionType.EMPTY_ALL_STATE:
      newState = {
        SET_USER: null,
        USERID: localStorage.getItem("AccessUserId"),
        BUSINESSES: [],
        INVOICES: [],
        CUSTOMERS: [],
        SOLDITEMS: [],
        SESSIONS: [],
        TOTALEARNING: 0,
        TOTALPAIDEARNING: 0,
        TOTALUNPAIDEARNING: 0,
        SELECTEDINVOICE: null,
        SELECTEDBUSINESS: null,
        SELECTEDCUSTOMER: null,
        PAYMENT: [],
        SYMBOL: "₹",
        CURRENCYCODE: "INR",
        TAXTYPE: "GST",
        CURRENCYNAME: "India",
        UNIT: "Qty",
        PREMIUM: false,
        PREMIUMLASTDATE: null,
        ACTIVEFILTER: "All",
        ACTIVEPAGE: "Dashboard",
        SELECTEDPREVIEW: 1
      };
      break;

    case actionType.USERID:
      newState = { ...state, USERID: action.payload };
      break;

    case actionType.BUSINESSES:
      newState = { ...state, BUSINESSES: action.payload };
      break;

    case actionType.INVOICES:
      newState = { ...state, INVOICES: action.payload };
      break;

    case actionType.CUSTOMERS:
      newState = { ...state, CUSTOMERS: action.payload };
      break;

    case actionType.SOLDITEMS:
      newState = { ...state, SOLDITEMS: action.payload };
      break;

    case actionType.TOTALEARNING:
      newState = { ...state, TOTALEARNING: action.payload };
      break;

    case actionType.TOTALPAIDEARNING:
      newState = { ...state, TOTALPAIDEARNING: action.payload };
      break;

    case actionType.TOTALUNPAIDEARNING:
      newState = { ...state, TOTALUNPAIDEARNING: action.payload };
      break;

    case actionType.SELECTEDINVOICE:
      newState = { ...state, SELECTEDINVOICE: action.payload };
      break;

    case actionType.SELECTEDBUSINESS:
      newState = { ...state, SELECTEDBUSINESS: action.payload };
      break;

    case actionType.SELECTEDCUSTOMER:
      newState = { ...state, SELECTEDCUSTOMER: action.payload };
      break;

    case actionType.SESSIONS:
      return { ...state, SESSIONS: action.payload };

    case actionType.PAYMENT:
      newState = { ...state, PAYMENT: action.payload };
      break;

    case actionType.SYMBOL:
      newState = { ...state, SYMBOL: action.payload };
      break;

    case actionType.CURRENCYCODE:
      newState = { ...state, CURRENCYCODE: action.payload };
      break;

    case actionType.CURRENCYNAME:
      newState = { ...state, CURRENCYNAME: action.payload };
      break;

    case actionType.FLAG:
      newState = { ...state, FLAG: action.payload };
      break;

    case actionType.TAXTYPE:
      newState = { ...state, TAXTYPE: action.payload };
      break;

    case actionType.UNIT:
      newState = { ...state, UNIT: action.payload };
      break;

    case actionType.PREMIUM:
      newState = { ...state, PREMIUM: action.payload };
      break;

    case actionType.PREMIUMLASTDATE:
      newState = { ...state, PREMIUMLASTDATE: action.payload };
      break;

    case actionType.SELECTEDPREVIEW:
      newState = { ...state, SELECTEDPREVIEW: action.payload };
      break;

    case actionType.ACTIVEFILTER:
      newState = { ...state, ACTIVEFILTER: action.payload };
      break;

    case actionType.ACTIVEPAGE:
      newState = { ...state, ACTIVEPAGE: action.payload };
      break;

    default:
      newState = state;
  }

  // Save state to localStorage after every action
  localStorage.setItem("appState", JSON.stringify(newState));
  return newState;
};

const ConfigProvider = ({ children }) => {
  const [state, dispatch] = useReducer(configReducer, initialState);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        dispatch({
          type: actionType.USERID,
          payload: user?.providerData?.[0]?.uid,
        });
        dispatch({ type: actionType.SET_USER, payload: user });
        GetAllData(state, dispatch);
      }
    });
    return () => unsubscribe();
  }, []);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

ConfigProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { ConfigContext, ConfigProvider };
