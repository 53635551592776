import { ref, update } from "firebase/database";
import { database } from "../../../Utils/firebase";
import UploadImageOnFirebase from "../UploadImage";
import GetBusinessDataFirebase from "./GetBusinessDataFirebase";

const EditBusinessDataInFirebase = async (state, dispatch, businessdata) => {

    if (!state?.USERID || state?.USERID == null || state?.USERID == undefined) return null;

    try {
        const file = businessdata?.companyPhoto;
        let fileURL = businessdata?.companyPhotoURL || null; // Preserve existing photo if no new upload
        
        // Upload new image if a file is provided
        if (file && typeof file !== "string") {
            const res = await UploadImageOnFirebase(state?.USERID, file);
            fileURL = res?.downloadURL;
        }

        // Ensure companyId is provided to identify the company to update
        if (!businessdata?.id) {
            throw new Error("Company ID is required for updating data.");
        }

        // Reference to the specific company data
        const companyRef = ref(database, `invoiceGenerator/users/${state?.USERID}/company/${businessdata.id}`);

        // Update company data
        await update(companyRef, {
            companyName: businessdata?.companyName?.toString(),
            address: businessdata?.address?.toString(),
            gstNumber: businessdata?.gstNumber?.toString(),
            terms: businessdata?.terms?.toString(),
            companyPhoto: fileURL,
            updateDate: businessdata?.updateDate?.toString(),
        });

        // Fetch updated company data
        await GetBusinessDataFirebase(state, dispatch);

        return { success: true, message: "Company data updated successfully!" };
    } catch (error) {
        return { success: false, message: error.message || "An error occurred while updating the company" };
    }
};

export default EditBusinessDataInFirebase;
