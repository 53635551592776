import * as actionType from "../../context/actions";

const GetEarnings = async (state, dispatch, data) => {
    
    if (!data) return null;
    try {

        let totalEarnings = 0;
        let paidEarnings = 0;
        let unpaidEarnings = 0;
        
        data?.map(([id, info]) => {
            if (info.totalAmount) {
                totalEarnings += parseFloat(info.totalAmount);
            }

            if(info.paidFlagKey == "true" || info.paidFlagKey == true){
                paidEarnings += parseFloat(info.totalAmount);
            }
            else{
                unpaidEarnings += parseFloat(info.totalAmount);
            }
        });

        dispatch({ type: actionType.TOTALEARNING, payload: totalEarnings.toFixed(2)});
        dispatch({ type: actionType.TOTALPAIDEARNING, payload: paidEarnings.toFixed(2)});
        dispatch({ type: actionType.TOTALUNPAIDEARNING, payload: unpaidEarnings.toFixed(2)});

        return { totalEarnings, paidEarnings, paidEarnings };
    } catch (error) {
        console.error("Error fetching company data:", error);
        return null;
    }
};

export default GetEarnings;