import { ref, get } from "firebase/database";
import { database, storage } from "../../../Utils/firebase";
import * as actionType from "../../../context/actions";
import { getDownloadURL } from "firebase/storage";

const GetBusinessDataFirebase = async (state, dispatch) => {
    if (!state?.USERID || state?.USERID == null || state?.USERID == undefined) return null;

    try {
        const userRef = ref(database, `invoiceGenerator/users/${state?.USERID}/company`);
        const snapshot = await get(userRef);
        const data = snapshot.val();

        if (!data) return null;


        const sortedData = Object.entries(data)
            .map(([id, info]) => ({
                id, // Firebase unique ID
                ...info, // Spread existing company data
            }))
            .sort((a, b) => b.creationDate - a.creationDate) // Sort in descending order
            .map((item, index) => ({
                srNo: index + 1, // Assign serial number after sorting
                ...item,
            }));

        dispatch({ type: actionType.BUSINESSES, payload: sortedData });
        return sortedData;
    } catch (error) {
        console.error("Error fetching company data:", error);
        return null;
    }
};

export default GetBusinessDataFirebase;