import { HandlePaymentSuccess } from "./HandlePaymentSuccess";

export const HandlePayment = (subscriptionId, state, dispatch, setLoading, navigate) => {
    if (!subscriptionId) {
        console.error("Subscription ID not available yet");
        return;
    }

    const options = {
        key: "rzp_test_O5YpNjI1WJIySR", // Replace with your Razorpay Key ID
        subscription_id: subscriptionId,
        name: "BillNama",
        description: "This is testing plan",
        image: '../../public/assets/images/billnama_icon.png',
        callback_url: "https://app.billnama.com/payment-successfully",
        prefill: {
            name: "",
            email: "unnati.softnoesis@gmail.com",
            contact: "+919714023780",
        },
        handler: function (response) {
            console.log("Payment successful!", response);
            HandlePaymentSuccess(response, state, dispatch, navigate);
        },
        modal: {
            ondismiss: function () {
                console.log("Payment popup closed by user");
                setLoading(false)
            },
        },
        theme: {
            color: "#3399cc",
        },
    };

    const rzp1 = new window.Razorpay(options);

    rzp1.on("payment.failed", function (response) {
        console.error("Payment failed!", response);
        setLoading(false)
        alert("Payment failed: " + response.error.description);
    });


    rzp1.open();
};