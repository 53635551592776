import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import InvoiceTableDataLayer from "./InvoiceTableDataLayer";
import BusinessTableDataLayer from "./BusinessTableDataLayer";
import { ConfigContext } from "../context/GlobalContext";

const BusinessListLayer = ({ BusinessData }) => {
    const { state } = useContext(ConfigContext);

    return (
        <div className="card basic-data-table">
            <BusinessTableDataLayer BusinessData={BusinessData} />
        </div >


    );
};

export default BusinessListLayer;
