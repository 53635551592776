import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DateRangePicker from "./DateRangePicker";
import { ConfigContext } from "../context/GlobalContext";
import * as actionType from '../context/actions';

const Breadcrumb = ({ title }) => {

  const { state, dispatch } = useContext(ConfigContext);
  const [activeFillter, setActiveFillter] = useState(state?.ACTIVEFILTER || "All")
  const [isOpenPicker, setIsOpenPicker] = useState(false);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);

  const HandleApply = (date) => {
    const selectedDate = date[0] ?? date[1]; // Get non-null date

    const updatedDate = [
      date[0] ?? selectedDate,
      date[1] ?? selectedDate
    ];
    setIsOpenPicker(false)
    setDateRange(updatedDate);
    dispatch({ type: actionType.ACTIVEFILTER, payload: `Custome, ${new Date(updatedDate[0]).getTime()}, ${new Date(updatedDate[1]).getTime()}` })
  }

  const HandleCancel = () => {
    setIsOpenPicker(false)
    if (state?.ACTIVEFILTER?.includes("Custom")) {
      setActiveFillter("Custom")
    }
    else {
      setActiveFillter(state?.ACTIVEFILTER)
    }
  }

  useEffect(() => {
    if (activeFillter !== "Custom") {
      setDateRange([new Date(), new Date()])
      dispatch({ type: actionType.ACTIVEFILTER, payload: activeFillter })
    }
  }, [activeFillter])

  useEffect(() => {
    if (state?.ACTIVEPAGE !== title) {
      dispatch({ type: actionType.ACTIVEPAGE, payload: title })
      setActiveFillter("All")
    }
  }, [title])

  return (
    <>
      <div className='d-flex flex-wrap align-items-center justify-content-between gap-3 mb-24'>
        <h6 className='fw-semibold mb-0'>{title}</h6>
        {(title != "Currency" && title != "sessions" && title != "Payment") && (<div className="dropdown">
          <button
            className="btn btn-outline-primary-600 not-active px-18 py-11 dropdown-toggle toggle-icon"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {" "}
            {activeFillter == "Custom" ? `${new Date(dateRange[0]).toLocaleDateString()} - ${new Date(dateRange[1]).toLocaleDateString()}` : activeFillter}{" "}
          </button>
          <ul className="dropdown-menu">
            <li>
              <Link
                className={`dropdown-item px-16 py-8 rounded text-secondary-light bg-hover-neutral-200 text-hover-neutral-900 ${activeFillter == "All" && "bg-primary-50"}`}
                to="#"
                onClick={() => { setActiveFillter("All") }}
              >
                All
              </Link>
            </li>
            <li>
              <Link
                className={`dropdown-item px-16 py-8 rounded text-secondary-light bg-hover-neutral-200 text-hover-neutral-900 ${activeFillter == "Today" && "bg-primary-50"}`}
                to="#"
                onClick={() => { setActiveFillter("Today") }}
              >
                Today
              </Link>
            </li>
            <li>
              <Link
                className={`dropdown-item px-16 py-8 rounded text-secondary-light bg-hover-neutral-200 text-hover-neutral-900 ${activeFillter == "Yeasterday" && "bg-primary-50"}`}
                to="#"
                onClick={() => { setActiveFillter("Yeasterday") }}
              >
                Yeasterday
              </Link>
            </li>
            <li>
              <Link
                className={`dropdown-item px-16 py-8 rounded text-secondary-light bg-hover-neutral-200 text-hover-neutral-900 ${activeFillter == "Last 7 Days" && "bg-primary-50"}`}
                to="#"
                onClick={() => { setActiveFillter("Last 7 Days") }}
              >
                Last 7 Days
              </Link>
            </li>
            <li>
              <Link
                className={`dropdown-item px-16 py-8 rounded text-secondary-light bg-hover-neutral-200 text-hover-neutral-900 ${activeFillter == "This Month" && "bg-primary-50"}`}
                to="#"
                onClick={() => { setActiveFillter("This Month") }}
              >
                This Month
              </Link>
            </li>
            <li>
              <Link
                className={`dropdown-item px-16 py-8 rounded text-secondary-light bg-hover-neutral-200 text-hover-neutral-900 ${activeFillter == "Last Month" && "bg-primary-50"}`}
                to="#"
                onClick={() => { setActiveFillter("Last Month") }}
              >
                Last Month
              </Link>
            </li>
            <li>
              <Link
                className={`dropdown-item px-16 py-8 rounded text-secondary-light bg-hover-neutral-200 text-hover-neutral-900 ${activeFillter == "Custom" && "bg-primary-50"}`}
                to="#"
                onClick={() => {
                  setActiveFillter("Custom")
                  setIsOpenPicker(true)
                }}
              >
                Custom
              </Link>
            </li>
          </ul>
        </div>)}
      </div>
      {isOpenPicker && <DateRangePicker isOpen={isOpenPicker} handleApply={HandleApply} handleCancel={HandleCancel} dates={dateRange} />}
    </>
  );
};

export default Breadcrumb;
