import React, { useContext, useEffect, useState } from "react";

import { ConfigContext } from "../context/GlobalContext";
import PreviewSidebar from "../components/preview/PreviewSidebar";
import MainPreview from "../components/preview/MainPreview";
import PreviewHeader from "../components/preview/PreviewHeader";
import Preview from "../components/preview/PreviewSidebar";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useLocation } from "react-router-dom"
import { getImage } from "../Utils/image_helper";
import { useNavigate } from "react-router-dom";


const InvoicePreviewPage = () => {
    const { state } = useContext(ConfigContext);
    const navigate = useNavigate();
    const location = useLocation();


    // Get the selected invoice from state
    const selectedInvoice = state?.INVOICES.find(
        (invoice) => invoice.invoiceId === state?.SELECTEDINVOICE
    );

    let [MobilePreview, setMobilePreview] = useState(false);

    // State to store selected template ID (default to 1)
    const [selectedTemplate, setSelectedTemplate] = useState(1);
    let mobileMenuControl = () => {
        setMobilePreview(!MobilePreview);
    };
    let [sidebarActive, seSidebarActive] = useState(false);

    useEffect(() => {
        setSelectedTemplate(state?.SELECTEDPREVIEW)
    }, [])

    return (
        <>
            <div className="row bg-white  text-black me-1 ms-1 overflow-x-hidden">
                {/* Sidebar for selecting templates */}
                <div className={` ${MobilePreview ? "overlay active" : "overlay"}`}>
                    <section className={`sidebar ${sidebarActive ? "active" : MobilePreview ? "sidebar-open" : ""} ${MobilePreview ? "show-sidebar" : "hide-sidebar"}
  `}>
                        <Preview setSelectedTemplate={setSelectedTemplate} selectedTemplate={selectedTemplate} mobileMenuControl={mobileMenuControl} />
                        <button
                            onClick={mobileMenuControl}
                            type="button"
                            className="sidebar-close-btns"
                        >
                            <Icon icon="radix-icons:cross-2" />
                        </button>
                    </section>

                </div>

                <div className="d-flex justify-content-between invoice_preview_button d-lg-none no-printme" style={{ marginTop: "1%", marginBottom: "1%", marginRight: "1%", padding: "0px" }}>
                    <button
                        onClick={mobileMenuControl}
                        type="button"
                        className=" sidebar-mobile-toggle text-black  ms-2 mt-2 bg-primary-light text-primary-600 rounded-circle p-2 d-inline-flex justify-content-center align-items-center no-printme"
                    >
                        <img src={getImage("viewIcon")} alt="view Icon" className="no-printme" height={"25px"} width={"25px"}/>
                    </button>

                    {/* <button
                    type="button"
                    className="no-printme sidebar-close-btn text-black "
                    onClick={()=>navigate(-1)}
                >
               <Icon icon="radix-icons:cross-2" />
                </button> */}
                    {/* <button
                type="button"
                className="no-printme sidebar-close-btn text-black "
              onClick={() => { navigate(-1) }}
            >
              <i class="fa fa-arrow-left" style={{ fontSize: 18}}></i>
            </button> */}
                </div>

                <div className="hide_sidebar col-2 ">
                    <Preview setSelectedTemplate={setSelectedTemplate} selectedTemplate={selectedTemplate} />

                </div>

                <div className="layer col-lg-10 col-md-12 bg-white overflow-x-hidden text-black">
                    {/* MainPreview handles dynamic template display */}
                    <MainPreview selectedTemplate={selectedTemplate} selectedInvoice={selectedInvoice} /></div>
                {/* <h6>hello</h6> */}
            </div>

        </>
    );
};

export default InvoicePreviewPage;
