import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../../Utils/firebase";
import { v4 } from "uuid";

const UploadImageOnFirebase = async (userID, file) => {
    if (!file) {
        console.error("No file selected");
        return;
    }


    const storageRef = ref(storage, `invoiceGenrator/users/${userID}/${v4()}`);

    try {
        // Upload file to Firebase Storage
        const uploadSnapshot = await uploadBytes(storageRef, file);

        // Get the file's download URL
        const downloadURL = await getDownloadURL(uploadSnapshot.ref);
        return { downloadURL: downloadURL }

        // You can now store this `downloadURL` in your database (Firestore/Realtime DB)
    } catch (error) {
        console.error("Error uploading file:", error);
        return { downloadURL: null }
    }
};

export default UploadImageOnFirebase;