import React from "react";
import { Icon } from "@iconify/react";

const PaginationView = ({ currentPage, totalPages, handlePageChange }) => {
  const isMobile = window.innerWidth < 768; // Check for mobile screens
  const visiblePages = isMobile ? 2 : 3; // Fewer page numbers on mobile
  const pages = [];

  // Determine the page range
  let startPage = Math.max(1, currentPage - Math.floor(visiblePages / 2));
  let endPage = Math.min(totalPages, startPage + visiblePages - 1);

  if (endPage - startPage < visiblePages - 1) {
    startPage = Math.max(1, endPage - visiblePages + 1);
  }

  for (let i = startPage; i <= endPage; i++) {
    pages.push(i);
  }

  return (
    <div className="pagination-container">
      <ul className="pagination d-flex flex-wrap align-items-center gap-2 justify-content-center">
        {/* Previous Page */}
        <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
          <button
            className="page-link pagination-btn"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <Icon icon="iconamoon:arrow-left-2-light" className="text-xxl" />
          </button>
        </li>

        {/* Show "..." if needed before first visible page */}
        {startPage > 1 && (
          <li className="page-item">
            <span className="page-link pagination-btn">...</span>
          </li>
        )}

        {/* Page Numbers */}
        {pages.map((page) => (
          <li key={page} className="page-item">
            <button
              className={`page-link pagination-btn ${
                currentPage === page ? "active" : ""
              }`}
              onClick={() => handlePageChange(page)}
            >
              {page}
            </button>
          </li>
        ))}

        {/* Show "..." if needed after last visible page */}
        {endPage < totalPages && (
          <li className="page-item">
            <span className="page-link pagination-btn">...</span>
          </li>
        )}

        {/* Next Page */}
        <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
          <button
            className="page-link pagination-btn"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            <Icon icon="iconamoon:arrow-right-2-light" className="text-xxl" />
          </button>
        </li>
      </ul>
    </div>
  );
};

export default PaginationView;
