import React from "react";
import MasterLayout from "../masterLayout/MasterLayout";
import Breadcrumb from "../components/Breadcrumb";
import ErrorLayer from "../components/ErrorLayer";


const ErrorPage = () => {
  return (
    <div className="bg-white">
      {/* ErrorLayer */}
      <ErrorLayer />
    </div>
  );
};

export default ErrorPage;
