const BusinessDataValidation = (businessData) => {
    let isValid = false;
    let Error = {
        companyName: ""
    }

    if(businessData?.companyName?.trim() == ""){
        Error.companyName = "Please enter valid business name!"
    }
    else{
        isValid = true;
    }
    
    return {isValid: isValid, errors: Error}
}

export default BusinessDataValidation;