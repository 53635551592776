import React, { useContext, useEffect } from 'react'
import BannerInnerOne from './child/BannerInnerOne'
import BusinessOverview from './child/BusinessOverview'
import RecentInvoices from './child/RecentInvoices'
import RecentBusiness from './child/RecentBusiness'
import { ConfigContext } from '../context/GlobalContext'
import DashboardChrat from './DashboardChart'
import * as actionType from '../context/actions'

const DashBoardLayerSeven = () => {

    const { state, dispatch } = useContext(ConfigContext);

    useEffect(() => {
        dispatch({type: actionType.ACTIVEFILTER, payload: "All"})
    }, [])
    
    return (
        <>
            <div className="row gy-4">
                <div className="col-xxl-8">
                    <div className="row gy-4">

                        {/* BannerInnerOne */}
                        <BannerInnerOne />

                        {/* TrendingBidsOne */}
                        <BusinessOverview state={state} disptch={dispatch}/>

                    </div>
                </div>

                <div className="col-xxl-4">
                    <div className="row gy-4">

                        {/* ETHPriceOne */}
                        <DashboardChrat />


                        {/* FeaturedCreatorsTwo */}
                        <RecentBusiness BusinessData={state?.BUSINESSES?.slice(0, 3)}/>

                    </div>
                </div>

                {/* RecentBidOne */}
                <RecentInvoices InvoicesData={state?.INVOICES?.slice(0, 5)}/>
            </div>
        </>
    )
}

export default DashBoardLayerSeven