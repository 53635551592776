import React,{useContext} from 'react'
import { Link } from 'react-router-dom'
import { ConfigContext } from '../../context/GlobalContext';

const BannerInnerOne = () => {
        const { state, dispatch } = useContext(ConfigContext);
   
    
    return (
        <div className="col-12">
            <div className="nft-promo-card card radius-12 overflow-hidden position-relative z-1">
                <img
                    src="assets/images/nft/nft-gradient-bg.png"
                    className="position-absolute start-0 top-0 w-100 h-100 z-n1"
                    alt=""
                />
                <div className="nft-promo-card__inner d-flex align-items-center">
                    <div className="nft-promo-card__thumb w-100">
                        <img
                            src="../assets/images/dashboard/dashbord_banner.svg"
                            alt=""
                            className="w-100 h-100 object-fit-cover"
                        />
                    </div>
                    <div className="flex-grow-1">
                        <h5 className="mb-16 text-white">
                            BillNama - Manage Your Invoices, Items & Customers Effortlessly
                        </h5>
                        <p className="text-white text-md">
                            Easily track your daily invoices, manage items, and keep customer records in one place. BillNama streamlines your billing process, saves time, and helps you stay organized—whether you're a small business
                        </p>
                        <div className="d-flex align-items-center flex-wrap mt-24 gap-16">
                            {/* <Link
                                to="#"
                                className="btn rounded-pill border br-white text-white radius-8 px-32 py-11 hover-bg-white text-hover-neutral-900"
                            >
                                Explore
                            </Link> */}
                            <Link
                                to={state?.BUSINESSES?.length > 0 || state?.PREMIUM?.length>0 ? "/invoice-add" : "/business-add"}
                                className="btn rounded-pill btn-primary-600 radius-8 px-28 py-11"
                            >
                                Create Now
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BannerInnerOne