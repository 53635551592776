import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export const DownloadDocument = async (setLoading) => {
  setLoading(true); // Show loader

  const data = document.getElementById("divToPrint");

  if (!data) {
    console.error("Error: divToPrint not found!");
    setLoading(false);
    return;
  }

  try {
    const canvas = await html2canvas(data, {
      useCORS: true,
      allowTaint: true,
      scale: 2, // High quality
    });

    const imgData = canvas.toDataURL("image/png");

    // Standard A4 size in mm (portrait)
    const pdfWidth = 210;
    const pdfHeight = 297;

    // Get actual canvas dimensions (in pixels)
    const imgWidth = canvas.width;
    const imgHeight = canvas.height;

    // Convert pixels to mm (assuming 96dpi -> 1 inch = 25.4mm)
    const imgWidthMM = (imgWidth * 25.4) / 96;
    const imgHeightMM = (imgHeight * 25.4) / 96;

    // Calculate best scale factor to fit within A4
    const scaleFactor = Math.min(pdfWidth / imgWidthMM, pdfHeight / imgHeightMM);

    const imgScaledWidth = imgWidthMM * scaleFactor;
    const imgScaledHeight = imgHeightMM * scaleFactor;

    // Center the image in the PDF
    const xOffset = (pdfWidth - imgScaledWidth) / 2;
    const yOffset = (pdfHeight - imgScaledHeight) / 2;

    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "a4",
    });

    pdf.addImage(imgData, "PNG", 5, 5, 200, 280);
    pdf.save("invoice.pdf");
  } catch (error) {
    console.error("Error generating PDF:", error);
  } finally {
    setLoading(false); // Hide loader
  }
};







