import * as actionType from '../../context/actions'
import GetBusinessDataLocal from './Business/GetBusinessDataLocal';
import GetInvoiceDataLocal from './Invoice/GetInvoiceDataLocal';

const FetchAllDataFromLocalStorage = async (state, dispatch) => {

    try {        
        const companyData = await GetBusinessDataLocal(state, dispatch);
        const invoiceData = await GetInvoiceDataLocal(state, dispatch);
        const SessionData = [];
        const PaymentData = [];

        return { companyData, invoiceData,SessionData,PaymentData};
        
    } catch (error) {
        console.error("Error fetching all data:", error);
        return null;
    }
    
};

export default FetchAllDataFromLocalStorage;
