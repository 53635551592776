import { Icon } from '@iconify/react/dist/iconify.js'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { FilterdData } from '../../helper/FilterdData';
import { ConfigContext } from '../../context/GlobalContext';
import DataNotFoundView from '../DataNotFoundView';
import * as actionType from '../../context/actions';
import { useNavigate } from 'react-router-dom';

const RecentBusiness = () => {

    const navigate = useNavigate();

    const { state, dispatch } = useContext(ConfigContext);

    const [businessData, setBusinessData] = useState(state?.BUSINESSES);

    useEffect(() => {
        setBusinessData(FilterdData(state, state?.BUSINESSES, "creationDate")?.slice(0, 3))
    }, [state])

    return (

        <div className="col-xxl-12 col-md-6">
            <div className="card h-100">
                <div className="card-header border-bottom d-flex align-items-center flex-wrap gap-2 justify-content-between">
                    <h6 className="fw-bold text-lg mb-0">Recent Businesses</h6>
                    {businessData.length > 0 && (<Link
                        to="/business-list"
                        className="text-primary-600 hover-text-primary d-flex align-items-center gap-1"
                    >
                        View All
                        <Icon
                            icon="solar:alt-arrow-right-linear"
                            className="icon"
                        />
                    </Link>)}
                </div>
                <div className="card-body pt-24">
                    {businessData && businessData.length > 0 ? (
                        businessData.map((item, index) => (
                            <div 
                                key={index}
                                className='cursor-pointer'
                                onClick={() => {
                                    dispatch({type: actionType.SELECTEDBUSINESS, payload: item.companyId});
                                    navigate("/business-view")
                                }}
                            >
                                    <button
                                        className="d-flex align-items-center justify-content-between gap-8 flex-wrap"
                                    >

                                        <div className="d-flex align-items-center">
                                            <img
                                                src={item.companyPhoto || "https://cdni.iconscout.com/illustration/premium/thumb/no-data-found-illustration-download-in-svg-png-gif-file-formats--paper-website-business-pack-illustrations-7438848.png?f=webp"}
                                                alt="Creator"
                                                className={`flex-shrink-0 me-12 w-40-px h-40-px object-fit-contain rounded-circle ${!item.companyPhoto && "rounded-circle border border-secondary p-2"}`}
                                            />
                                            <div className="flex-grow-1">
                                                <h6 className="text-md mb-0 fw-semibold">{item.companyName}</h6>
                                                {item.address && (<span className="text-sm text-secondary-light fw-normal">
                                                    {item.address}
                                                </span>)}
                                            </div>
                                        </div>
                                    </button>
                                {index !== businessData.length - 1 && <hr className="my-3 mx-0 border-secondary-light  opacity-30" />}
                            </div>
                        ))
                    ) : (
                        <DataNotFoundView />
                    )}

                </div>
            </div>
        </div>
    )
}

export default RecentBusiness