

import Breadcrumb from "../components/Breadcrumb";
import CurrencyView from "../components/CurrencyView";
import MasterLayout from "../masterLayout/MasterLayout";

export default function CurrencyPage() {

  return (
    <>
      <MasterLayout>
        <Breadcrumb title="Currency" />
        <CurrencyView />
      </MasterLayout>
    </>
  );
}
