import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useRef, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ConfigContext } from "../../context/GlobalContext";
import "../../../src/style/previewdesign5.css";
import { formatDate } from "../../Utils/formate_date";
const InvoicePreview5 = ({ selectedInvoice }) => {
    
  const inputRef = useRef(null);
  const { state } = useContext(ConfigContext);


  const BusinessData = state?.BUSINESSES.find(
    (business) => business.companyId == selectedInvoice?.companyId
  );
  console.log("business data..", selectedInvoice?.items);

  // const selectedInvoice = datas?.find(invo_data => invoice_id === invo_data[1].invoiceId);

  return (
  
    
    
      <>
      {selectedInvoice?(<div className="Invoice5">
          

          <div className="Invoices5" ref={inputRef}>
            <div className="Invoice-details" id="divToPrint" ref={inputRef}>
              {/* Company details code start  */}
             
                        <div className="bussiness-info">
                          <div className="bussiness-info-logo">
                            <img src={BusinessData.companyPhoto} />
                          </div>
                          <div >
                            <h6>{BusinessData.companyName}</h6>
                          </div>
                          <div className="bussiness-info-details" >
                            <p>
                              {" "}
                          
                              {state.TAXTYPE}: {BusinessData.gstNumber}
                            </p>
                            <p className="adress">
                              {" "}
                              {BusinessData.address}
                            </p>
                          </div>
                        </div>
                    
              {/* Company details code End  */}

              {/* customer-info start */}

          
                        <>
                          <div className="customer-info" >
                            <div className="Cus-name">
                              <p >Billed To</p>
                              <p style={{color:"black",fontWeight:"bold"}}>{selectedInvoice.customer}</p>
                              <span>
                              {state.TAXTYPE}: {selectedInvoice.customerGSTNo}
                              </span>
                              <span>{selectedInvoice.address}</span>
                              <span>{selectedInvoice.phone}</span>
                              <span>{selectedInvoice.email}</span>
                            </div>

                            <div className="Date">
                              <div>
                                <p>Date of Issue</p>
                                <span>{formatDate(selectedInvoice.date)}</span>
                              </div>

                              <div>
                                <p>Due Date</p>
                                <span>
                                  {formatDate(selectedInvoice.dueDate)}
                                </span>
                              </div>
                            </div>

                            <div
                              className="Gst"
                              style={{ marginRight: "20px" }}
                            >
                              <p>Invoice Number</p>
                              <span>{selectedInvoice.id}</span>
                            </div>

                            {/* <div className='number'>
                        <span><span>{invo_data[1].phone}</span></span>
                      </div>

                      <div className='Email'>
                        <span><span>{invo_data[1].email}</span></span>
                      </div> */}
                          </div>
                          <div className="purshcing-info">
                            <table
                              style={{
                                textAlign: "left",
                                width: "104%",
                                fontSize: "14px",
                                borderCollapse: "collapse",
                                marginBottom: "10px",
                                marginLeft: "-10px",
                                color:"black"
                              }}
                            >
                              <thead
                                className="table_header"
                                style={{ borderTop: "1.5px solid #4c69ff" }}
                              >
                                <tr
                                  style={{
                                    fontWeight: "500",
                                    color: "rgb(76, 105, 255)",
                                  }}
                                >
                                  <th
                                    style={{
                                      padding: " 3px",
                                      width: "20px",
                                      fontWeight: "500",
                                      paddingBottom: "5px",
                                    }}
                                  >
                                    No
                                  </th>
                                  <th
                                    style={{
                                      padding: " 3px",
                                      width: "40%",
                                      fontWeight: "500",
                                      paddingBottom: "5px",
                                    }}
                                  >
                                    Item
                                  </th>
                                  <th
                                    style={{
                                      padding: " 3px",
                                      fontWeight: "500",
                                      paddingBottom: "5px",
                                    }}
                                  >
                                    {state.UNIT}
                                  </th>
                              
                                  <th
                                    style={{
                                      padding: " 3px",
                                      fontWeight: "500",
                                      paddingBottom: "5px",
                                    }}
                                  >
                                    Price
                                  </th>
                                  <th
                                    style={{
                                      padding: " 3px",
                                      fontWeight: "500",
                                      paddingBottom: "5px",
                                    }}
                                  >
                                    Amount
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {selectedInvoice.items.map((items, i) => (
                                  <tr >
                                    <td
                                      style={{
                                        padding: "5px 3px",
                                        textAlign: "center",
                                      }}
                                      valign="top"
                                    >
                                      {i + 1}
                                    </td>
                                    <td
                                      style={{ padding: "5px 3px" }}
                                      valign="top"
                                    >
                                      <div>{items.name}</div>
                                      <div
                                        style={{
                                          color: "dimgray",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {items.subtitle}
                                      </div>
                                    </td>
                                    <td
                                      style={{ padding: "5px 3px" }}
                                      valign="top"
                                    >
                                      {items.quantity}
                                    </td>
                                    <td
                                      style={{ padding: "5px 3px" }}
                                      valign="top"
                                    >
                                      {state.SYMBOL} {items.price}
                                    </td>
                                    <td
                                      style={{ padding: "5px 3px" }}
                                      valign="top"
                                    >
                                     {state.SYMBOL} {items.amount}
                                    </td>
                                  </tr>
                                ))}
                                <tr
                                  style={{
                                    borderTop: "0.1em solid lightgray",
                                    borderBottom: "0.1em solid lightgray",
                                  }}
                                >
                                  <td
                                    style={{ padding: " 3px 3px 10px 3px" }}
                                  ></td>
                                  <td
                                    style={{ padding: " 3px 3px 10px 3px" }}
                                  ></td>
                                  <td style={{ padding: "3px 3px 10px 3px" }}>
                                    {selectedInvoice.items.reduce(
                                      (total, item) =>
                                        total +
                                        parseFloat(item.quantity || 0),
                                      0
                                    )}
                                  </td>
                                  <td
                                    style={{ padding: "3px 3px 10px 3px" }}
                                  ></td>
                                  <td style={{ padding: "3px 3px 10px 3px" }}>
                                  {state.SYMBOL} {selectedInvoice.items
                                      .reduce(
                                        (total, item) =>
                                          total +
                                          parseFloat(item.amount || 0),
                                        0
                                      )
                                      .toFixed(2)}
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ padding: "5px 3px" }}></td>
                                  <td style={{ padding: "5px 3px" }}></td>
                                  <td style={{ padding: "5px 3px" }}></td>
                                  <td style={{ padding: "5px 3px" }}>
                                  {state.TAXTYPE} ({selectedInvoice.gstPercetage}.0 %):
                                  </td>
                                  <td style={{ padding: "5px 3px" }}>
                                  {state.SYMBOL}&nbsp;{selectedInvoice.gstAmount}
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ padding: "5px 3px" }}></td>
                                  <td style={{ padding: "5px 3px" }}></td>
                                  <td style={{ padding: "5px 3px" }}></td>
                                  <td style={{ padding: "5px 3px" }}>
                                    Disc ({selectedInvoice.discountPercetage}
                                    .0%):
                                  </td>
                                  <td style={{ padding: " 3px" }}>
                                  {state.SYMBOL}-{selectedInvoice.discountAmount}
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ padding: " 3px" }}></td>
                                  <td style={{ padding: " 3px" }}></td>
                                  <td style={{ padding: " 3px" }}></td>
                                  <td
                                    style={{
                                      padding: " 3px",
                                      fontSize: "15px",
                                      width: "25%",
                                      color:"#4c69ff"
                                    }}
                                  >
                                    <b>Payable Amount:</b>
                                  </td>
                                  <td
                                    style={{
                                      padding: " 3px",
                                      fontSize: "15px",

                                    }}
                                  >
                                  
                                  {state.SYMBOL} {selectedInvoice.totalAmount}
                                  </td>
                                </tr>
                              </tbody>{" "}
                            </table>
                            {/* purshcing-info-section3 Start*/}

                            {/* <div className='iteam-total'>

  <div className='total-Amount'>
      <span>{symbol}<span>{invo_data[1].total}</span></span>
  </div>
</div> */}

                            {/* purshcing-info-section3  End*/}
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              width: "100%",
                              bottom: "0px",
                              left: "0px",
                              padding: "5px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "flex-end",
                                justifyContent: "space-between",
                                padding: "5px 10px",
                                fontSize: "13px",
                                marginBottom: "5px",
                              }}
                            >
                              <div className="Note" style={{ width: "60%" ,color:"black"}}>
                                <span>
                                  <b>Note:</b>
                                </span>
                                <ul
                                  style={{
                                    listStyle: "none",
                                    marginTop: "10px",
                                  }}
                                >
                                  <li>{selectedInvoice.note}</li>
                                </ul>
                              </div>
                              <div className="signeture-mens">
                                <div className="signature">
                                  <div className="sig-box"></div>
                                  
                                            <p
                                              style={{
                                                color: "dimgray",
                                                fontSize: "15px",
                                              }}
                                            >
                                              <b>
                                                Signature of{" "}
                                                {BusinessData.companyName}
                                              </b>
                                            </p>
                                         
                                </div>
                              </div>
                            </div>
                            <div
                              className="Tearms-section"
                              style={{
                                display: "flex",
                                alignItems: "flex-end",
                                justifyContent: "space-between",
                                borderTop: "1.5px solid #4c69ff",
                                padding: "2px 10px",
                                fontSize: "13px",
                              }}
                            >
                              <div className="Tearms">
                                <span style={{ color:"black"}}>
                                  <b>Terms:</b>
                                </span>
                                <ul style={{ listStyle: "none" }}>
                                  
                                            <li>{BusinessData.terms} </li>
                                         
                                </ul>
                              </div>
                              <div className="Reciver-sig">
                                <p
                                  style={{
                                    color: "dimgray",
                                    fontSize: "15px",
                                  }}
                                >
                                  <b>Receiver Signature</b>
                                </p>
                              </div>
                            </div>
                            <p style={{ position: "absolute", width: "100%", bottom: "-47px", left: "0px", padding: "5px", color: "dimgray", fontSize: "14px" }}>Powered by BillNama.com</p>
                          </div>
                        </>
                     

              {/* customer-info Code End */}

              {/* <div className="Tearms-section">
                <div className="Tearms">
                  <span>Terms</span>
                  {company
                    ? company.map((company_data, i) => {
                        if (company_data[1].companyId == viewcompanyid) {
                          const viewcompanyid =
                            localStorage.getItem("company_id");
                          {
                            return (
                              <div className="bussiness-info">
                                <div className="bussiness-info-details">
                                  <p>{company_data[1].terms}</p>
                                </div>
                              </div>
                            );
                          }
                        }
                      })
                    : "Company Data Not Found"}
                </div>
                <div className="Reciver-sig">
                  <div className="signature">
                    <div className="sig-box"></div>
                    <p>Receiver Signature</p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>):""}
        
   
    
        </>
  
  );
};

export default InvoicePreview5;
