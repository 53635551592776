import React, { useContext } from 'react'
import SessionDatas from '../components/SessionData'
import MasterLayout from '../masterLayout/MasterLayout'
import Breadcrumb from '../components/Breadcrumb'
import { ConfigContext } from '../context/GlobalContext'
const SessionPage = () => {
  const { state } = useContext(ConfigContext);

  return (
    <div>
      <MasterLayout>
        <Breadcrumb title="sessions" />
        <SessionDatas state={state} />
      </MasterLayout>
    </div>
  )
}

export default SessionPage
