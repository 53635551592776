import { Icon } from '@iconify/react/dist/iconify.js';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import DeleteInvoiceView from '../DeleteInvoiceView';
import { ConfigContext } from '../../context/GlobalContext';
import * as actionType from "../../context/actions";
import { FilterdData } from '../../helper/FilterdData';
import DataNotFoundView from '../DataNotFoundView';
import { useNavigate } from 'react-router-dom';
const RecentInvoices = ({ InvoicesData }) => {

    const navigate = useNavigate();

    const { state, dispatch } = useContext(ConfigContext);

    const [invoiceDataData, setInvoiceData] = useState(state?.INVOICES);

    useEffect(() => {
        setInvoiceData(FilterdData(state, state?.INVOICES, "date")?.slice(0, 5))
    }, [state])

    return (
        <div className="col-12">
            <div className="card h-100">
                <div className="card-header border-bottom d-flex align-items-center flex-wrap gap-2 justify-content-between">
                    <h6 className="mb-2 fw-bold text-lg mb-0">Recent Invoice</h6>
                    {invoiceDataData?.length > 0 && (<Link
                        to="/invoice-list"
                        className="text-primary-600 hover-text-primary d-flex align-items-center gap-1"
                    >
                        View All
                        <Icon
                            icon="solar:alt-arrow-right-linear"
                            className="icon"
                        />
                    </Link>)}
                </div>
                <div className="card-body p-24">
                    <div className="table-responsive scroll-sm">
                        <div className="table-responsive scroll-sm">
                            {invoiceDataData?.length > 0 ? (
                                <table
                                    className="table bordered-table mb-0"
                                    id="dataTable"
                                    data-page-length={10}
                                >
                                    <thead>
                                        <tr>
                                            <th scope="col">
                                                <div className="form-check style-check d-flex align-items-center">
                                                    {/* <input className="form-check-input" type="checkbox" /> */}
                                                    <label className="form-check-label">S.R</label>
                                                </div>
                                            </th>
                                            <th scope="col">Customer Name</th>
                                            <th scope="col" className='dt-orderable-asc dt-orderable-desc'>Amount</th>
                                            <th scope="col">Issued Date</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {invoiceDataData?.map((invoice, index) => (
                                            <tr key={index}>
                                                <td
                                                    className='cursor-pointer'
                                                    onClick={() => {
                                                        dispatch({ type: actionType.SELECTEDINVOICE, payload: invoice.invoiceId });
                                                        if (invoice?.paidFlagKey == "true" || invoice?.paidFlagKey == true) {
                                                            navigate("/invoice-preview")
                                                        }
                                                        else {
                                                            navigate("/invoice-edit")
                                                        }
                                                    }}
                                                >
                                                    <div className="form-check style-check d-flex align-items-center">
                                                        <label className="form-check-label">{index + 1}</label>
                                                    </div>
                                                </td>

                                                <td
                                                    className='cursor-pointer'
                                                    onClick={() => {
                                                        dispatch({ type: actionType.SELECTEDINVOICE, payload: invoice.invoiceId });
                                                        if (invoice?.paidFlagKey == "true" || invoice?.paidFlagKey == true) {
                                                            navigate("/invoice-preview")
                                                        }
                                                        else {
                                                            navigate("/invoice-edit")
                                                        }
                                                    }}
                                                >
                                                    <div className="d-flex align-items-center">
                                                        <h6 className="text-md mb-0 fw-medium flex-grow-1">
                                                            {invoice?.customer}
                                                        </h6>
                                                    </div>
                                                </td>



                                                <td
                                                    className='cursor-pointer'
                                                    onClick={() => {
                                                        dispatch({ type: actionType.SELECTEDINVOICE, payload: invoice.invoiceId });
                                                        if (invoice?.paidFlagKey == "true" || invoice?.paidFlagKey == true) {
                                                            navigate("/invoice-preview")
                                                        }
                                                        else {
                                                            navigate("/invoice-edit")
                                                        }
                                                    }}
                                                >
                                                    {invoice?.totalAmount}
                                                </td>

                                                <td
                                                    className='cursor-pointer'
                                                    onClick={() => {
                                                        dispatch({ type: actionType.SELECTEDINVOICE, payload: invoice.invoiceId });
                                                        if (invoice?.paidFlagKey == "true" || invoice?.paidFlagKey == true) {
                                                            navigate("/invoice-preview")
                                                        }
                                                        else {
                                                            navigate("/invoice-edit")
                                                        }
                                                    }}
                                                >
                                                    {new Date(parseInt(invoice?.date?.length > 10 ? invoice?.date : invoice?.date * 1000)).toDateString()}
                                                </td>

                                                <td
                                                    className='cursor-pointer'
                                                    onClick={() => {
                                                        dispatch({ type: actionType.SELECTEDINVOICE, payload: invoice.invoiceId });
                                                        if (invoice?.paidFlagKey == "true" || invoice?.paidFlagKey == true) {
                                                            navigate("/invoice-preview")
                                                        }
                                                        else {
                                                            navigate("/invoice-edit")
                                                        }
                                                    }}
                                                >
                                                    {" "}
                                                    <span
                                                        className={`px-24 py-4 rounded-pill fw-medium text-sm ${invoice.paidFlagKey == "true"
                                                            ? "bg-success-focus text-success-main"  // If paid
                                                            : "bg-danger-focus text-danger-main"    // If unpaid
                                                            }`}
                                                    >
                                                        {(invoice.paidFlagKey == "true" || invoice.paidFlagKey == true) ? "Paid" : "Unpaid"}
                                                    </span>
                                                </td>
                                                <td>
                                                    <Link
                                                        onClick={() => dispatch({ type: actionType.SELECTEDINVOICE, payload: invoice.invoiceId })}
                                                        to="/invoice-preview"
                                                        className="w-32-px h-32-px me-8 bg-primary-light text-primary-600 rounded-circle d-inline-flex align-items-center justify-content-center"
                                                    >
                                                        <Icon icon="iconamoon:eye-light" />
                                                    </Link>
                                                    <Link
                                                        onClick={() => dispatch({ type: actionType.SELECTEDINVOICE, payload: invoice.invoiceId })}
                                                        to="/invoice-edit"
                                                        className="w-32-px h-32-px me-8 bg-success-focus text-success-main rounded-circle d-inline-flex align-items-center justify-content-center"
                                                    >
                                                        <Icon icon="lucide:edit" />
                                                    </Link>

                                                    <DeleteInvoiceView FirebaseId={invoice.uniqueID} />
                                                </td>
                                            </tr>
                                        ))}

                                    </tbody>
                                </table>
                            )
                                : (
                                    <DataNotFoundView height={350} />
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RecentInvoices;