import React, { useContext, useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import { ConfigContext } from "../context/GlobalContext";
import * as actionType from "../context/actions";
import PaginationView from "./PaginationView";
import { FilterdData } from "../helper/FilterdData";
import DataNotFoundView from "./DataNotFoundView";
import { useNavigate } from "react-router-dom";

const CustomerTableDataLayer = () => {

    const navigate = useNavigate();

    const { state, dispatch } = useContext(ConfigContext);

    const [selectedBusiness, setSelectedBusiness] = useState("null");
    const { SearchBar } = Search;
    const [matchedCustomers, setMatchedCustomers] = useState([]);

    // ✅ Pagination State
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);

    // ✅ Filter Data According to Selected Business
    useEffect(() => {
        const uniqueCustomers = FilterdData(state, state?.CUSTOMERS, "date")

        let customerList = [];

        if (state?.SELECTEDBUSINESS != "null" && state?.SELECTEDBUSINESS != null) {
            customerList = uniqueCustomers?.filter(customer => customer.companyId == state?.SELECTEDBUSINESS)
                ?.map((customer, index) => ({
                    ...customer,
                    srNo: index + 1
                }));
        }
        else {
            customerList = uniqueCustomers?.map((customer, index) => ({
                ...customer,
                srNo: index + 1
            }));
        }

        setMatchedCustomers(customerList)
    }, [selectedBusiness, state?.ACTIVEFILTER, state?.CUSTOMERS])


    // ✅ Pagination Logic
    const totalPages = Math.ceil(matchedCustomers?.length / itemsPerPage);
    const paginatedData = useMemo(() => {
        const start = (currentPage - 1) * itemsPerPage;
        return matchedCustomers.slice(start, start + itemsPerPage);
    }, [matchedCustomers, currentPage, itemsPerPage]);

    // ✅ Sorting Icons
    const sortCaret = (order) => (
        <span className="d-inline-flex align-items-center ms-2">
            {!order ? (
                <Icon icon="mdi:swap-vertical" className="text-muted" />
            ) : order === "asc" ? (
                <Icon icon="mdi:arrow-up-thick" className="text-muted" />
            ) : (
                <Icon icon="mdi:arrow-down-thick" className="text-muted" />
            )}
        </span>
    );

    // ✅ Table Columns
    const columns = [
        {
            dataField: "srNo",
            text: "S.R",
            headerAlign: "center",
            align: "center",
            sort: true,
            style: { minWidth: "80px" },
            sortCaret: sortCaret,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    OnClickOfRow(row);
                }
            }
        },
        {
            dataField: "customer",
            text: "Customer Name",
            sort: true,
            style: { minWidth: "150px" },
            sortCaret: sortCaret,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    OnClickOfRow(row);
                }
            }
        },
        {
            dataField: "phone",
            text: "Phone",
            sort: true,
            style: { minWidth: "120px" },
            sortCaret: sortCaret,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    OnClickOfRow(row);
                }
            }
        },
        {
            dataField: "email",
            text: "Email",
            sort: true,
            style: { minWidth: "180px" },
            sortCaret: sortCaret,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    OnClickOfRow(row);
                }
            }
        },
        {
            dataField: "customerGSTNo",
            text: "GST Number",
            sort: true,
            style: { minWidth: "150px" },
            sortCaret: sortCaret,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    OnClickOfRow(row);
                }
            }
        },
        {
            dataField: "totalAmount",
            text: "Total Amount",
            sort: true,
            formatter: (cell) => (
                <span>{state?.SYMBOL} {cell}</span>
            ),
            sortCaret: sortCaret,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    OnClickOfRow(row);
                }
            }
        },
        {
            dataField: "action",
            text: "Action",
            headerAlign: "center",
            align: "center",
            formatter: (cell, row) => {
                return (
                    <>
                        <Link
                            onClick={() => dispatch({ type: actionType.SELECTEDCUSTOMER, payload: row })}
                            to="/customer-view"
                            className="w-32-px h-32-px me-8 bg-primary-light text-primary-600 rounded-circle d-inline-flex align-items-center justify-content-center"
                        >
                            <Icon icon="iconamoon:eye-light" />
                        </Link>
                        <Link
                            onClick={() => dispatch({ type: actionType.SELECTEDCUSTOMER, payload: row })}
                            to="/customer-edit"
                            className="w-32-px h-32-px me-8 bg-success-focus text-success-main rounded-circle d-inline-flex align-items-center justify-content-center"
                        >
                            <Icon icon="lucide:edit" />
                        </Link>
                    </>
                )
            },
            style: { minWidth: "150px" }
        }
    ];

    const OnClickOfRow = (row) => {
        dispatch({ type: actionType.SELECTEDCUSTOMER, payload: row })
        navigate("/customer-view")
    }

    // ✅ Handle Change Items Per Page
    const handleSelection = (val) => {
        setItemsPerPage(val);
    };

    const handleBusinessSelection = (businessId) => {
        dispatch({ type: actionType.SELECTEDBUSINESS, payload: businessId })
        setSelectedBusiness(businessId);
    };

    return (
        <div className="card">
            <div className="card-body">
                <ToolkitProvider keyField="srNo" data={paginatedData} columns={columns} search>
                    {props => (
                        <>
                            {/* ✅ Search + Length Change */}
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <SearchBar
                                    {...props.searchProps}
                                    placeholder="Search customers..."
                                    className="form-control w-auto"
                                />

                                <div className="dropdown">
                                    <button
                                        className="btn btn-primary-600 bg-primary-50 border-primary-50 text-primary-600 hover-text-primary not-active px-18 py-11 dropdown-toggle toggle-icon"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        {(state?.SELECTEDBUSINESS == null || state?.SELECTEDBUSINESS == "null") ? "All Business" : state?.BUSINESSES?.find(business => business.companyId == state?.SELECTEDBUSINESS)?.companyName}
                                    </button>
                                    <ul className="dropdown-menu">
                                        {state?.BUSINESSES?.length > 0 && (<li>
                                            <Link
                                                onClick={() => handleBusinessSelection("null")}
                                                className={`dropdown-item px-16 py-8 rounded text-secondary-light bg-hover-neutral-200 text-hover-neutral-900 ${(state?.SELECTEDBUSINESS == "null" || state?.SELECTEDBUSINESS == null) && "bg-primary-50"}`}
                                                to="#"
                                            >
                                                All Business
                                            </Link>
                                        </li>)}
                                        {state?.BUSINESSES?.length > 0 ? (
                                            state?.BUSINESSES?.map((business) => (
                                                <li key={business?.companyId}>
                                                    <Link
                                                        onClick={() => handleBusinessSelection(business?.companyId)}
                                                        className={`dropdown-item px-16 py-8 rounded text-secondary-light bg-hover-neutral-200 text-hover-neutral-900 ${business?.companyId == selectedBusiness && "bg-primary-50"}`}
                                                        to="#"
                                                    >
                                                        {business?.companyName}
                                                    </Link>
                                                </li>
                                            ))
                                        ) : (
                                            <li className="dropdown-item text-muted">No businesses available</li>
                                        )}
                                    </ul>
                                </div>

                            </div>

                            {/* ✅ Data Table */}
                            <div className="table-responsive">
                                {(<BootstrapTable
                                    {...props.baseProps}
                                    bootstrap4
                                    bordered={false}
                                    wrapperClasses={`custom-table-border invoice-table ${localStorage.getItem("theme") === "dark" ? "table-dark" : "table-light"}`}
                                    defaultSorted={[{
                                        dataField: "srNo",
                                        order: "asc"
                                    }]}
                                    noDataIndication={() => <DataNotFoundView height={400} />}
                                    rowStyle={{ cursor: "pointer" }}
                                />)}
                            </div>

                            {/* ✅ Pagination */}
                            {paginatedData?.length > 0 && (<div className="d-flex justify-content-between align-items-center mt-3">
                                <div className="d-flex align-items-center gap-2 entry_per_page">
                                    <div className="dropdown">
                                        <button
                                            className="btn btn-outline-primary-600 not-active px-18 py-6 dropdown-toggle toggle-icon"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            {itemsPerPage}
                                        </button>
                                        <ul className="dropdown-menu shadow-md">
                                            {[5, 10, 15, 20].map((value) => (
                                                <li key={value}>
                                                    <Link
                                                        className={`dropdown-item px-16 py-8 rounded text-secondary-light bg-hover-neutral-200 text-hover-neutral-900 ${itemsPerPage == value && "bg-primary-50"}`}
                                                        onClick={() => handleSelection(value)}
                                                    >
                                                        {value}
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <span className="text-secondary-light">entries per page</span>
                                </div>
                                <div className="ms-auto">
                                    <PaginationView
                                        currentPage={currentPage}
                                        totalPages={totalPages}
                                        handlePageChange={setCurrentPage}
                                    />
                                </div>
                            </div>)}
                        </>
                    )}
                </ToolkitProvider>
            </div>
        </div>
    );
};

export default CustomerTableDataLayer;
