import UploadImageOnFirebase from "../../Firebase/UploadImage";
import GetBusinessDataLocal from "./GetBusinessDataLocal";

const AddBusinessDataInLocal = async (state, dispatch, businessdata) => {
    try {

        const file = businessdata?.companyPhoto;
        let fileURL = null;

        // Upload image if file exists
        if (file) {
            const res = await UploadImageOnFirebase(businessdata?.companyId, file);
            fileURL = res?.downloadURL;
        }


        const data = [{
            companyName: businessdata?.companyName?.toString(),
            companyId: businessdata?.companyId,
            address: businessdata?.address?.toString(),
            gstNumber: businessdata?.gstNumber?.toString(),
            terms: businessdata?.terms?.toString(),
            companyPhoto: fileURL,
            creationDate: businessdata?.creationDate?.toString(),
            isArchive: businessdata?.isArchive?.toString(),
            updateDate: businessdata?.updateDate?.toString(),
        }];

        localStorage.setItem("LOCAL_BUSINESSES", JSON.stringify(data));

        await GetBusinessDataLocal(state, dispatch);

        return { success: true, message: "New company added successfully!" };
    } catch (error) {
        console.error("Error adding company:", error);
        return { success: false, message: error.message || "An error occurred while adding the company" };
    }
};

export default AddBusinessDataInLocal;
