import GetInvoiceDataLocal from "../Invoice/GetInvoiceDataLocal";

const EditCustomerInLocal = async (state, dispatch, customerData) => {

    try {

        const existingData = JSON.parse(localStorage.getItem("LOCAL_INVOICES")) || [];

        const updatedData = existingData.map(customer =>
            customerData.invoices.includes(customer.invoiceId)
                ? {
                    ...customer,
                    address: customerData?.address,
                    customer: customerData?.customer,
                    phone: customerData?.phone,
                    customerGSTNo: customerData?.customerGSTNo,
                    email: customerData?.email
                }
                : customer
        );

        localStorage.setItem("LOCAL_INVOICES", JSON.stringify(updatedData));

        GetInvoiceDataLocal(state, dispatch);
        
        return { success: true, message: "Customer data updated successfully!" };
    } catch (error) {
        return { success: false, message: error.message || "An error occurred while updating the company" };
    }
};

export default EditCustomerInLocal;
