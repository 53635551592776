import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ConfigContext } from "../context/GlobalContext";
import BusinessDataValidation from "../helper/BusinessDataValidation";
import EditBusinessData from "../data/Firebase/Business/EditBusinessDataInFirebase";
import { Icon } from "@iconify/react/dist/iconify.js";

const BusinessView = () => {
  const { state, dispatch } = useContext(ConfigContext);
  const navigate = useNavigate();

  const business_data = state?.BUSINESSES?.find(
    (business) => business.companyId === state?.SELECTEDBUSINESS
  );

  const [businessData, setBusinessData] = useState({
    companyPhoto: null,
    companyPhotoURL: business_data?.companyPhoto || "",
    companyName: business_data?.companyName || "",
    gstNumber: business_data?.gstNumber || "",
    address: business_data?.address || "",
    companyId: business_data?.companyId || "",
    creationDate: business_data?.creationDate || "",
    isArchive: business_data?.isArchive || "",
    terms: business_data?.terms || "",
    updateDate: new Date().getTime(),
    id: business_data?.id,
  });

  const onBack = () => {
    navigate(-1);
  };

  return (
    <div className="card shadow-lg p-4 w-80 mt-20 position-relative">
      <div className="card-header">

        <div className="d-flex flex-wrap align-items-center justify-content-between gap-2">
          {/* Left Side - Back/Close Button */}
          <div className="d-flex align-items-center gap-2">
            <button
              onClick={() => { navigate(-1) }}
            >
              <i class="fa fa-arrow-left" style={{ fontSize: 18 }}></i>
            </button>
            <p className='fw-semibold mb-0' style={{ fontSize: 20 }}>View Business</p>
          </div>

          {/* Right Side - Other Buttons */}
          {/* <div className="d-flex gap-2">
            <button
              type="button"
              className="btn btn-md bg-success-focus text-success-main radius-8 d-inline-flex align-items-center px-40 py-6 radius-8 gap-2"
              onClick={() => navigate("/business-edit")}
            >
              <Icon icon="lucide:edit" />
              Edit
            </button>
          </div> */}
        </div>

      </div>
      <div className="card-body">
        <div className="text-center border border-top-0 border-start-0 border-end-0">
          <img
            src={businessData.companyPhotoURL || "https://cdni.iconscout.com/illustration/premium/thumb/no-data-found-illustration-download-in-svg-png-gif-file-formats--paper-website-business-pack-illustrations-7438848.png?f=webp"}
            alt="Company Logo"
            className={`border ${localStorage.getItem("theme") == "dark" ? "br-secondary" : "bt-dark"} border-width-2-px w-160-px h-160-px rounded-circle object-fit-contain p-2`}
          />
          <h6 className="mb-0 mt-10 mb-3 fw-medium fs-5">{businessData.companyName}</h6>
        </div>
        <div className="mt-24">
          <h6 className="text-xl mb-16">Business Info</h6>
          <ul className="mt-1">
            <li className="d-flex gap-1 mb-12">
              <span className="w-20 text-md fw-medium text-primary-light">
                GST Number
              </span>
              <span className="w-70 text-secondary-light fw-regular">
                : {businessData.gstNumber}
              </span>
            </li>
            <li className="d-flex gap-1 mb-12">
              <span className="w-20 text-md fw-medium text-primary-light">
                Address
              </span>
              <span className="w-70 text-secondary-light fw-regular">
                : {businessData.address}
              </span>
            </li>
            <li className="d-flex gap-1 mb-12">
              <span className="w-20 text-md fw-medium text-primary-light">
                T&C / Account Details
              </span>
              <span className="w-70 text-secondary-light fw-regular">
                : {businessData.terms}
              </span>
            </li>
          </ul>
        </div>
      </div>

      {/* Back Button */}
      <div className="align-self-end m-3">
        <button
          type="reset"
          onClick={() => {
            navigate(-1);
          }}
          className="border btn-sm border-danger-600 bg-hover-danger-200 text-danger-600 text-md px-40 py-6 radius-8"
        >
          Back
        </button>
      </div>
    </div>
  );
};

export default BusinessView;
