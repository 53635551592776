import React, { useContext, useEffect, useState } from 'react';
import * as actionType from '../context/actions';
import CustomerTableDataLayer from './CustomerTableDataLayer';
import { ConfigContext } from '../context/GlobalContext';

const CustomerListLayer = () => {

    return (
        <div className="card basic-data-table">
            <CustomerTableDataLayer />
        </div >


    );
};

export default CustomerListLayer;