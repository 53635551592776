import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateRangePicker = ({ isOpen, handleApply, handleCancel, dates }) => {

    const [tempRange, setTempRange] = useState(dates);


    const handleDateChange = (dates) => {
        const [start, end] = dates || [];

        if (start && end) {
            const normalizedStart = new Date(start);
            normalizedStart.setHours(0, 0, 0, 0); // Set to start of the day

            const normalizedEnd = new Date(end);
            normalizedEnd.setHours(23, 59, 59, 999); // Set to end of the day

            setTempRange([normalizedStart, normalizedEnd]);
        } else {
            setTempRange(dates);
        }
    };

    return (
        <div className="datepicker-wrapper">
            {isOpen && (
                <div className="datepicker-container">
                    <DatePicker
                        selected={tempRange[0]}
                        onChange={handleDateChange}
                        startDate={tempRange[0]}
                        endDate={tempRange[1]}
                        selectsRange
                        inline
                        className="small-datepicker"  // Add a small custom class
                    />
                    <div className="datepicker-footer">
                        <button onClick={handleCancel} className="cancel-btn">
                            Cancel
                        </button>
                        <button onClick={() => handleApply(tempRange)} className="apply-btn">
                            Apply
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DateRangePicker;
