import React, { useContext, useEffect, useState } from 'react';


import DonutChart from './child/DonutChart';

import { ConfigContext } from '../context/GlobalContext';
import { FilterdData } from '../helper/FilterdData';

const DashboardChrat = () => {

    const { state } = useContext(ConfigContext);

    const [totalPaidEarning, setTotalPaidEarning] = useState(state?.TOTALPAIDEARNING);
    const [totalUnpaidEarning, setTotalUnpaidEarning] = useState(state?.TOTALUNPAIDEARNING);

    useEffect(() => {
        let paidEarnings = 0;
        let unpaidEarnings = 0;

        FilterdData(state, state?.INVOICES, "date")?.map((info) => {
            if (info.paidFlagKey == "true" || info.paidFlagKey == true) {
                paidEarnings += parseFloat(info.totalAmount);
            }
            else {
                unpaidEarnings += parseFloat(info.totalAmount);
            }
        });
        setTotalPaidEarning(paidEarnings);
        setTotalUnpaidEarning(unpaidEarnings);
    }, [state?.ACTIVEFILTER, state?.INVOICES]);

    return (
        <div className="col-xxl-12 col-md-6">
            <div className="card h-80" style={{ height: "400px", width:"100%", boxShadow: 'none'}}>
                <div className="card-header border-bottom d-flex align-items-center flex-wrap gap-2 justify-content-between">
                    <h6 className="fw-bold text-lg mb-0">Total Earning</h6>
                </div>
                
                <div className="card-body">
                    <DonutChart state={state} totalPaidEarning={totalPaidEarning} totalUnpaidEarning={totalUnpaidEarning} />

                    <div
                        id="enrollmentChart"
                        className="apexcharts-tooltip-style-1 yaxies-more"
                    >
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardChrat;