const InvoiceDataValidation = (invoiceData) => {
    let isValid = true;
    let Error = {
        dateDue: null,
        customer: null,
        phone: null,
        email: null,
        items: [],
    };

    if (!invoiceData?.dateDue) {
        Error.dateDue = "Please select valid date!";
        isValid = false;
    }

    if (!invoiceData?.customer?.trim()) {
        Error.customer = "Please enter a valid business name!";
        isValid = false;
    }


    if (!invoiceData?.phone?.trim() || isNaN(invoiceData?.phone)) {
        Error.phone = "Please enter a valid phone number!";
        isValid = false;
    }

    if (!invoiceData?.email?.trim() || !/^\S+@\S+\.\S+$/.test(invoiceData?.email)) {
        Error.email = "Please enter a valid email address!";
        isValid = false;
    }


    // Validate items
    invoiceData?.items?.forEach((item, index) => {
        let itemErrors = { name: null, quantity: null, price: null };

        if (!item?.name?.trim()) {
            itemErrors.name = "Please enter valid item name!";
            isValid = false;
        }

        if (!item?.quantity || item?.quantity <= 0 || isNaN(item?.quantity)) {
            itemErrors.quantity = "Please enter valid quantity!";
            isValid = false;
        }

        if (!item?.price || item?.price <= 0 || isNaN(item?.price)) {
            itemErrors.price = "Please enter valid Price!";
            isValid = false;
        }

        Error.items[index] = itemErrors;
    });

    return { isValid: isValid, errors: Error };
};

export default InvoiceDataValidation;
