import React, { useContext, useEffect,useState } from "react";
import MasterLayout from "../masterLayout/MasterLayout";
import Breadcrumb from "../components/Breadcrumb";
import { ConfigContext } from "../context/GlobalContext";
import BusinessListLayer from "../components/BusinessListLayer";

const BusinessListPage = () => {

  const { state } = useContext(ConfigContext);
  const [activeFillter, setActiveFillter] = useState("All");
  return (
    <>

      {/* MasterLayout */}
      <MasterLayout>

        {/* Breadcrumb */}
        <Breadcrumb title="Business - List"  />

        {/* InvoiceListLayer */}
        <BusinessListLayer BusinessData={state?.BUSINESSES}/>

      </MasterLayout>

    </>
  );
};

export default BusinessListPage;
