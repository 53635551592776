import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ConfigContext } from "../../context/GlobalContext";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import { formatDate } from "../../Utils/formate_date";
import Preview from "./PreviewSidebar";
import { useReactToPrint } from "react-to-print";

const InvoicePreview1 = ({ selectedInvoice }) => {
  const { state } = useContext(ConfigContext);
  const navigate = useNavigate();


  const inputRef = useRef();

  const BusinessData = state?.BUSINESSES.find(
    (business) => business.companyId == selectedInvoice?.companyId
  );


  return (
    <>
      {selectedInvoice ? (
        <div>
        {/* <Preview/> */}
          <div className="Invoice1">
            <div className="Invoices1" ref={inputRef}>
              <div className="Invoice-details" ref={inputRef} id="divToPrint">
                {/* {/ Company details code start  /} */}

                <div
                  className="bussiness-info"
                  style={{
                    borderBottom: "1.5px solid black",
                    marginBottom: "10px",
                    alignItems: "start",
                    paddingBottom: "2px",
                    marginTop: "16px",
                  }}
                >
                  <div className="bussiness-info-logo">
                    <img src={BusinessData?.companyPhoto} />
                  </div>
                  <div className="bussiness-info-details">
                    <h6 style={{ marginBottom: "0px" }}>
                      {BusinessData?.companyName}
                    </h6>
                    <p style={{ marginBottom: "0px", marginTop: "0px" }}>
                      {state.TAXTYPE}: {BusinessData?.gstNumber}
                    </p>
                    <p
                      className="adress"
                      style={{ marginBottom: "0px", marginTop: "0px" }}
                    >
                      {BusinessData?.address}
                    </p>
                  </div>
                </div>

                {/* {/ Company details code End  /} */}
                {/* {/ customer-info start /} */}

                <div className="customer-info">
                  <div className="Cusname-invoice-number">
                    <div className="Cus-name">
                      <span>
                        To, <span>{selectedInvoice.customer}</span>
                      </span>
                      <div className="Adress">
                        <p>
                          {state.TAXTYPE}: {selectedInvoice.customerGSTNo}
                        </p>
                      </div>

                      <div className="number">
                        <p>{selectedInvoice.phone}</p>
                      </div>
                      <div className="Email">
                        <p>{selectedInvoice.email}</p>
                      </div>
                    </div>
                    <div className="invoice-number">
                      <div>
                        <span>
                          Invoice: <span>{selectedInvoice.id}</span>
                        </span>
                      </div>
                      <div className="Date">
                        <span
                          style={{
                            display: "block",
                          }}
                        >
                          Date: <span>{formatDate(selectedInvoice.date)}</span>
                        </span>
                        <span>
                          Due Date:{" "}
                          <span>{formatDate(selectedInvoice.dueDate)}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* {/ customer-info Code End /} */}
                <div className="purshcing-info">
                  <table
                    style={{
                      textAlign: "left",
                      width: "100%",
                      fontSize: "14px",
                      borderCollapse: "collapse",
                      marginBottom: "10px",
                      color: "black",
                    }}
                  >
                    <thead>
                      <tr
                        style={{
                          backgroundColor: "#D5D5D5",
                          fontWeight: "500",
                        }}
                      >
                        <th
                          style={{
                            padding: "1px 3px",
                            width: "10px",
                            borderRight: "2px solid white",
                            fontWeight: "500",
                            backgroundColor: "white",
                          }}
                        ></th>
                        <th
                          style={{
                            padding: "1px 3px",
                            width: "20px",
                            borderRight: "2px solid white",
                            fontWeight: "500",
                          }}
                        >
                          No
                        </th>
                        <th
                          style={{
                            padding: "1px 3px",
                            borderRight: "2px solid white",
                            width: "40%",
                            fontWeight: "500",
                          }}
                        >
                          Item
                        </th>
                        <th
                          style={{
                            padding: "1px 3px",
                            borderRight: "2px solid white",
                            fontWeight: "500",
                          }}
                        >
                          {state.UNIT}
                        </th>

                        <th
                          style={{
                            padding: "1px 3px",
                            fontWeight: "500",
                            borderRight: "2px solid white",
                          }}
                        >
                          Price
                        </th>
                        <th
                          style={{
                            padding: "1px 3px",
                            fontWeight: "500",
                          }}
                        >
                          Amount
                        </th>
                        <th
                          style={{
                            padding: "1px 3px",
                            fontWeight: "500",
                            width: "10px",
                            backgroundColor: "white",
                          }}
                        ></th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedInvoice.items.map((items, i) => (
                        <tr key={i}>
                          <td
                            style={{
                              padding: "5px 3px",
                              textAlign: "center",
                            }}
                            valign="top"
                          ></td>
                          <td
                            style={{
                              padding: "5px 3px",
                              textAlign: "center",
                            }}
                            valign="top"
                          >
                            {i + 1}
                          </td>
                          <td style={{ padding: "5px 3px" }} valign="top">
                            <div>{items.name}</div>
                            <div
                              style={{
                                color: "dimgray",
                                fontSize: "12px",
                              }}
                            >
                              {items.subtitle}
                            </div>
                          </td>
                          <td style={{ padding: "5px 3px" }} valign="top">
                            {items.quantity}
                          </td>
                          <td style={{ padding: "5px 3px" }} valign="top">
                            {state.SYMBOL}
                            {items.price}
                          </td>
                          <td style={{ padding: "5px 3px" }} valign="top">
                            {state.SYMBOL} {selectedInvoice.totalAmount}
                          </td>
                          <td
                            style={{
                              padding: "5px 3px",
                              width: "10px",
                            }}
                            valign="top"
                          ></td>
                        </tr>
                      ))}
                      <tr
                        style={{
                          borderTop: "0.1em solid black",
                          borderBottom: "0.1em solid black",
                        }}
                      >
                        <td
                          style={{
                            padding: " 3px 3px 10px 3px",
                          }}
                        ></td>
                        <td
                          style={{
                            padding: " 3px 3px 10px 3px",
                          }}
                        ></td>
                        <td
                          style={{
                            padding: " 3px 3px 10px 3px",
                          }}
                        ></td>
                        <td
                          style={{
                            padding: "3px 3px 10px 3px",
                          }}
                        >
                          {selectedInvoice.items.reduce(
                            (total, item) =>
                              total + parseFloat(item.quantity || 0),
                            0
                          )}
                        </td>
                        <td
                          style={{
                            padding: "3px 3px 10px 3px",
                          }}
                        ></td>

                        <td
                          style={{
                            padding: "3px 3px 10px 3px",
                          }}
                        >
                          {state.SYMBOL}{" "}
                          {selectedInvoice.items
                            .reduce(
                              (total, item) =>
                                total + parseFloat(item.amount || 0),
                              0
                            )
                            .toFixed(2)}
                        </td>
                        <td
                          style={{
                            padding: "3px 3px 10px 3px",
                            width: "10px",
                          }}
                        >
                          {" "}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ padding: "5px 3px" }}></td>
                        <td></td>
                        <td style={{ padding: "5px 3px" }}></td>
                        <td style={{ padding: "5px 3px" }}></td>
                        <td style={{ padding: "5px 3px" }}>
                          {state.TAXTYPE}({selectedInvoice.gstPercetage}.0 %):
                        </td>
                        <td style={{ padding: "5px 3px" }}>
                          {state.SYMBOL} &nbsp;{selectedInvoice.gstAmount}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ padding: "5px 3px" }}></td>
                        <td style={{ padding: "5px 3px" }}></td>
                        <td style={{ padding: "5px 3px" }}></td>
                        <td style={{ padding: "5px 3px" }}></td>
                        <td style={{ padding: "5px 3px" }}>
                          Disc ({selectedInvoice.discountPercetage}%):
                        </td>
                        <td style={{ padding: " 3px" }}>
                          {state.SYMBOL}-{selectedInvoice.discountAmount}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ padding: "5px 3px" }}></td>
                        <td style={{ padding: "5px 3px" }}></td>
                        <td style={{ padding: "5px 3px" }}></td>
                        <td style={{ padding: "5px 3px" }}></td>
                        <td
                          style={{
                            padding: "5px 3px",
                            fontSize: "15px",
                            color: "black",
                          }}
                        >
                          <b>Payable Amount:</b>
                        </td>
                        <td
                          style={{
                            padding: "5px 3px",
                            fontSize: "15px",
                            color: "black",
                          }}
                        >
                          <b>
                            {state.SYMBOL}
                            {selectedInvoice.totalAmount}
                          </b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {/* purshcing-info-section3 Start*/}
                  {/* <div className='iteam-total'>
                                                              <div className='total-Amount'>
                                                                  <span>{symbol}<span>{invo_data[1].total}</span></span>
                                                              </div>
                                                          </div> */}
                  {/* purshcing-info-section3  End*/}
                </div>
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    bottom: "0px",
                    left: "0px",
                    padding: "5px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                      justifyContent: "space-between",
                      padding: "5px 10px",
                      fontSize: "13px",
                      marginBottom: "5px",
                    }}
                  >
                    <div
                      className="Note"
                      style={{ width: "60%", color: "black" }}
                    >
                      <span>
                        <b>Note:</b>
                      </span>
                      <ul
                        style={{
                          listStyle: "none",
                          marginTop: "10px",
                        }}
                      >
                        <li>{selectedInvoice.note}</li>
                      </ul>
                    </div>
                    <div className="signeture-mens">
                      <div className="signature">
                        <div className="sig-box"></div>

                        <p
                          style={{
                            color: "dimgray",
                            fontSize: "15px",
                          }}
                        >
                          <b>Signature of {BusinessData.companyName}</b>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="Tearms-section"
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                      justifyContent: "space-between",
                      borderTop: "1.5px solid black",
                      padding: "2px 10px",
                      fontSize: "13px",
                    }}
                  >
                    <div className="Tearms">
                      <span style={{ color: "black" }}>
                        <b>Terms:</b>
                      </span>
                      <ul style={{ listStyle: "none" }}>
                        <li>{BusinessData.terms} </li>
                      </ul>
                    </div>
                    <div className="Reciver-sig">
                      <p
                        style={{
                          color: "dimgray",
                          fontSize: "15px",
                        }}
                      >
                        <b>Receiver Signature</b>
                      </p>
                    </div>
                  </div>
                </div>
                <p
                  style={{
                    position: "absolute",
                    width: "100%",
                    bottom: "-47px",
                    left: "0px",
                    padding: "5px",
                    color: "dimgray",
                    fontSize: "14px",
                  }}
                >
                  Powered by BillNama.com
                </p>
              </div>
            </div>
          </div>
        </div>
        
      ) : (
        ""
      )}
    </>
  );
};

export default InvoicePreview1;
