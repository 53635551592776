import { ref, get } from "firebase/database";
import { database } from "../../../Utils/firebase";
import * as actionType from "../../../context/actions";


const GetSessionData = async (state, dispatch) => {
    
    if (!state?.USERID || state?.USERID == null || state?.USERID == undefined) return null;

    try {
        const userRef = ref(database, `invoiceGenerator/users/${state?.USERID}/session`);
        const snapshot = await get(userRef);
        const data = snapshot.val();

        const formattedData = Object.entries(data).map(([id, info], index) => ({
            srNo: index + 1, // Serial number starts from 1
            id, // Firebase unique ID
            ...info, // Spread existing company data
        }));

        dispatch({ type: actionType.SESSIONS, payload: formattedData});        

        return formattedData;
    } catch (error) {
        console.error("Error fetching inovice data:", error);
        return null;
    }
};

export default GetSessionData;