import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth, GoogleAuthProvider, FacebookAuthProvider, OAuthProvider } from "firebase/auth";
import { getStorage } from "firebase/storage";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD41WE04ZjmymBzAudLExcyXoaHiLYpk38",
  authDomain: "softnoesis-7e28b.firebaseapp.com",
  databaseURL: "https://softnoesis-7e28b.firebaseio.com",
  projectId: "softnoesis-7e28b",
  storageBucket: "softnoesis-7e28b.appspot.com",
  messagingSenderId: "244596601598",
  appId: "1:244596601598:web:a00ff2158df3d907f5abaf",
  measurementId: "G-HPZ5S15KKT"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
database.persistenceEnabled = false;
const auth = getAuth(app);
const storage = getStorage(app); // Initialize Firebase Storage

// Initialize Social Login Providers
const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();
const appleProvider = new OAuthProvider("apple.com");

export { app, database, auth, storage, googleProvider, facebookProvider, appleProvider };
