import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ConfigContext } from "../../context/GlobalContext";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import "../../../src/style/previewdesign1.css";
import "../../../src/style/previewdesign3.css";
import { formatDate } from "../../Utils/formate_date";

const InvoicePreview3 = ({ selectedInvoice }) => {
  const { state } = useContext(ConfigContext);
  const navigate = useNavigate();

  const inputRef = useRef(null);

  const BusinessData = state.BUSINESSES.find(
    (business) => business.companyId == selectedInvoice?.companyId
  );

  return (
    <div>
      {selectedInvoice ? (
        <div className="Invoice1">
          <div className="Invoices1" ref={inputRef}>
            <div className="Invoice-details" id="divToPrint" ref={inputRef}>
              {/* {/ Company details code start  /} */}
    
    
     

              <div
                className="bussiness-info"
                style={{
                  borderBottom: "1.5px solid #ACACEB",
                  marginBottom: "10px",
                  justifyContent: "space-between",
                  paddingBottom: "10px",
                }}
              >
                <div
                  className="bussiness-info-logo"
                  style={{ marginLeft: "0px", textAlign: "left" }}
                >
                  <img src={BusinessData.companyPhoto} />
                </div>
                <div className="bussiness-info-detailss">
                  <h6 className="header_3">{BusinessData.companyName}</h6>

                  <p className="p_3rd">{state.TAXTYPE}: {BusinessData.gstNumber}</p>
                  <p className="adres">{BusinessData.address}</p>
                </div>
              </div>

              {/* {/ Company details code End  /} */}

              {/* {/ customer-info start /} */}

              <>
                <div className="customer-info">
                  <div className="Cusname-invoice-number">
                    <div className="Cus-name">
                      <span>
                        To, <span>{selectedInvoice.customer}</span>
                      </span>

                      <div className="Adress" style={{ marginTop: "5px" }}>
                        <p>{state.TAXTYPE}: {selectedInvoice.customerGSTNo}</p>
                      </div>

                      <div className="number">
                        <p>{selectedInvoice.phone}</p>
                      </div>

                      <div className="Email">
                        <p>{selectedInvoice.email}</p>
                      </div>
                    </div>
                    <div className="invoice-number">
                      <div>
                        <span>
                          Invoice: <span>{selectedInvoice.id}</span>
                        </span>
                      </div>
                      <div className="Date">
                        <span style={{ display: "block" }}>
                          Date: <span>{formatDate(selectedInvoice.date)}</span>
                        </span>
                        <span>
                          Due Date:{" "}
                          <span>{formatDate(selectedInvoice.dueDate)}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                {/* {/ customer-info Code End /} */}

                <div className="purshcing-info">
                  <table
                    style={{
                      textAlign: "left",
                      width: "100%",
                      fontSize: "14px",
                      borderCollapse: "collapse",
                      marginBottom: "10px",
                      color: "black",
                    }}
                  >
                    <thead>
                      <tr
                        style={{
                          backgroundColor: "#ACACEB",
                          fontWeight: "500",
                        }}
                      >
                        <th
                          style={{
                            padding: " 3px",
                            width: "20px",
                            fontWeight: "500",
                            paddingBottom: "5px",
                          }}
                        >
                          No
                        </th>
                        <th
                          style={{
                            padding: " 3px",
                            width: "40%",
                            fontWeight: "500",
                            paddingBottom: "5px",
                          }}
                        >
                          Item
                        </th>
                        <th style={{ padding: " 3px",  fontWeight: "500" ,paddingBottom:"5px"}}>{state.UNIT}</th>
                        
                        <th
                          style={{
                            padding: " 3px",
                            fontWeight: "500",
                            paddingBottom: "5px",
                          }}
                        >
                          Price
                        </th>
                        <th
                          style={{
                            padding: " 3px",
                            fontWeight: "500",
                            paddingBottom: "5px",
                          }}
                        >
                          Amount
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedInvoice.items.map((items, i) => (
                        <tr
                          key={i}
                          style={{
                            backgroundColor: i % 2 === 0 ? "#D7D7D9" : "white",
                          }}
                        >
                          <td
                            style={{ padding: "5px 3px", textAlign: "center" }}
                            valign="top"
                          >
                            {i + 1}
                          </td>
                          <td style={{ padding: "5px 3px" }} valign="top">
                            <div>{items.name}</div>
                            <div style={{ color: "dimgray", fontSize: "12px" }}>
                              {items.subtitle}
                            </div>
                          </td>
                          <td style={{ padding: "5px 3px" }} valign="top">
                            {items.quantity}
                          </td>
                          <td style={{ padding: "5px 3px" }} valign="top">
                            {" "}
                            {state.SYMBOL} {items.price}
                          </td>
                          <td style={{ padding: "5px 3px" }} valign="top">
                            {" "}
                            {state.SYMBOL} {items.amount}
                          </td>
                        </tr>
                      ))}
                      <tr
                        style={{
                          borderTop: "0.1em solid #ACACEB",
                          borderBottom: "0.1em solid #ACACEB",
                        }}
                      >
                        <td style={{ padding: " 3px 3px 10px 3px" }}></td>
                        <td style={{ padding: " 3px 3px 10px 3px" }}></td>
                        <td style={{ padding: "3px 3px 10px 3px" }}>
                          {selectedInvoice.items.reduce(
                            (total, item) =>
                              total + parseFloat(item.quantity || 0),
                            0
                          )}
                        </td>
                        <td style={{ padding: "3px 3px 10px 3px" }}></td>
                        <td style={{ padding: "3px 3px 10px 3px" }}>
                          {" "}
                          {state.SYMBOL} {selectedInvoice.items
                            .reduce(
                              (total, item) =>
                                total + parseFloat(item.amount || 0),
                              0
                            )
                            .toFixed(2)}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ padding: "5px 3px" }}></td>
                        <td style={{ padding: "5px 3px" }}></td>
                        <td style={{ padding: "5px 3px" }}></td>
                        <td style={{ padding: "5px 3px" }}>
                          {" "}
                          {state.TAXTYPE} ({selectedInvoice.gstPercetage}.0 %):
                        </td>
                        <td style={{ padding: "5px 3px" }}>
                        {state.SYMBOL} &nbsp;{selectedInvoice.gstAmount}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ padding: "5px 3px" }}></td>
                        <td style={{ padding: "5px 3px" }}></td>
                        <td style={{ padding: "5px 3px" }}></td>
                        <td style={{ padding: "5px 3px" }}>
                          Disc ({selectedInvoice.discountPercetage}.0%):
                        </td>
                        <td style={{ padding: " 3px" }}>
                          {" "}
                          {state.SYMBOL} -{selectedInvoice.discountAmount}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ padding: " 3px" }}></td>
                        <td style={{ padding: " 3px" }}></td>
                        <td style={{ padding: " 3px" }}></td>
                        <td
                          style={{
                            padding: " 3px",
                            fontSize: "15px",
                            backgroundColor: "#ACACEB",
                          }}
                        >
                          <b>Payable Amount:</b>
                        </td>
                        <td
                          style={{
                            padding: " 3px",
                            fontSize: "15px",
                            backgroundColor: "#ACACEB",
                          }}
                        >
                          <b> {state.SYMBOL} {selectedInvoice.totalAmount}</b>
                        </td>
                      </tr>
                    </tbody>{" "}
                  </table>
                  {/* purshcing-info-section3 Start*/}

                  {/* <div className='iteam-total'>

                                                        <div className='total-Amount'>
                                                            <span>{symbol}<span>{invo_data[1].total}</span></span>
                                                        </div>
                                                    </div> */}

                  {/* purshcing-info-section3  End*/}
                </div>
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    bottom: "0px",
                    left: "0px",
                    padding: "5px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                      justifyContent: "space-between",
                      padding: "5px 10px",
                      fontSize: "13px",
                      marginBottom: "5px",
                      color: "black",
                    }}
                  >
                    <div className="Note" style={{ width: "60%" }}>
                      <span>
                        <b>Note:</b>
                      </span>
                      <ul style={{ listStyle: "none", marginTop: "10px" }}>
                        <li>{selectedInvoice.note}</li>
                      </ul>
                    </div>
                    <div className="signeture-mens">
                      <div className="signature">
                        <div className="sig-box"></div>

                        <p style={{ color: "dimgray", fontSize: "15px" }}>
                          <b>Signature of {BusinessData.companyName}</b>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="Tearms-section"
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                      justifyContent: "space-between",
                      borderTop: "1.5px solid #ACACEB",
                      padding: "2px 10px",
                      fontSize: "13px",
                      color: "black",
                    }}
                  >
                    <div className="Tearms">
                      <span>
                        <b>Terms:</b>
                      </span>
                      <ul style={{ listStyle: "none" }}>
                        <li>{BusinessData.terms} </li>
                      </ul>
                    </div>
                    <div className="Reciver-sig">
                      <p style={{ color: "dimgray", fontSize: "15px" }}>
                        <b>Receiver Signature</b>
                      </p>
                    </div>
                  </div>
                </div>

                <p
                  style={{
                    position: "absolute",
                    width: "100%",
                    bottom: "-47px",
                    left: "0px",
                    padding: "5px",
                    color: "dimgray",
                    fontSize: "14px",
                  }}
                >
                  Powered by BillNama.com
                </p>
              </>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default InvoicePreview3;
