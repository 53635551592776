import { ref, get } from "firebase/database";
import { database } from "../../../Utils/firebase";
import * as actionType from "../../../context/actions";


const GetPaymentData = async (state, dispatch) => {

    if (!state?.USERID || state?.USERID == null || state?.USERID == undefined) return null;

    try {
        const userRef = ref(database, `invoiceGenerator/users/${state?.USERID}/premium`);
        const snapshot = await get(userRef);
        const data = snapshot.val();
        let formattedData = [];

        if (data) {
            formattedData =
                Object.entries(data.transactions).map(([id, info], index) => ({
                    srNo: index + 1, // Serial number starts from 1
                    id, // Firebase unique ID
                    ...info, // Spread existing company data
                }));
            const premiumDate = new Date(
                data.premiumLastDate?.length > 10
                    ? parseInt(data.premiumLastDate)
                    : parseInt(data.premiumLastDate * 1000)
            );
            
            // Get today's date at 00:00:00 to compare only the date (ignoring time)
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            // Check if the premiumDate is today or in the future
            const isValidDate = premiumDate.getTime() >= today.getTime();
            
            if (isValidDate) {
                dispatch({ type: actionType.PREMIUM, payload: true });
                dispatch({ type: actionType.PREMIUMLASTDATE, payload: premiumDate.toLocaleDateString()})
            }
            else {
                dispatch({ type: actionType.PREMIUM, payload: false });
            }

            dispatch({ type: actionType.PAYMENT, payload: formattedData });
        }
        else {
            dispatch({ type: actionType.PREMIUM, payload: false });
        }
        return formattedData;
    } catch (error) {
        console.error("Error fetching inovice data:", error);
        return null;
    }
};

export default GetPaymentData;