import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useRef, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ConfigContext } from "../../context/GlobalContext";
import "../../../src/style/previewdesign6.css"
import { formatDate } from "../../Utils/formate_date"
const InvoicePreview6 = ({ selectedInvoice }) => {

  const inputRef = useRef(null);
  const { state } = useContext(ConfigContext);


  const BusinessData = state?.BUSINESSES.find(
    (business) => business?.companyId == selectedInvoice?.companyId
  );
  console.log("business data..", selectedInvoice?.items);

  // const selectedInvoice = datas?.find(invo_data => invoice_id === selectedInvoice.invoiceId);

  return (
    <>
      {selectedInvoice ? (<div className="Invoice6">


        <div className="Invoices6" ref={inputRef}>
          <div className="Invoice-details" id="divToPrint" ref={inputRef}>
            {/* Company details code start  */}

            {/* Company details code End  */}

            {/* customer-info start */}

            <div className="bussiness-info">
              <div>
                <h6>{BusinessData.companyName}</h6>
                <span>
                  {state.TAXTYPE}: {BusinessData.gstNumber}
                </span>
                <span>{BusinessData.address}</span>

                <>
                  <div className="compny_details_header">
                    <p>BILLED TO</p>
                    <span>{selectedInvoice.customer}</span>
                    <span>

                      {state.TAXTYPE}: {selectedInvoice.customerGSTNo}
                    </span>
                    <span>{selectedInvoice.address}</span>
                    <span>{selectedInvoice.phone}</span>
                    <span>{selectedInvoice.email}</span>
                  </div>
                </>

              </div>

                <img className="bussiness-info-logo" src={BusinessData.companyPhoto} />
            </div>





            <div className="billing_details_or_purshcing-info">
              <div className="billing_details">

                <>
                  <p style={{ fontSize: "20px" }}>INVOICE</p>
                  <p>Invoice Number</p>
                  <span>{selectedInvoice.id}</span>
                  <p>Date of Issue:</p>
                  <span>
                    {formatDate(selectedInvoice.date)}
                  </span>
                  <p>Due Date:</p>
                  <span>
                    {formatDate(selectedInvoice.dueDate)}
                  </span>
                </>

              </div>


              <table
                style={{
                  textAlign: "left",
                  width: "100%",
                  fontSize: "14px",
                  borderCollapse: "collapse",
                  marginBottom: "10px",
                }}
              >
                <thead>
                  <tr
                    style={{
                      backgroundColor: "#71838e",
                      fontWeight: "500",
                    }}
                  >
                    <th
                      style={{
                        padding: " 3px",
                        width: "20px",
                        fontWeight: "500",
                        paddingBottom: "5px",
                        color: "white"
                      }}
                    >
                      No
                    </th>
                    <th
                      style={{
                        padding: " 3px",
                        width: "350px",
                        fontWeight: "500",
                        paddingBottom: "5px",
                        color: "white"
                      }}
                    >
                      Item
                    </th>
                    <th
                      style={{
                        padding: " 3px",
                        fontWeight: "500",
                        paddingBottom: "5px",
                        color: "white"
                      }}
                    >
                      {state.UNIT}
                    </th>

                    <th
                      style={{
                        padding: " 3px",
                        fontWeight: "500",
                        paddingBottom: "5px",
                        color: "white"
                      }}
                    >
                      Price
                    </th>
                    <th
                      style={{
                        padding: " 3px",
                        fontWeight: "500",
                        paddingBottom: "5px",
                        color: "white"
                      }}
                    >
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {selectedInvoice.items.map((items, i) => (
                    <tr
                      key={i}
                      style={{
                        backgroundColor: "#ced3d6",
                      }}
                    >
                      <td
                        style={{
                          padding: "5px 3px",
                          textAlign: "center",
                        }}
                        valign="top"
                      >
                        {i + 1}
                      </td>
                      <td
                        style={{ padding: "5px 3px", width: "30%" }}
                        valign="top"
                      >
                        <div>{items.name}</div>
                        <div
                          style={{
                            color: "dimgray",
                            fontSize: "12px",
                          }}
                        >
                          {items.subtitle}
                        </div>
                      </td>
                      <td
                        style={{ padding: "5px 3px" }}
                        valign="top"
                      >
                        {items.quantity}
                      </td>
                      <td
                        style={{ padding: "5px 3px" }}
                        valign="top"
                      >
                        {state.SYMBOL} {items.price}
                      </td>
                      <td
                        style={{ padding: "5px 3px" }}
                        valign="top"
                      >
                        {state.SYMBOL} {items.amount}
                      </td>
                    </tr>
                  ))}
                  <tr
                    style={{
                      borderTop: "1px solid #71838e",
                      borderBottom: "1px solid #71838e",
                    }}
                  >
                    <td
                      style={{ padding: " 3px 3px 10px 3px" }}
                    ></td>
                    <td
                      style={{ padding: " 3px 3px 10px 3px" }}
                    ></td>
                    <td
                      style={{ padding: "3px 3px 10px 3px" }}
                    >
                      {selectedInvoice.items.reduce(
                        (total, item) =>
                          total +
                          parseFloat(item.quantity || 0),
                        0
                      )}
                    </td>
                    <td
                      style={{ padding: "3px 3px 10px 3px" }}
                    ></td>

                    <td
                      style={{ padding: "3px 3px 10px 3px", }}
                    >

                      {state.SYMBOL} {selectedInvoice.items
                        .reduce(
                          (total, item) =>
                            total +
                            parseFloat(item.amount || 0),
                          0
                        )
                        .toFixed(2)}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ padding: "5px 3px" }}></td>
                    <td style={{ padding: "5px 3px" }}></td>
                    <td style={{ padding: "5px 3px" }}></td>
                    <td style={{ padding: "5px 3px", color: "#84939d" }}>
                      {state.TAXTYPE} ({selectedInvoice.gstPercetage}.0 %):
                    </td>
                    <td style={{ padding: "5px 3px" }}>
                      {state.SYMBOL}&nbsp;{selectedInvoice.gstAmount}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ padding: "5px 3px" }}></td>
                    <td style={{ padding: "5px 3px" }}></td>
                    <td style={{ padding: "5px 3px" }}></td>
                    <td style={{ padding: "5px 3px", color: "#84939d" }}>
                      Disc ({selectedInvoice.discountPercetage}
                      %):
                    </td>
                    <td style={{ padding: " 3px" }}>
                      {state.SYMBOL}-{selectedInvoice.discountAmount}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ padding: " 3px" }}></td>
                    <td style={{ padding: " 3px" }}></td>
                    <td style={{ padding: " 3px" }}></td>
                    <td
                      style={{
                        padding: " 3px",
                        fontSize: "15px",
                        color: "#84939d"
                      }}
                    >
                      <b>Payable Amount:</b>
                    </td>
                    <td
                      style={{
                        padding: " 3px",
                        fontSize: "15px",
                      }}
                    >
                      <b>
                        {state.SYMBOL} {selectedInvoice.totalAmount}
                      </b>
                    </td>
                  </tr>
                </tbody>{" "}
              </table>
            </div>





            {/* customer-info Code End */}
            <div
              className="Tearms-section"
              style={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "space-between",
                // borderTop: "1.5px solid rgb(89,201,250)",
                // padding: "2px 10px",
                fontSize: "13px",
              }}
            >
              <div className="Tearms">
                <span>
                  <b>Terms:</b>
                </span>
                <ul style={{ listStyle: "none" }}>
                  <li>{BusinessData.terms} </li>

                </ul>
              </div>
              <div className="signature">
                <div className="sig-box"></div>

                <p
                  style={{
                    color: "#84939d",
                    fontSize: "15px",
                  }}
                >
                  <b>
                    Signature of{" "}
                    {BusinessData.companyName}
                  </b>
                </p>

              </div>
            </div>
            <div className="Reciver-sig">
              <p style={{ color: "#84939d", fontSize: "15px", marginTop: "50px", display: "flex", justifyContent: "flex-end" }}>
                <b>Receiver Signature</b>
              </p>
            </div>
            <p style={
              {
                marginTop: "20px",
                color: "#84939d"
              }
            } >Powered by BillNama.com</p>

          </div>
        </div>
      </div>) : ""}

    </>
  );
};

export default InvoicePreview6;
