import React from "react";
import BusinessEditForm from "../components/BusinessEditForm";
import MasterLayout from "../masterLayout/MasterLayout";
import Breadcrumb from "../components/Breadcrumb";


function BusinessEditPage() {


  return (
    <>
      <MasterLayout>
        <BusinessEditForm />
      </MasterLayout>
    </>
  );
}

export default BusinessEditPage;
