import * as actionType from "../../context/actions";

const GetSoldItemsData = async (state, dispatch, data) => {
    if (!data || !Array.isArray(data)) return null;

    try {
        const soldItemsMap = new Map();
        
        data.forEach(([id, info]) => {
            info?.items?.forEach(item => {
                const price = (parseFloat(item.price) || 0).toFixed(2);
                const amount = (parseFloat(item.amount) || 0).toFixed(2);
                const quantity = parseInt(item.quantity) || 0;
                const key = `${info.companyId}-${item.name}-${price}`; // Unique key for grouping

                if (!soldItemsMap.has(key)) {
                    // First occurrence, initialize
                    soldItemsMap.set(key, {
                        companyId: info.companyId,
                        name: item.name,
                        price: price,
                        quantity: quantity,
                        amount: amount,
                        invoiceIds: info.invoiceId ? [info.invoiceId] : [], // Store invoice IDs in an array
                        date: info.creationDate || "" // Store only the latest date
                    });
                } else {
                    const existingItem = soldItemsMap.get(key);

                    soldItemsMap.set(key, {
                        ...existingItem,
                        quantity: existingItem.quantity + quantity, // Accumulate quantity
                        amount: (parseFloat(existingItem.amount) + parseFloat(amount)).toFixed(2), // Accumulate amount
                        invoiceIds: info.invoiceId
                            ? Array.from(new Set([...existingItem.invoiceIds, info.invoiceId])) // Avoid duplicate invoice IDs
                            : existingItem.invoiceIds,
                        date: info.date && (!existingItem.date || new Date(info.date) > new Date(existingItem.date))
                            ? info.date // Update to latest date
                            : existingItem.date
                    });
                }
            });
        });

        // Convert map to an array and add serial numbers
        const soldItems = Array.from(soldItemsMap.values()).map((item, index) => ({
            srNo: index + 1, // Serial Number
            ...item
        }));
        
        dispatch({ type: actionType.SOLDITEMS, payload: soldItems });

        return soldItems;
    } catch (error) {
        console.error("Error fetching sold items data:", error);
        return null;
    }
};

export default GetSoldItemsData;
