import GetInvoiceDataLocal from "./GetInvoiceDataLocal";

const DeleteInvoiceDataInLocal = async (state, dispatch, uniqueID) => {

    try {

        console.log("uniqueID", uniqueID);
        
        // Retrieve existing invoices from localStorage
        let existingData = JSON.parse(localStorage.getItem("LOCAL_INVOICES")) || [];

        // Find the index of the invoice with the matching invoiceId
        const indexToDelete = existingData.findIndex(invoice => invoice.uniqueID === uniqueID);

        // Validate if the invoice exists
        if (indexToDelete === -1) {
            return { success: true, message: "Invoice deleted successfully!" };
        }

        // Remove the invoice at the found index
        existingData.splice(indexToDelete, 1);

        // Save updated data back to localStorage
        localStorage.setItem("LOCAL_INVOICES", JSON.stringify(existingData));

        // Refresh invoice data
        await GetInvoiceDataLocal(state, dispatch);

        return { success: true, message: "Invoice deleted successfully!" };
    } catch (error) {
        return { success: false, message: error.message || error };
    }
};

export default DeleteInvoiceDataInLocal;
