import { ConfigContext } from "../../context/GlobalContext";
import "../../style/previewsidebar.css";
import template1 from "./images/template_1.png";
import template2 from "./images/template_2.png";
import template3 from "./images/template_3.png";
import template4 from "./images/template_4.png";
import template5 from "./images/template_5.png";
import template6 from "./images/template_6.png";
import template7 from "./images/template_7.png";
import { useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import * as actionType from '../../context/actions';

export default function Preview({ setSelectedTemplate, selectedTemplate, mobileMenuControl }) {

    const navigate = useNavigate();

    const { state, dispatch } = useContext(ConfigContext);

    const templates = [
        { id: 1, src: template1, alt: "preview1" },
        { id: 2, src: template2, alt: "preview2" },
        { id: 3, src: template3, alt: "preview3" },
        { id: 4, src: template4, alt: "preview4" },
        { id: 5, src: template5, alt: "preview5" },
        { id: 6, src: template6, alt: "preview6" },
        { id: 7, src: template7, alt: "preview7" },
    ];

    // Reference array to track image elements
    const imageRefs = useRef([]);

    // Scroll to the selected template when the page loads or selection changes
    useEffect(() => {
        const selectedIndex = templates.findIndex(template => template.id === selectedTemplate);
        if (selectedIndex !== -1 && imageRefs.current[selectedIndex]) {
            imageRefs.current[selectedIndex].scrollIntoView({
                behavior: "smooth",
                block: "center"
            });
        }
    }, [selectedTemplate]);

    const handleClick = (id) => {
        if (id > 3) {
            if (state?.PREMIUM == true) {
                dispatch({ type: actionType.SELECTEDPREVIEW, payload: id })
                setSelectedTemplate(id);
                if (window.innerWidth < 1024) {
                    mobileMenuControl();
                }
            }
            else {
                navigate("/pricing")
            }
        }
        else {
            setSelectedTemplate(id);
            dispatch({ type: actionType.SELECTEDPREVIEW, payload: id })
            if (window.innerWidth < 1024) {
                mobileMenuControl();
            }
        }

    };

    return (
        <div id="preview_select" className="no-printme">
            {templates.map((template, index) => (
                <div key={template.id} className="template-container">
                    <img
                        ref={el => imageRefs.current[index] = el}
                        src={template.src}
                        alt={template.alt}
                        className={selectedTemplate === template.id ? "active-template" : ""}
                        onClick={() => handleClick(template.id)}
                    />
                    {selectedTemplate === template.id && (
                        <div className="checkmark-icon">
                            <svg viewBox="0 0 24 24">
                                <path d="M9 16.2l-4.2-4.2-1.4 1.4 5.6 5.6 12-12-1.4-1.4z" />
                            </svg>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
}
