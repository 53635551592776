import React, { useContext, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import { ConfigContext } from "../context/GlobalContext";
import { toast } from "react-toastify";
import { DeleteInvoice } from "../data";

const DeleteInvoiceView = ({FirebaseId}) => {

    const { state, dispatch } = useContext(ConfigContext)

    const [showModal, setShowModal] = useState(false);

    const handleDelete = async() => {        
       const {success, message} = await DeleteInvoice(state, dispatch, FirebaseId);
        if(success){
            toast.success("Invoice successfully deleted!")
        }
        else{
            toast.error("Failed to delete invoice, Please try again!")
        }
        setShowModal(false);
    };

    return (
        <>
            {/* Delete Button */}
            <Link
                onClick={() => setShowModal(true)}
                className="w-32-px h-32-px me-8 bg-danger-focus text-danger-main rounded-circle d-inline-flex align-items-center justify-content-center"
            >
                <Icon icon="mingcute:delete-2-line" />
            </Link>

            {/* Delete Confirmation Modal */}
            <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this invoice?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" className="bg-danger" onClick={handleDelete}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default DeleteInvoiceView