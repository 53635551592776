import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useRef, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ConfigContext } from "../../context/GlobalContext";
import "../../../src/style/previewdesign1.css";
import "../../../src/style/previewdesign4.css";
import { formatDate } from "../../Utils/formate_date";

const InvoicePreview4 = ({ selectedInvoice }) => {


  const inputRef = useRef(null);
  const { state } = useContext(ConfigContext);

  const BusinessData = state?.BUSINESSES.find(
    (business) => business.companyId == selectedInvoice?.companyId
  );
  console.log("business data..", selectedInvoice?.items);

  // const selectedInvoice = datas?.find(invo_data => invoice_id === invo_data[1].invoiceId);

  return (
    <div>
      {selectedInvoice?( <div className="Invoice1">
        <div className="Invoices1" ref={inputRef}>
          <div
            className="Invoice-details"
            id="divToPrint"
            ref={inputRef}
            style={{ padding: "0px", backgroundColor: "rgb(238, 249, 254)" }}
          >
            {/* {/ Company details code start  /} */}
           
                      <div
                        className="bussiness-info"
                        style={{
                          alignItems: "End",
                          paddingBottom: "2px",
                          justifyContent: "space-between",
                          marginRight: "20px",
                          paddingBottom: "10px",
                          backgroundColor: "#eef9fe",
                          padding: " 10px",
                          borderRadius: "10px",
                          marginTop: "14px",
                        }}
                      >
                        <div
                          className="bussiness-info-details"
                          style={{ marginLeft: "0px" }}
                        >
                          <h6>{BusinessData.companyName}</h6>

                          <p>
                          {state.TAXTYPE}: {BusinessData.gstNumber}
                          </p>
                          <p className="adress">{BusinessData.address}</p>
                        </div>
                        <div className="bussiness-info-logo">
                          <img src={BusinessData.companyPhoto} />
                        </div>
                      </div>

            {/* {/ Company details code End  /} */}

            {/* {/ customer-info start /} */}

       
                      <>
                        <div
                          className="customer-info"
                         
                          style={{
                            padding: " 10px",
                            margin: "0px",
                            backgroundColor: "rgb(238, 249, 254)",
                          }}
                        >
                          <div className="Cusname-invoice-number">
                            <div className="Cus-name">
                              <span>
                                To, <span>{selectedInvoice.customer}</span>
                              </span>

                              <div
                                className="Adress"
                                style={{ marginTop: "5px" }}
                              >
                                <p>
                                {state.TAXTYPE}: {selectedInvoice.customerGSTNo}
                                </p>
                              </div>
                             

                              <div className="number">
                                <p>{selectedInvoice.phone}</p>
                              </div>

                              <div className="Email">
                                <p>{selectedInvoice.email}</p>
                              </div>
                            </div>
                            <div className="invoice-number">
                               
                              
                                      <span>
                                        Invoice: <span>{selectedInvoice.id}</span>
                                      </span>
                                    
                              <div className="Date">
                                <span
                                  style={{
                                    display: "block"
                                  }}
                                >
                                  Date:{" "}
                                  <span>{formatDate(selectedInvoice.date)}</span>
                                </span>
                                <span>
                                  Due Date:{" "}
                                  <span>
                                    {formatDate(selectedInvoice.dueDate)}
                                  </span>
                                </span>
                              </div> 
                            </div>
                          </div>
                        </div>

                        {/* {/ customer-info Code End /} */}

                        <div className="purshcing-info">
                          <table
                            style={{
                              textAlign: "left",
                              width: "100%",
                              fontSize: "14px",
                              borderCollapse: "collapse",
                              marginBottom: "10px",
                              backgroundColor: "#D1EFFC",
                              color:"black"
                            }}
                          >
                            <thead>
                              <tr
                                style={{
                                  backgroundColor: "#59C9FA",
                                  fontWeight: "500",
                                }}
                              >
                                <th
                                  style={{
                                    padding: " 3px",
                                    width: "20px",
                                    fontWeight: "500",
                                    paddingBottom: "5px",
                                  }}
                                >
                                  No
                                </th>
                                <th
                                  style={{
                                    padding: " 3px",
                                    width: "40%",
                                    fontWeight: "500",
                                    paddingBottom: "5px",
                                  }}
                                >
                                  Item
                                </th>
                                <th
                                  style={{
                                    padding: " 3px",
                                    fontWeight: "500",
                                    paddingBottom: "5px",
                                  }}
                                >
                                  {state.UNIT}
                                </th>
                                <th
                                  style={{
                                    padding: " 3px",
                                    fontWeight: "500",
                                    paddingBottom: "5px",
                                  }}
                                >
                                  Price
                                </th>
                                <th
                                  style={{
                                    padding: " 3px",
                                    fontWeight: "500",
                                    paddingBottom: "5px",
                                  }}
                                >
                                  Amount
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {selectedInvoice.items.map((items, i) => (
                                <tr
                                  key={i}
                                  style={{
                                    backgroundColor:
                                      i % 2 === 0 ? "#9CDEFB" : "transparent",
                                  }}
                                >
                                  <td
                                    style={{
                                      padding: "5px 3px",
                                      textAlign: "center",
                                    }}
                                    valign="top"
                                  >
                                    {i + 1}
                                  </td>
                                  <td
                                    style={{ padding: "5px 3px" }}
                                    valign="top"
                                  >
                                    <div>{items.name}</div>
                                    <div
                                      style={{
                                        color: "dimgray",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {items.subtitle}
                                    </div>
                                  </td>
                                  <td
                                    style={{ padding: "5px 3px" }}
                                    valign="top"
                                  >
                                    {items.quantity}
                                  </td>
                                  <td
                                    style={{ padding: "5px 3px" }}
                                    valign="top"
                                  >
                                   {state.SYMBOL} {items.price}
                                  </td>
                                  <td
                                    style={{ padding: "5px 3px" }}
                                    valign="top"
                                  >
                             {state.SYMBOL} {items.amount}
                                  </td>
                                </tr>
                              ))}
                              <tr
                                style={{
                                  borderTop: "1px solid rgb(89,201,250)",
                                  borderBottom: "1px solid rgb(89,201,250)",
                                }}
                              >
                                <td
                                  style={{ padding: " 3px 3px 10px 3px" }}
                                ></td>
                                <td
                                  style={{ padding: " 3px 3px 10px 3px" }}
                                ></td>
                                <td style={{ padding: "3px 3px 10px 3px" }}>
                                  {selectedInvoice.items.reduce(
                                    (total, item) =>
                                      total + parseFloat(item.quantity || 0),
                                    0
                                  )}
                                </td>
                                <td
                                  style={{ padding: "3px 3px 10px 3px" }}
                                ></td>

                                <td style={{ padding: "3px 3px 10px 3px" }}>
                                 
                                {state.SYMBOL} {selectedInvoice.items
                                    .reduce(
                                      (total, item) =>
                                        total + parseFloat(item.amount || 0),
                                      0
                                    )
                                    .toFixed(2)}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ padding: "5px 3px" }}></td>
                                <td style={{ padding: "5px 3px" }}></td>
                                <td style={{ padding: "5px 3px" }}></td>
                                <td style={{ padding: "5px 3px" }}>
                                {state.TAXTYPE}({selectedInvoice.gstPercetage}.0 %):
                                </td>
                                <td style={{ padding: "5px 3px" }}>
                                {state.SYMBOL}&nbsp;{selectedInvoice.gstAmount}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ padding: "5px 3px" }}></td>
                                <td style={{ padding: "5px 3px" }}></td>
                                <td style={{ padding: "5px 3px" }}></td>
                                <td style={{ padding: "5px 3px" }}>
                                  Disc ({selectedInvoice.discountPercetage}.0%):
                                </td>
                                <td style={{ padding: " 3px" }}>
                                {state.SYMBOL}-{selectedInvoice.discountAmount}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ padding: " 3px" }}></td>
                                <td style={{ padding: " 3px" }}></td>
                                <td style={{ padding: " 3px" }}></td>
                                <td
                                  style={{ padding: " 3px", fontSize: "15px" }}
                                >
                                  <b>Payable Amount:</b>
                                </td>
                                <td
                                  style={{ padding: " 3px", fontSize: "15px" }}
                                >
                                  <b>
                                  {state.SYMBOL} {selectedInvoice.totalAmount}
                                  </b>
                                </td>
                              </tr>
                            </tbody>{" "}
                          </table>
                          {/* purshcing-info-section3 Start*/}

                          {/* <div className='iteam-total'>

                                                            <div className='total-Amount'>
                                                                <span>{symbol}<span>{invo_data[1].total}</span></span>
                                                            </div>
                                                        </div> */}

                          {/* purshcing-info-section3  End*/}
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            bottom: "0px",
                            left: "0px",
                            padding: "5px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              justifyContent: "space-between",
                              padding: "5px 10px",
                              fontSize: "13px",
                              marginBottom: "5px",
                            }}
                          >
                            <div className="Note" style={{ width: "60%",color:"black"}}>
                              <span>
                                <b>Note:</b>
                              </span>
                              <ul
                                style={{ listStyle: "none", marginTop: "10px" }}
                              >
                                <li>{selectedInvoice.note}</li>
                              </ul>
                            </div>
                            <div className="signeture-mens">
                              <div className="signature">
                                <div className="sig-box"></div>
                               
                                          <p
                                            style={{
                                              color: "dimgray",
                                              fontSize: "15px",
                                            }}
                                          >
                                            <b>
                                              Signature of{" "}
                                              {BusinessData.companyName}
                                            </b>
                                          </p>
                                      
                              </div>
                            </div>
                          </div>
                          <div
                            className="Tearms-section"
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              justifyContent: "space-between",
                              borderTop: "1.5px solid rgb(89,201,250)",
                              padding: "2px 10px",
                              fontSize: "13px",
                              color:"black"
                            }}
                          >
                            <div className="Tearms">
                              <span>
                                <b>Terms:</b>
                              </span>
                              <ul style={{ listStyle: "none" }}>
                                
                                          <li>{BusinessData.terms} </li>
                                       
                              </ul>
                            </div>
                            <div className="Reciver-sig">
                              <p style={{ color: "dimgray", fontSize: "15px" }}>
                                <b>Receiver Signature</b>
                              </p>
                            </div>
                          </div>
                        </div>

                        <p
                          style={{
                            position: "absolute",
                            width: "100%",
                            bottom: "-47px",
                            left: "0px",
                            padding: "5px",
                            color: "dimgray",
                            fontSize: "14px",
                          }}
                        >
                          Powered by BillNama.com
                        </p>
                      </>
                    
          </div>
        </div>
      </div>):""}
     
    </div>
  );
};

export default InvoicePreview4;
