import React, { useContext, useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import { ConfigContext } from "../context/GlobalContext";
import * as actionType from "../context/actions";
import DeleteInvoiceView from "./DeleteInvoiceView";
import PaginationView from "./PaginationView";
import { FilterdData } from "../helper/FilterdData";
import DataNotFoundView from "./DataNotFoundView";
import { useNavigate } from "react-router-dom";

const InvoiceTableDataLayer = ({ InvoicesData, selectedBusiness }) => {

    const navigate = useNavigate();
    
    const { state, dispatch } = useContext(ConfigContext);
    const [filterdInvoiceData, setFilterdInvoiceData] = useState(InvoicesData);
    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);

    useEffect(() => {
        setFilterdInvoiceData(FilterdData(state, InvoicesData, "creationDate"));
    }, [state])

    const matchedInvoices = useMemo(() => {
        
        const filteredData = (selectedBusiness === null || selectedBusiness === "null")
            ? filterdInvoiceData
            : filterdInvoiceData.filter(invoice => invoice?.companyId == selectedBusiness);

        return filteredData.map((invoice, index) => ({ ...invoice, srNo: index + 1 }));
    }, [filterdInvoiceData, selectedBusiness]);

    // Pagination logic
    const totalPages = Math.ceil(matchedInvoices?.length / itemsPerPage);
    const paginatedData = useMemo(() => {
        const start = (currentPage - 1) * itemsPerPage;
        return matchedInvoices.slice(start, start + itemsPerPage);
    }, [matchedInvoices, currentPage, itemsPerPage]);
    
    const sortCaret = (order, column) => {
        return (
            <span className="d-inline-flex align-items-center ms-2" >
                {!order ? (
                    <Icon icon="mdi:swap-vertical" className="text-muted" /> // Two arrows pointing up & down
                ) : order === "asc" ? (
                    <Icon icon="mdi:arrow-up-thick" className="text-muted" />
                ) : (
                    <Icon icon="mdi:arrow-down-thick" className="text-muted" />
                )}
            </span>
        );
    };


    const { SearchBar } = Search;

    const columns = [
        {
            dataField: "srNo",
            text: "S.R",
            headerAlign: "center",
            align: "center",
            sort: true,
            formatter: (cell) => (
                <span style={{ marginLeft: -8 }}>{cell}</span>
            ),
            sortCaret: sortCaret,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                   OnClickOfRow(row?.invoiceId, row?.paidFlagKey);
                }
            }
        },
        {
            dataField: "id",
            text: "Invoice ID",
            headerAlign: "center",
            align: "center",
            sort: true,
            formatter: (cell) => (
                <span style={{ marginLeft: -8 }}>{cell}</span>
            ),
            sortCaret: sortCaret,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                   OnClickOfRow(row?.invoiceId, row?.paidFlagKey);
                }
            }
        },
        {
            dataField: "customer",
            text: "Customer Name",
            sort: true,
            style: { minWidth: "150px" },
            sortCaret: sortCaret,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                   OnClickOfRow(row?.invoiceId, row?.paidFlagKey);
                }
            }
        },
        {
            dataField: "totalAmount",
            text: "Amount",
            sort: true,
            sortCaret: sortCaret,
            formatter: (cell) => (
                <span>{state?.SYMBOL} {cell}</span>
            ),
            sortFunc: (a, b, order) => {
                if (order === 'asc') {
                    return Number(a) - Number(b);
                }
                return Number(b) - Number(a);
            },
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                   OnClickOfRow(row?.invoiceId, row?.paidFlagKey);
                }
            }
        },
        {
            dataField: "creationDate",
            text: "Issued Date",
            formatter: (cell) => new Date(parseInt(cell?.length > 10 ? parseInt(cell) : parseInt(cell) * 1000)).toDateString(),
            sort: true,
            style: { minWidth: "100px" },
            sortCaret: sortCaret,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                   OnClickOfRow(row?.invoiceId, row?.paidFlagKey);
                }
            }
        },
        {
            dataField: "paidFlagKey",
            text: "Status",
            headerAlign: "center",
            align: "center",
            formatter: (cell) => (
                <span
                    className={`d-inline-block text-center px-24 py-4 rounded-pill fw-medium text-sm ${(cell === "true" || cell == true) ? "bg-success-focus text-success-main" : "bg-danger-focus text-danger-main"
                        }`}
                >
                    {(cell === "true" || cell === true) ? "Paid" : "Unpaid"}
                </span>
            ),
            sort: true,
            sortCaret: sortCaret,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    OnClickOfRow(row?.invoiceId, row?.paidFlagKey);
                }
            }
        },
        {
            dataField: "action",
            text: "Action",
            headerAlign: "center",
            align: "center",
            formatter: (cell, row) => (
                <>
                    <Link
                        onClick={() => dispatch({ type: actionType.SELECTEDINVOICE, payload: row?.invoiceId })}
                        to="/invoice-preview"
                        className="w-32-px h-32-px me-8 bg-primary-light text-primary-600 rounded-circle d-inline-flex align-items-center justify-content-center"
                    >
                        <Icon icon="iconamoon:eye-light" />
                    </Link>
                    <Link
                        onClick={() => dispatch({ type: actionType.SELECTEDINVOICE, payload: row?.invoiceId })}
                        to="/invoice-edit"
                        className="w-32-px h-32-px me-8 bg-success-focus text-success-main rounded-circle d-inline-flex align-items-center justify-content-center"
                    >
                        <Icon icon="lucide:edit" />
                    </Link>
                    <DeleteInvoiceView FirebaseId={row?.uniqueID} />
                </>
            ),
        }
    ];

    const OnClickOfRow = (invoiceId, isPaid) => {
        dispatch({ type: actionType.SELECTEDINVOICE, payload: invoiceId })
        if (isPaid == "true" || isPaid == true) {
            navigate("/invoice-preview")
        }
        else {
            navigate("/invoice-edit")
        }
    }

    const handleSelection = (val) => {
        setItemsPerPage(val)
    };

    return (
        <div className="card">
            <div className="card-body">
                <ToolkitProvider keyField="invoiceId" data={paginatedData} columns={columns} search>
                    {props => (
                        <>
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                {/* Search Bar (Left) */}

                                <SearchBar
                                    {...props.searchProps}
                                    placeholder="Search invoices..."
                                    className="form-control w-auto"
                                />
                            </div>
                            <div className={`table-responsive`}>
                                <BootstrapTable
                                    {...props.baseProps}
                                    bootstrap4
                                    bordered={false}
                                    wrapperClasses={`custom-table-border invoice-table ${localStorage.getItem("theme") == "dark" ? "table-dark" : "table-light"}`}
                                    defaultSorted={{
                                        dataField: "date",
                                        order: "desc",
                                    }}
                                    noDataIndication={() => <DataNotFoundView height={400} />}
                                    rowStyle={{ cursor: "pointer" }}
                                />
                            </div>

                            {/* Pagination UI */}
                            {paginatedData?.length > 0 && (<div className="d-flex justify-content-between align-items-center mt-3">

                                {/* Items Per Page Dropdown (Right) */}
                                <div className="d-flex align-items-center gap-2 entry_per_page">
                                    <div className="dropdown">
                                        <button
                                            className="btn btn-outline-primary-600 not-active px-18 py-6 dropdown-toggle toggle-icon"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            {itemsPerPage}
                                        </button>
                                        <ul className="dropdown-menu shadow-md">
                                            {[5, 10, 15, 20].map((value) => (
                                                <li key={value}>
                                                    <Link
                                                        className={`dropdown-item px-16 py-8 rounded text-secondary-light bg-hover-neutral-200 text-hover-neutral-900 ${itemsPerPage == value && "bg-primary-50"}`}
                                                        onClick={() => handleSelection(value)}
                                                    >
                                                        {value}
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <span className="text-secondary-light">entries per page</span>
                                </div>

                                <div className="ms-auto">
                                    <PaginationView
                                        currentPage={currentPage}
                                        totalPages={totalPages}
                                        handlePageChange={setCurrentPage}
                                    />
                                </div>
                            </div>)}
                        </>
                    )}
                </ToolkitProvider>
            </div>
        </div>
    );
};

export default InvoiceTableDataLayer;
