const CustomerDataValidation = (customerData) => {
    let isValid = true;
    let Error = {
        customer: "",
        email: "",
        phone: ""
    }

    if (customerData?.customer?.trim() == "") {
        Error.customer = "Please enter valid customer name!";
        isValid = false
    }

    if (!customerData?.email?.trim() || !/^\S+@\S+\.\S+$/.test(customerData?.email)) {
        Error.email = "Please enter valid email!";
        isValid = false
    }

    if (!customerData?.phone?.trim() || isNaN(customerData?.phone)) {
        Error.phone = "Please enter a valid phone number!";
        isValid = false;
    }

    return { isValid: isValid, errors: Error }
}

export default CustomerDataValidation;