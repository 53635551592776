import GetBusinessDataLocal from "./GetBusinessDataLocal";
import * as actionType from '../../../context/actions';
import UploadImageOnFirebase from "../../Firebase/UploadImage";

const EditBusinessDataLocal = async (state, dispatch, businessdata) => {
    try {

        const file = businessdata?.companyPhoto;
        let fileURL = businessdata?.companyPhotoURL || null; // Preserve existing photo if no new upload

        // Upload new image if a file is provided
        if (file && typeof file !== "string") {
            const res = await UploadImageOnFirebase("104071135247372128258", file);
            fileURL = res?.downloadURL;
        }

        const data = [{
            companyName: businessdata?.companyName?.toString(),
            companyId: businessdata?.companyId,
            address: businessdata?.address?.toString(),
            gstNumber: businessdata?.gstNumber?.toString(),
            terms: businessdata?.terms?.toString(),
            companyPhoto: fileURL,
            creationDate: businessdata?.creationDate?.toString(),
            isArchive: businessdata?.isArchive?.toString(),
            updateDate: businessdata?.updateDate?.toString(),
        }]

        localStorage.setItem("LOCAL_BUSINESSES", JSON.stringify(data));

        GetBusinessDataLocal(state, dispatch)

        return { success: true, message: "Company data updated successfully!" };
    } catch (error) {
        return { success: false, message: error.message || "An error occurred while updating the company" };
    }
};

export default EditBusinessDataLocal;
