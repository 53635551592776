import React from "react";
import MasterLayout from "../masterLayout/MasterLayout";
import Breadcrumb from "../components/Breadcrumb";
import CustomerEditForm from "../components/CustomerEditForm";


function CustomerEditPage() {


  return (
    <>
      <MasterLayout>
        <CustomerEditForm />
      </MasterLayout>
    </>
  );
}

export default CustomerEditPage;
