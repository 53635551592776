import { Icon } from '@iconify/react/dist/iconify.js'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { FilterdData } from '../../helper/FilterdData';
import GetCustomersData from '../../data/Comman/GetCustomersData';
import GetSoldItemsData from '../../data/Comman/GetSoldItesmData';

const BusinessOverview = ({ state, disptch }) => {

    const [totalEarning, setTotalEarning] = useState(state?.TOTALEARNING);
    const [totalPaidEarning, setTotalPaidEarning] = useState(state?.TOTALPAIDEARNING);
    const [totalUnpaidEarning, setTotalUnpaidEarning] = useState(state?.TOTALUNPAIDEARNING);
    const [businessData, setBusinessData] = useState(state?.BUSINESSES);
    const [invoiceData, setInvoiceData] = useState(state?.INVOICES);
    const [customerData, setCustomerData] = useState(state?.CUSTOMERS);
    const [soldItemsData, setSoldItemsData] = useState(state?.SOLDITEMS);

    useEffect(() => {
        if (!state) return; // Ensure state is defined

        setBusinessData(FilterdData(state, state?.BUSINESSES, "creationDate"));
        setInvoiceData(FilterdData(state, state?.INVOICES, "date"));
        const uniqueCustomers = FilterdData(state, state?.CUSTOMERS, "date");
        setCustomerData(uniqueCustomers);

        const soldItems = [];
        FilterdData(state, state?.INVOICES, "date")?.forEach((info) => {
            info?.items?.forEach((item) => {
                const price = (parseFloat(item.price) || 0).toFixed(2);
                const amount = (parseFloat(item.amount) || 0).toFixed(2);

                const existingItem = soldItems.find(soldItem =>
                    soldItem.name === item.name &&
                    soldItem.companyId === info.companyId &&
                    soldItem.price === price
                );

                if (existingItem) {
                    existingItem.quantity += parseInt(item.quantity);
                    existingItem.amount = (parseFloat(existingItem.amount) + parseFloat(amount)).toFixed(2);
                } else {
                    soldItems.push({
                        srNo: soldItems.length + 1,
                        companyId: info.companyId,
                        invoiceId: info.invoiceId,
                        name: item.name,
                        price: price,
                        quantity: parseInt(item.quantity),
                        amount: amount
                    });
                }
            });
        });

        setSoldItemsData(soldItems);

        let data = [];

        FilterdData(state, state?.INVOICES, "date")?.forEach((item) => {
            data.push([null, item]);
        });

        GetSoldItemsData(state, disptch, data);

        let totalEarnings = 0;
        let paidEarnings = 0;
        let unpaidEarnings = 0;


        data.forEach(([id, info]) => {

            if (info.totalAmount) {
                totalEarnings += parseFloat(info.totalAmount);
            }

            if (info.paidFlagKey === "true" || info.paidFlagKey === true) {
                paidEarnings += parseFloat(info.totalAmount);
            } else {
                unpaidEarnings += parseFloat(info.totalAmount);
            }
        });

        setTotalEarning(totalEarnings.toFixed(2));
        setTotalPaidEarning(paidEarnings.toFixed(2));
        setTotalUnpaidEarning(unpaidEarnings.toFixed(2));

    }, [state?.ACTIVEFILTER, state?.BUSINESSES, state?.INVOICES]);



    return (
        <div className="col-12">
            <h6 className="mb-16">Business Overview & Insights</h6>
            <div className="row gy-4">
                {/* Total Earning */}
                <div className="col-lg-4 col-sm-6">
                    <div className="card px-3 py-3 shadow-none radius-12 border h-100 bg-gradient-start-4 d-flex align-items-center">
                        <div className="card-body p-0 w-100 d-flex align-items-center">
                            <div className="d-flex align-items-center gap-3 w-100 flex-wrap">
                                <span className="w-40-px h-40-px bg-success-600 text-white d-flex justify-content-center align-items-center rounded-circle h6">
                                    <Icon icon="mdi:wallet" className="icon" />
                                </span>
                                <div className="flex-grow-1 min-w-0">
                                    <h6 className="fw-semibold mb-0 text-truncate"
                                        style={{
                                            fontSize: "clamp(14px, 2.5vw, 18px)",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            wordBreak: "break-word"
                                        }}>
                                        {state?.SYMBOL} {totalEarning || state?.TOTALEARNING}
                                    </h6>
                                    <span className="fw-medium text-secondary-light text-md">Total Earning</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* Total Paid Earning */}
                <div className="col-lg-4 col-sm-6">
                    <div className="card px-24 py-24 shadow-none radius-12 border h-100 bg-gradient-start-3 d-flex align-items-center">
                        <div className="card-body p-0 w-100 d-flex align-items-center">
                            <div className="d-flex align-items-center gap-16 w-100">
                                <span className="w-40-px h-40-px bg-primary-600 text-white d-flex justify-content-center align-items-center rounded-circle h6">
                                    <Icon icon="mdi:cash-check" className="icon" />
                                </span>
                                <div className="flex-grow-1">
                                    <h6 className="fw-semibold mb-0 text-truncate"
                                        style={{
                                            fontSize: "clamp(14px, 2.5vw, 18px)",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            wordBreak: "break-word"
                                        }}>{state?.SYMBOL} {totalPaidEarning}</h6>
                                    <span className="fw-medium text-secondary-light text-md">Total Paid Earning</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Total Unpaid Earning */}
                <div className="col-lg-4 col-sm-6">
                    <div className="card px-24 py-24 shadow-none radius-12 border h-100 bg-gradient-start-5 d-flex align-items-center">
                        <div className="card-body p-0 w-100 d-flex align-items-center">
                            <div className="d-flex align-items-center gap-16 w-100">
                                <span className="w-40-px h-40-px bg-danger-600 text-white d-flex justify-content-center align-items-center rounded-circle h6">
                                    <Icon icon="mdi:alert-circle-outline" className="icon" />
                                </span>
                                <div className="flex-grow-1">
                                    <h6 className="fw-semibold mb-0 text-truncate"
                                        style={{
                                            fontSize: "clamp(14px, 2.5vw, 18px)",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            wordBreak: "break-word"
                                        }}>{state?.SYMBOL} {totalUnpaidEarning}</h6>
                                    <span className="fw-medium text-secondary-light text-md">Total Unpaid Earning</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Total Businesses */}
                <div className="col-lg-4 col-sm-6">
                    <Link to="/business-list" className="card px-24 py-24 shadow-none radius-12 border h-100 bg-gradient-start-3 d-flex align-items-center">


                        <div className="card-body p-0 w-100 d-flex align-items-center">
                            <div className="d-flex align-items-center gap-16 w-100">
                                <span className="w-40-px h-40-px bg-info-600 text-white d-flex justify-content-center align-items-center rounded-circle h6">
                                    <Icon icon="tabler:building-store" className="icon" />
                                </span>
                                <div className="flex-grow-1">
                                    <h6 className="fw-semibold mb-0 text-truncate"
                                        style={{
                                            fontSize: "clamp(14px, 2.5vw, 18px)",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            wordBreak: "break-word"
                                        }}>{businessData?.length}</h6>
                                    <span className="fw-medium text-secondary-light text-md">Total Business</span>
                                </div>
                            </div>
                        </div>

                    </Link>

                </div>

                {/* Total Invoices */}
                <div className="col-lg-4 col-sm-6">
                    <Link to="/invoice-list"
                        className="card px-24 py-24 shadow-none radius-12 border h-100 bg-gradient-start-5 d-flex align-items-center">
                        <div className="card-body p-0 w-100 d-flex align-items-center">
                            <div className="d-flex align-items-center gap-16 w-100">
                                <span className="w-40-px h-40-px bg-warning-600 text-white d-flex justify-content-center align-items-center rounded-circle h6">
                                    <Icon icon="hugeicons:invoice-03" className="icon" />
                                </span>
                                <div className="flex-grow-1">
                                    <h6 className="fw-semibold mb-0 text-truncate"
                                        style={{
                                            fontSize: "clamp(14px, 2.5vw, 18px)",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            wordBreak: "break-word"
                                        }}>{invoiceData?.length}</h6>
                                    <span className="fw-medium text-secondary-light text-md">Total Invoices</span>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>

                {/* Total Customers */}
                <div className="col-lg-4 col-sm-6">
                    <Link to="/customer-list" className="card px-24 py-24 shadow-none radius-12 border h-100 bg-gradient-start-2 d-flex align-items-center">
                        <div className="card-body p-0 w-100 d-flex align-items-center">
                            <div className="d-flex align-items-center gap-16 w-100">
                                <span className="w-40-px h-40-px bg-purple text-white d-flex justify-content-center align-items-center rounded-circle h6">
                                    <Icon icon="heroicons-outline:users" className="icon" />
                                </span>
                                <div className="flex-grow-1">
                                    <h6 className="fw-semibold mb-0 text-truncate"
                                        style={{
                                            fontSize: "clamp(14px, 2.5vw, 18px)",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            wordBreak: "break-word"
                                        }}>{customerData?.length}</h6>
                                    <span className="fw-medium text-secondary-light text-md">Total Customers</span>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>

                {/* Total Sold Items */}
                <div className="col-lg-4 col-sm-6">
                    <Link to="/sold-items-list" className="card px-24 py-24 shadow-none radius-12 border h-100 bg-gradient-start-1 d-flex align-items-center">
                        <div className="card-body p-0 w-100 d-flex align-items-center">
                            <div className="d-flex align-items-center gap-16 w-100">
                                <span className="w-40-px h-40-px bg-info text-white d-flex justify-content-center align-items-center rounded-circle h6">
                                    <Icon icon="mdi:cart-check" className="icon" />
                                </span>
                                <div className="flex-grow-1">
                                    <h6 className="fw-semibold mb-0 text-truncate"
                                        style={{
                                            fontSize: "clamp(14px, 2.5vw, 18px)",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            wordBreak: "break-word"
                                        }}>{soldItemsData?.length}</h6>
                                    <span className="fw-medium text-secondary-light text-md">Total Sold Items</span>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>

        </div>
    )
}

export default BusinessOverview