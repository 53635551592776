import { push, ref } from "firebase/database";
import { database } from "../../../Utils/firebase";
import GetInvoiceDataFirebase from "./GetInvoiceDataFirebase";

const AddInvoiceDataInFirebase = async (state, dispatch, invoiceData, userId) => {

    if ((!state?.USERID || state?.USERID == null) && !userId) return null;

    const USERID = state?.USERID || userId

    try {

        const formatItems = (rawItems) => {
            return rawItems.map(i => ({
                amount: i?.amount?.toString() || "0.00",
                billId: invoiceData?.id?.toString() || "",
                companyId: invoiceData?.companyId?.toString() || "",
                creationDate: invoiceData.creationDate.toString() || new Date().getTime().toString(),
                isSalesReturnKey: "",
                itemId: "",
                name: i?.name || "",
                price: i?.price?.toString() || "",
                quantity: i?.quantity?.toString() || "",
                subtitle: i.subtitle || "",
                updateDate: invoiceData.creationDate.toString() || new Date().getTime().toString()
            }));
        };

        // Reference to user's invoice data
        const userRef = ref(database, `invoiceGenerator/users/${USERID}/billInvoice`);

        // Push new invoice data
        await push(userRef, {
            address: invoiceData?.address?.toString() || "",
            companyId: invoiceData?.companyId?.toString() || "",
            creationDate: invoiceData.creationDate.toString() || new Date().getTime().toString(),
            customInvoiceId: "",
            customer: invoiceData?.customer?.toString() || "",
            customerGSTNo: invoiceData?.customerGSTNo?.toString() || "",
            date: invoiceData.creationDate.toString() || new Date().getTime().toString(),
            discountAmount: invoiceData?.discountAmount?.toString() || "0.00",
            discountPercetage: invoiceData?.discountPercentage?.toString() || "0",
            dueDate: invoiceData?.dateDue.toString() || new Date().getTime().toString(),
            email: invoiceData?.email.toString() || "",
            gstAmount: invoiceData?.gstAmount?.toString() || "0.00",
            gstPercetage: invoiceData?.gstPercentage?.toString() || "0",
            id: invoiceData?.id?.toString() || "",
            invoiceId: invoiceData?.invoiceId?.toString() || "",
            isigst: invoiceData?.isigst ? "true" : "flase",
            items: formatItems(invoiceData?.items),
            note: invoiceData?.note?.toString() || "",
            paidFlagKey: invoiceData?.isPaid ? "true" : "flase",
            phone: invoiceData?.phone?.toString() || "",
            totalAmount: invoiceData?.totalAmount?.toString() || "",
            updateDate: invoiceData.creationDate.toString() || new Date().getTime().toString()
        });

        // Fetch updated invoice data
        await GetInvoiceDataFirebase(state, dispatch);

        return { success: true, message: "New invoice added successfully!" };
    } catch (error) {
        return { success: false, message: error };
    }
};

export default AddInvoiceDataInFirebase;
