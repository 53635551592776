import React from 'react'

import { Link } from 'react-router-dom'
import { getImage } from '../Utils/image_helper'
export const SuccessPage = () => {
  return (
    <>
    <div className="success_popups">
    <div style={{ width: "100%" }}>
        <div style={{ textAlign: "center", marginBottom: "20px" }}>
            <img src={getImage("success")} alt="Success" height={300} width={300}/>
        </div>
        <h2 style={{ textAlign: "center", color: "#404040", fontSize: "30px", marginBottom: "10px" }}>
            Your Payment is successful
        </h2>
        <p style={{ textAlign: "center", color: "#787878", fontSize: "15px", wordSpacing: "1px" }}>
            Thank you for your payment. An automated payment receipt will be sent to your registered email.
        </p>
        <div className="backtohome" style={{ marginTop: "30px" }}>
            <Link to="/dashboard">
            <button style={{cursor:"pointer"}}> Back to Dashboard</button>
            </Link>
        </div>
    </div>
</div>
</>
  )
}
