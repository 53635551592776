import React, { useContext } from "react";
import MasterLayout from "../masterLayout/MasterLayout";
import Breadcrumb from "../components/Breadcrumb";
import { ConfigContext } from "../context/GlobalContext";
import CustomerListLayer from "../components/CustomerListLayer";


const CustomerListPage = () => {

  return (
    <>

      {/* MasterLayout */}
      <MasterLayout>

        {/* Breadcrumb */}
        <Breadcrumb title="Customers - List" />

        {/* InvoiceListLayer */}
        <CustomerListLayer />

      </MasterLayout>

    </>
  );
};

export default CustomerListPage;
