import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import HomePageSeven from "./pages/HomePageSeven";
import ErrorPage from "./pages/ErrorPage";
import InvoiceAddPage from "./pages/InvoiceAddPage";
import InvoiceEditPage from "./pages/InvoiceEditPage";
import InvoiceListPage from "./pages/InvoiceListPage";
import InvoicePreviewPage from "./pages/InvoicePreviewPage";
import SignInPage from "./pages/SignInPage";
import RouteScrollToTop from "./helper/RouteScrollToTop";
import { useEffect } from "react";
import { useContext } from "react";
import { ConfigContext } from "../src/context/GlobalContext";
import BusinessListPage from "./pages/BusinessListPage";
import CustomerListPage from "./pages/CustomerListPage";
import SoldItemsListPage from "./pages/SoldItemsListPage";
import BusinessAddPage from "./pages/BusinessAddPage";
import BusinessEditPage from "./pages/BusinessEditPage";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { ToastContainer } from "react-toastify";
import BusinessViewPage from "./pages/BusinessViewPage";
import CustomerEditPage from "./pages/CustomerEditPage";
import SessionPage from "./pages/SessionPage";
import CurrencyPage from "./pages/CurrencyPage";
import PaymentPage from "./pages/PaymentDataPage";
import { GetAllData } from "./data";
import PricingPlansPage from "./pages/PricingPlansPage";
import DownloadAppPage from "./pages/DownloadAppPage";
import CustomerViewPage from "./pages/CustomerViewPage";
import { SuccessPage } from "./pages/SuccessPage";

function App() {

  const { state, dispatch } = useContext(ConfigContext);

  useEffect(() => {
    GetAllData(state, dispatch)
  }, [])

  return (
    <BrowserRouter>
      <ToastContainer
        style={{ marginTop: '60px' }} // Adjust top margin
        theme={localStorage.getItem('theme') == 'dark' ? 'dark' : 'light'}
        pauseOnHover={false}
        newestOnTop
        autoClose={1000}
      />
      <RouteScrollToTop />
      <Routes>

        <Route path="/" element={(state?.USERID || localStorage.getItem("isLocal") == "true") ? <Navigate to="/dashboard" replace /> : <SignInPage />} />

        {/* login */}
        <Route exact path='/login' element={<SignInPage />} />

        {/* Dashboard */}
        <Route exact path='/dashboard' element={<HomePageSeven />} />

        {/* Business */}
        <Route exact path='/business-list' element={<BusinessListPage />} />
        <Route exact path='/business-add' element={<BusinessAddPage />} />
        <Route exact path='/business-edit' element={<BusinessEditPage />} />
        <Route exact path='/business-view' element={<BusinessViewPage />} />

        {/* Invoice */}
        <Route exact path='/invoice-add' element={<InvoiceAddPage />} />
        <Route exact path='/invoice-edit' element={<InvoiceEditPage />} />
        <Route exact path='/invoice-list' element={<InvoiceListPage />} />
        <Route exact path='/invoice-preview' element={<InvoicePreviewPage />} />

        {/* Customer */}
        <Route exact path='/customer-list' element={<CustomerListPage />} />
        <Route exact path='/customer-view' element={<CustomerViewPage />} />
        <Route exact path='/customer-edit' element={<CustomerEditPage />} />

        {/* Sold Ite */}
        <Route exact path='/sold-items-list' element={<SoldItemsListPage />} />

        {/* Currency */}
        <Route exact path='/currency' element={<CurrencyPage />} />

        {/* Sessions */}
        <Route exact path='/sessions' element={<SessionPage />} />

        {/* Payment */}
        <Route exact path='/payment' element={<PaymentPage />} />
        
        {/* Pricing */}
        <Route exact path='/pricing' element={<PricingPlansPage />} />

        {/* Download app */}
        <Route exact path='/download-app' element={<DownloadAppPage />} />

        <Route exact path='*' element={<ErrorPage />} />
{/* Success Page */}
        <Route exact path='/success' element={<SuccessPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
