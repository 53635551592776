import { ref, get } from "firebase/database";
import { database } from "../../../Utils/firebase";
import * as actionType from "../../../context/actions";
import GetCustomersData from "../../Comman/GetCustomersData";
import GetSoldItesmData from "../../Comman/GetSoldItesmData";
import GetEarnings from "../../Comman/GetEarnings";

const GetInvoiceDataFirebase = async (state, dispatch) => {
    if (!state?.USERID || state?.USERID == null || state?.USERID == undefined) return null;

    try {
        const userRef = ref(database, `invoiceGenerator/users/${state?.USERID}/billInvoice`);
        const snapshot = await get(userRef);
        const data = snapshot.val();

        GetEarnings(state, dispatch, Object.entries(data));
        GetCustomersData(state, dispatch, Object.entries(data));
        GetSoldItesmData(state, dispatch, Object.entries(data));

        const formattedData = Object.entries(data).map(([uniqueID, info]) => ({
            uniqueID, // Ensure we use the Firebase unique ID
            ...info, // Spread existing company data
        }));

        const sortedInvoiceData = formattedData.sort((a, b) => new Date(parseInt(b.date)) - new Date(parseInt(a.date)))
            .map((item, index) => ({
                srNo: index + 1, // Assign serial number after sorting
                ...item,
            }));
            
        dispatch({ type: actionType.INVOICES, payload: sortedInvoiceData });

        return sortedInvoiceData;
    } catch (error) {
        console.error("Error fetching inovice data:", error);
        return null;
    }
};

export default GetInvoiceDataFirebase;