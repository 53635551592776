import GetBusinessDataFirebase from "./Business/GetBusinessDataFirebase";
import GetInvoiceDataFirebase from "./Invoice/GetInvoiceDataFirebase";
import GetPaymentData from "./Payment/GetPaymentData";
import GetSessionData from "./Session/GetSessionData";


const FetchAllDataFromFirebase = async (state, dispatch) => {

    
    if (!state?.USERID || state?.USERID == null || state?.USERID == undefined) return null;

    try {
        const companyData = await GetBusinessDataFirebase(state, dispatch);
        const invoiceData = await GetInvoiceDataFirebase(state, dispatch);
        const SessionData = await GetSessionData(state, dispatch);
        const PaymentData = await GetPaymentData(state, dispatch);
        

        return { companyData, invoiceData, SessionData, PaymentData };
        
    } catch (error) {
        console.error("Error fetching all data:", error);
        return null;
    }

    
};

export default FetchAllDataFromFirebase;
