const IMAGES = {
  logo: "../assets/images/billnama-logo_dark.png",
  banner: "../assets/images/Login/new.png",
  businessform:"../assets/images/uploadimage.png",
  gradient: '../assets/images/nft-gradient-bg-rotated.png',
  viewIcon:'../assets/images/ViewIcon.svg',
  premium:'../assets/images/Frame.png',
  success:'../assets/images/sucess.gif',

  // profilePlaceholder: "/assets/profile-placeholder.png",
  // Add more images as needed
 
};

/**
 * Function to get an image by key.
 * @param {string} key - The key of the image.
 * @returns {string} - The image URL.
 */
export const getImage = (key) => IMAGES[key] || "/assets/default.png";
