
import React, { useEffect, useState } from 'react'
import { formatDate } from '../Utils/formate_date';
import { FilterdData } from '../helper/FilterdData';
import { useNavigate } from 'react-router-dom';
import DataNotFoundView from "./DataNotFoundView"

const PaymentData = ({ state }) => {

  const [paymentData, setPaymentData] = useState(state?.PAYMENT);
  useEffect(() => {
    setPaymentData(FilterdData(state, state?.PAYMENT, "paymentDate"));
  }, [state?.ACTIVEFILTER])
const navigate=useNavigate()
  return (
    <div className="card h-100 p-0">
      <div className="card-body p-24">
        
        <ul className="list-group radius-8 mt-3">
          {paymentData?.length > 0 ? (
            paymentData.map((payment, index) => (
              <React.Fragment key={index}>
                <li className="list-group-item border text-secondary-light p-16 bg-base border-bottom-0">
                  <div className="d-flex flex-column">
                    <span className="fw-bold">
                      <b>Device:</b> {payment.device || "N/A"}
                    </span>
                    <span>
                      <b>Plan Name:</b> {payment.planName || "N/A"}
                    </span>
                    <span>
                      <b>Payment Date:</b>{" "}
                      {payment.paymentDate ? formatDate(payment.paymentDate) : "N/A"}
                    </span>
                    <span>
                      <b>Payment Last Date:</b>{" "}
                      {payment.lastDate ? formatDate(payment.lastDate) : "N/A"}
                    </span>
                  </div>
                </li>
                <hr className="m-0" />
              </React.Fragment>
            ))
          ) : (
           <DataNotFoundView/>
          )}
        </ul>
      </div>
    </div>
  )
}

export default PaymentData