import * as actionType from "../../../context/actions";

const GetBusinessDataLocal = async (state, dispatch) => {
    try {

        const BUSINESS_DATA = localStorage.getItem("LOCAL_BUSINESSES");
        
        if (BUSINESS_DATA) {
            const formated_data = JSON.parse(BUSINESS_DATA).map((item, index) => ({
                id: index + 1,
                srNo: index + 1,
                ...item
            }));

            dispatch({ type: actionType.BUSINESSES, payload: formated_data });


            return formated_data;
        }
        else {
            dispatch({ type: actionType.BUSINESSES, payload: [] });
            return []
        }


    } catch (error) {
        console.error("Error fetching company data:", error);
        return null;
    }
};

export default GetBusinessDataLocal;