import { get, push, ref, set, update } from "firebase/database";
import { database } from "../../../Utils/firebase";
import GetPaymentData from "./GetPaymentData";

const AddPaymentDataInFirebase = async (state, dispatch, paymentData) => {

    if (!state?.USERID || state?.USERID == null) return null;

    try {

        const existingDataRef = ref(database, `invoiceGenerator/users/${state?.USERID}/premium/transactions`);
        const snapshot = await get(existingDataRef);
        const transaction = snapshot.val();

        if (transaction) {
            const userRef = ref(database, `invoiceGenerator/users/${state?.USERID}/premium`);

            await update(userRef, {
                device: "Website",
                isLifeTimePremium: "false",
                premiumLastDate: paymentData?.premiumLastDate?.toString(),
                subscriptionId: paymentData?.subscriptionId?.toString(),
                transactions: [
                    ...transaction,
                    {
                        device: "Website",
                        lastDate: paymentData?.premiumLastDate?.toString(),
                        paymentDate: paymentData?.paymentDate?.toString(),
                        planName: paymentData?.planName?.toString(),
                        subscriptionId: paymentData?.subscriptionId?.toString()
                    }
                ]
            })
        }
        else {

            const userRef = ref(database, `invoiceGenerator/users/${state?.USERID}/premium`);
            await set(userRef, {
                device: "Website",
                isLifeTimePremium: "false",
                premiumLastDate: paymentData?.premiumLastDate?.toString(),
                subscriptionId: paymentData?.subscriptionId?.toString(),
                transactions: [
                    {
                        device: "Website",
                        lastDate: paymentData?.premiumLastDate?.toString(),
                        paymentDate: paymentData?.paymentDate?.toString(),
                        planName: paymentData?.planName?.toString(),
                        subscriptionId: paymentData?.subscriptionId?.toString()
                    }
                ]
            })
        }

        // Fetch updated payment data
        await GetPaymentData(state, dispatch);

        return { success: true, message: "New invoice added successfully!" };
    } catch (error) {
        return { success: false, message: error };
    }
};

export default AddPaymentDataInFirebase;
