import React from 'react'
import BusinessAddForm from '../components/BusinessAddForm'
import MasterLayout from '../masterLayout/MasterLayout'

function BusinessAddPage() {
  return (
    <>
    <MasterLayout>
      <BusinessAddForm/>
      </MasterLayout>
    </>
  )
}

export default BusinessAddPage
