import React, { useContext, useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import { ConfigContext } from "../context/GlobalContext";
import * as actionType from "../context/actions";
import PaginationView from "./PaginationView";
import { FilterdData } from "../helper/FilterdData";
import DataNotFoundView from "./DataNotFoundView";
import { useNavigate } from "react-router-dom";

const BusinessTableDataLayer = ({ BusinessData }) => {

    const navigate = useNavigate();

    const { state, dispatch } = useContext(ConfigContext);
    const [filterdBusinessData, setFilterdBusinessData] = useState(BusinessData);

    useEffect(() => {
        setFilterdBusinessData(state?.BUSINESSES)
    }, [state?.BUSINESSES])

    useEffect(() => {
        setFilterdBusinessData(FilterdData(state, BusinessData, "creationDate"));
    }, [state?.ACTIVEFILTER])

    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);


    // Pagination logic
    const totalPages = Math.max(1, Math.ceil(filterdBusinessData?.length / itemsPerPage));

    const paginatedData = useMemo(() => {
        if (!filterdBusinessData?.length) return []; // Handle empty data case

        const start = (currentPage - 1) * itemsPerPage;

        return filterdBusinessData.slice(start, start + itemsPerPage).map(business => ({
            ...business,
            invoiceCount: state?.INVOICES?.filter(invoice => invoice?.companyId === business?.companyId)?.length || 0,
            customerCount: state?.CUSTOMERS?.filter(customer => customer?.companyId === business?.companyId)?.length || 0
        }));
    }, [filterdBusinessData, currentPage, itemsPerPage, state?.CUSTOMERS]);

    // Ensure currentPage is within range
    useEffect(() => {
        if (currentPage > totalPages) {
            setCurrentPage(totalPages);
        }
        if (currentPage < 1) {
            setCurrentPage(1);
        }
    }, [currentPage, totalPages]);


    const sortCaret = (order, column) => {
        return (
            <span className="d-inline-flex align-items-center ms-2" >
                {!order ? (
                    <Icon icon="mdi:swap-vertical" className="text-muted" /> // Two arrows pointing up & down
                ) : order === "asc" ? (
                    <Icon icon="mdi:arrow-up-thick" className="text-muted" />
                ) : (
                    <Icon icon="mdi:arrow-down-thick" className="text-muted" />
                )}
            </span>
        );
    };


    const { SearchBar } = Search;

    const columns = [
        {
            dataField: 'srNo',
            text: 'S.R',
            headerAlign: "center",
            align: "center",
            sort: true,
            formatter: (cell) => (
                <span style={{marginLeft: -8}}>{cell}</span>
            ),
            sort: true,
            sortCaret: sortCaret,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    OnRowClick(row?.companyId);
                }
            }
        },
        {
            dataField: 'companyPhoto',
            text: 'Business Logo',
            formatter: (cell, row) => (
                <img
                    src={cell || "https://cdni.iconscout.com/illustration/premium/thumb/no-data-found-illustration-download-in-svg-png-gif-file-formats--paper-website-business-pack-illustrations-7438848.png?f=webp"}
                    alt=""
                    className={`flex-shrink-0 me-12 radius-8 rounded-circle border border-secondary p-2`}
                    style={{ width: "50px", height: "50px", aspectRatio: "1 / 1", objectFit: 'contain' }}
                />
            ),
            headerStyle: { width: '10%' },
            sort: true,
            sortCaret: sortCaret,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    OnRowClick(row?.companyId);
                }
            }
        },
        {
            dataField: 'companyName',
            text: 'Business Name',
            headerStyle: { width: '25%' },
            sort: true,
            sortCaret: sortCaret,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    OnRowClick(row?.companyId);
                }
            }
        },
        {
            dataField: 'gstNumber',
            text: 'GST Number',
            headerStyle: { width: '15%' },
            sort: true,
            sortCaret: sortCaret,
            formatter: (cell) => cell && cell.trim() !== "" ? cell : "-",
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    OnRowClick(row?.companyId);
                }
            }
        },
        {
            dataField: 'invoiceCount',
            text: 'Invoice',
            headerAlign: "center",
            align: "center",
            formatter: (cell, row) => (
                <Link
                    to="/invoice-list"
                    className="text-primary-400"
                    onClick={() => {
                        const selectedBusiness = state?.BUSINESSES?.filter(business => business?.companyId === row?.companyId)[0]?.companyId;
                        dispatch({ type: actionType.SELECTEDBUSINESS, payload: selectedBusiness })
                    }}
                >
                    {state?.INVOICES?.filter(invoice => invoice?.companyId === row?.companyId)?.length}
                </Link>
            ),
            headerStyle: { width: '10%' },
            sort: true,
            sortCaret: sortCaret
        },
        {
            dataField: 'customerCount',
            text: 'Customers',
            headerAlign: "center",
            align: "center",
            formatter: (cell, row) => (
                <Link
                    to="/customer-list"
                    className="text-primary-400"
                    onClick={() => {
                        const selectedBusiness = state?.BUSINESSES?.filter(business => business?.companyId === row?.companyId)[0]?.companyId;


                        dispatch({ type: actionType.SELECTEDBUSINESS, payload: selectedBusiness })
                    }}
                >
                    {state?.CUSTOMERS?.filter(customer => customer?.companyId === row?.companyId)?.length}
                </Link>
            ),
            headerStyle: { width: '10%' },
            sort: true,
            sortCaret: sortCaret
        },
        {
            dataField: 'creationDate',
            text: 'Issued Date',
            formatter: (cell) => new Date(parseInt(cell?.length > 10 ? cell : cell * 1000)).toDateString(),
            headerStyle: { width: '10%' },
            sort: true,
            sortCaret: sortCaret,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    OnRowClick(row?.companyId);
                }
            }
        },
        {
            dataField: 'action',
            text: 'Action',
            formatter: (cell, row) => (
                <>
                    <Link
                        onClick={() => dispatch({ type: actionType.SELECTEDBUSINESS, payload: row.companyId })}
                        to="/business-view"
                        className="w-32-px h-32-px me-8 bg-primary-light text-primary-600 rounded-circle d-inline-flex align-items-center justify-content-center"
                    >
                        <Icon icon="iconamoon:eye-light" />
                    </Link>
                    <Link
                        to="/business-edit"
                        className="w-32-px h-32-px me-8 bg-success-focus text-success-main rounded-circle d-inline-flex align-items-center justify-content-center"
                        onClick={() => dispatch({ type: actionType.SELECTEDBUSINESS, payload: row.companyId })}
                    >
                        <Icon icon="lucide:edit" />
                    </Link>
                </>
            ),
            headerStyle: { width: '10%' },
        },
    ];

    const OnRowClick = (companyId) => {
        dispatch({ type: actionType.SELECTEDBUSINESS, payload: companyId })
        navigate("/business-view")
    }

    const handleSelection = (val) => {
        setItemsPerPage(val)
    };

    return (
        <div className="card">
            <div className="card-body">
                <ToolkitProvider keyField="invoiceId" data={paginatedData} columns={columns} search>
                    {props => (
                        <>
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                {/* Search Bar (Left) */}

                                <SearchBar
                                    {...props.searchProps}
                                    placeholder="Search invoices..."
                                    className="form-control w-auto"
                                />
                                <div className="d-flex flex-wrap align-items-center gap-3 display_business">

                                    <Link to={state?.PREMIUM == true ? "/business-add" : state?.BUSINESSES?.length == 0 ? "/business-add" : "/pricing"} className="btn btn-sm btn-primary-600">
                                        <i className="ri-add-line" /><span className="hide-text"> Create Business</span>
                                    </Link>
                                </div>
                            </div>
                            <div className={`table-responsive`}>
                                <BootstrapTable
                                    {...props.baseProps}
                                    bootstrap4
                                    bordered={false}
                                    wrapperClasses={`custom-table-border invoice-table ${localStorage.getItem("theme") == "dark" ? "table-dark" : "table-light"}`}
                                    defaultSorted={{
                                        dataField: "date",
                                        order: "desc",
                                    }}
                                    noDataIndication={() => <DataNotFoundView height={400} />}
                                    rowStyle={{cursor: 'pointer'}}
                                />
                            </div>

                            {/* Pagination UI */}
                            {paginatedData?.length > 0 && (<div className="d-flex justify-content-between align-items-center mt-3">
                                {/* Items Per Page Dropdown (Right) */}
                                <div className="d-flex align-items-center gap-2 entry_per_page">
                                    <div className="dropdown">
                                        <button
                                            className="btn btn-outline-primary-600 not-active px-18 py-6 dropdown-toggle toggle-icon"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            {itemsPerPage}
                                        </button>
                                        <ul className="dropdown-menu shadow-md">
                                            {[5, 10, 15, 20].map((value) => (
                                                <li key={value}>
                                                    <Link
                                                        className={`dropdown-item px-16 py-8 rounded text-secondary-light bg-hover-neutral-200 text-hover-neutral-900 ${itemsPerPage == value && "bg-primary-50"}`}
                                                        onClick={() => handleSelection(value)}
                                                    >
                                                        {value}
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <span className="text-secondary-light">entries per page</span>
                                </div>

                                <div className="ms-auto">
                                    <PaginationView
                                        currentPage={currentPage}
                                        totalPages={totalPages}
                                        handlePageChange={setCurrentPage}
                                    />
                                </div>
                            </div>)}
                        </>
                    )}
                </ToolkitProvider>
            </div>
        </div>
    );
};

export default BusinessTableDataLayer;
