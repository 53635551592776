import AddBusinessDataInFirebase from "./Firebase/Business/AddBusinessDataInFirebase";
import AddInvoiceDataInFirebase from "./Firebase/Invoice/AddInvoiceDataInFirebase";

export const AddLocalDataToFirebase = async (state, dispatch, userId) => {
  const businessData = JSON.parse(localStorage.getItem("LOCAL_BUSINESSES")) || [];
  const invoicedata = JSON.parse(localStorage.getItem("LOCAL_INVOICES")) || [];

  if (businessData.length > 0) {

    AddBusinessDataInFirebase(state, dispatch, businessData[0], userId, true);

    if (invoicedata.length > 0) {
      if (Array.isArray(invoicedata) && invoicedata.length > 0) {
        invoicedata.forEach((invoice) => {
          AddInvoiceDataInFirebase(state, dispatch, invoice, userId);
        });
      } else {
        console.error("No valid invoice data found in localStorage.");
      }
    }
  }
};