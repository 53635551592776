import React from "react";
import DownloadAppLayer from "../components/DownloadAppLayer";

const DownloadAppPage = () => {
  return (
    <>
      <DownloadAppLayer />
    </>
  );
};

export default DownloadAppPage;
