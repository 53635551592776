import axios from "axios";
import AddPaymentDataInFirebase from "../Firebase/Payment/AddPaymentDataInFirebase";

export const HandlePaymentSuccess = async (response, state, dispatch, navigate) => {
    const {
        razorpay_payment_id,
        razorpay_subscription_id,
        razorpay_signature,
    } = response;

    try {
        // Delay the API call to fetch subscription details
        setTimeout(async () => {
            const apiUrl =
                "https://payment-test-kappa.vercel.app/api/getSubscription";
            const res = await axios.post(apiUrl, {
                sub_id: razorpay_subscription_id,
            });

            if (res.data) {

                const planName = res?.data?.plan_id == "plan_P49Nh8gVYB4WhK" ? "Mothly" : "Yearly";

                const payment_data = {
                    premiumLastDate: res?.data?.current_end,
                    subscriptionId: res?.data?.id,
                    paymentDate: res?.data?.current_start,
                    planName: planName
                }
                
                AddPaymentDataInFirebase(state, dispatch, payment_data)

                console.log("Updated premium data sent to Firebase successfully!");
                navigate("/success");
                
            } else {
                console.error("No subscription details found.");
            }
        }, 10000); // 10 seconds delay for fetching subscription details
    } catch (error) {
        console.error("Error fetching subscription details:", error);
    }
};