import { Icon } from '@iconify/react/dist/iconify.js';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import SoldItemsTableDataLayer from './SoldItemsTableDataLayer';

const SoldItemsListLayer = ({ SoldItemsData }) => {

    return (
        <div className="card basic-data-table">
            {/* <div className="card-header d-flex flex-wrap align-items-center justify-content-between gap-3">
                <div className="d-flex flex-wrap align-items-center gap-3">

                    <Link to="/invoice-add" className="btn btn-sm btn-primary-600">
                        <i className="ri-add-line" /> Create Business
                    </Link>
                </div>
            </div> */}

            {/* Table */}
            <SoldItemsTableDataLayer SoldItemsData={SoldItemsData} />
        </div >


    );
};

export default SoldItemsListLayer;