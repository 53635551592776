import React, { useContext, useState } from "react";
import BusinessDataValidation from "../helper/BusinessDataValidation";
import { ConfigContext } from "../context/GlobalContext";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react/dist/iconify.js";
import { getImage } from "../Utils/image_helper";
import { toast } from "react-toastify";
import { AddBusiness } from "../data";
import GetBusinessDataLocal from "../data/LocalStorage/Business/GetBusinessDataLocal";

const BusinessAddForm = () => {
  const { state, dispatch } = useContext(ConfigContext);

  const navigate = useNavigate();
  const venderId = String(Math.floor(Math.random() * 9000000000000) + 1);
  const companyId = String(Number(venderId) + 1500920);
  const [businessData, setBusinessData] = useState({
    companyPhoto: "",
    companyPhotoURL: "",
    companyName: "",
    gstNumber: "",
    address: "",
    terms: "",
    companyId: companyId,
    creationDate: new Date().getTime(),
    isArchive: false,
    updateDate: new Date().getTime(),
  });

  const [error, setError] = useState({
    companyName: null,
  });

  const HandleChangeInBusinessData = (field, value) => {
    setBusinessData((prevData) => ({
      ...prevData,
      [field]: value,
    }));

    setError((prevData) => ({
      ...prevData,
      [field]: null,
    }));
  };

  const OnCreatePress = async () => {
    const { isValid, errors } = BusinessDataValidation(businessData);

    if (isValid) {
      const { success, message } = await AddBusiness(
        state,
        dispatch,
        businessData
      );
      if (success && message == "New company added successfully!") {
        navigate(-1);
        
        toast.success("Business successfully created!")
      }
      else {
        toast.error("Failed to create the business, Please try again!")
      }
    } else {
      console.log(errors);
      setError(errors);
    }
  };

  return (
    <div className="card shadow-lg p-4 w-100">
      <div className="card-header">

        <div className="d-flex flex-wrap align-items-center justify-content-between gap-2">
          {/* Left Side - Back/Close Button */}
          <div className="d-flex align-items-center gap-2">
            <button
              onClick={() => { navigate(-1) }}
            >
              <i class="fa fa-arrow-left" style={{ fontSize: 18}}></i>
            </button>
            <p className='fw-semibold mb-0' style={{ fontSize: 20}}>Add Business</p>
          </div>

          {/* Right Side - Other Buttons */}
          <div className="d-flex gap-2">
          </div>
        </div>
 
      </div>
      <div className="card-body">
  <div className="row">
    <form>
      <div className="row">
        <div className="col-12 col-md-3 text-center">
          <label className="form-label">Business Logo</label>
          {/* Image Preview */}
          <div className="mb-3 mt-3">
            {businessData?.companyPhoto ? (
              <label htmlFor="file" className="position-relative d-inline-block image-container">
                <div className="position-relative">
                  <img
                    id="preview"
                    className="img-fluid rounded-circle shadow-sm image-hover"
                    alt="Company Logo"
                    src={URL.createObjectURL(businessData?.companyPhoto)}
                    style={{
                      width: "160px",
                      height: "160px",
                      objectFit: "cover",
                      cursor: "pointer",
                      transition: "opacity 0.3s ease-in-out",
                    }}
                  />
                  <div className="image-overlay position-absolute top-0 start-0 w-100 h-100 rounded-circle"></div>
                  <div className="edit-icon position-absolute top-50 start-50 translate-middle d-flex align-items-center justify-content-center">
                    <Icon icon="lucide:edit" width="30" height="30" color="white" />
                  </div>
                </div>
              </label>
            ) : (
              <label htmlFor="file">
                <div className="d-flex align-items-center justify-content-center rounded-circle shadow-mg" style={{ width: "150px", height: "150px" }}>
                  <img src={getImage("businessform")} style={{ width: "80px", height: "80px" }} />
                </div>
              </label>
            )}
            <input
              type="file"
              id="file"
              name="companyPhoto"
              accept="image/*"
              style={{ display: "none" }}
              onChange={(e) => HandleChangeInBusinessData("companyPhoto", e.target.files[0])}
            />
          </div>
        </div>

        <div className="col-12 col-md-9">
          <div className="row mb-3">
            <div className="col-12 col-md-6">
              <label className="form-label">Business Name <span className="text-danger">*</span></label>
              <input
                type="text"
                className={`form-control wizard-required ${error.companyName ? "border-danger" : ""}`}
                placeholder="Enter Business Name"
                value={businessData.companyName}
                onChange={(e) => HandleChangeInBusinessData("companyName", e.target.value)}
              />
              {error.companyName && <div className="text-danger small mt-1">{error.companyName}</div>}
            </div>
            <div className="col-12 col-md-6">
              <label className="form-label">GST Number</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter GST Number"
                value={businessData.gstNumber}
                onChange={(e) => HandleChangeInBusinessData("gstNumber", e.target.value)}
              />
            </div>
          </div>
          <div className="mb-3">
            <label className="form-label">Address</label>
            <textarea
              className="form-control"
              rows="3"
              placeholder="Enter Address"
              value={businessData.address}
              onChange={(e) => HandleChangeInBusinessData("address", e.target.value)}
            ></textarea>
          </div>
        </div>
      </div>

      <div className="mb-3">
        <label className="form-label">T&C / Account Details</label>
        <textarea
          className="form-control"
          rows="3"
          placeholder="Enter Terms & Conditions"
          value={businessData.terms}
          onChange={(e) => HandleChangeInBusinessData("terms", e.target.value)}
        ></textarea>
      </div>

      <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mt-3">
        <button
          type="reset"
          onClick={() => navigate(-1)}
          className="border btn-sm border-danger-600 bg-hover-danger-200 text-danger-600 text-md px-40 py-6 radius-8"
        >
          Back
        </button>
        <button
          type="button"
          className="btn btn-sm btn-primary-600 radius-8 d-inline-flex align-items-center px-40 py-6 radius-8 gap-2"
          onClick={OnCreatePress}
        >
          Add
        </button>
      </div>
    </form>
  </div>
</div>

    </div>
  );
};

export default BusinessAddForm;
