import React, { useContext, useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import { ConfigContext } from "../context/GlobalContext";
import PaginationView from "./PaginationView";
import { FilterdData } from "../helper/FilterdData";
import GetSoldItemsData from "../data/Comman/GetSoldItesmData";
import DataNotFoundView from "./DataNotFoundView";
import * as actionType from '../context/actions';

const SoldItemsTableDataLayer = ({ SoldItemsData }) => {

    const { state, dispatch } = useContext(ConfigContext);
    const [selectedBusiness, setSelectedBusiness] = useState("null");
    const [matchedItems, setMatchedItems] = useState(SoldItemsData);
    // ✅ Pagination State
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);

    // ✅ Filter Data According to Selected Business
    useEffect(() => {
        GetSoldItems();
    }, [state?.ACTIVEFILTER, selectedBusiness])

    const GetSoldItems = async () => {
        let data = [];
        FilterdData(state, state?.INVOICES, "date")?.map((item) => {
            data.push([
                null,
                item
            ])
        })

        const Item_Data = await GetSoldItemsData(state, dispatch, data);

        let itemList = [];
        if (state?.SELECTEDBUSINESS != "null" && state?.SELECTEDBUSINESS != null) {
            itemList = Item_Data?.filter(item => item.companyId == state?.SELECTEDBUSINESS)
                ?.map((item, index) => ({
                    ...item,
                    srNo: index + 1
                }));
        }
        else {
            itemList = Item_Data?.map((item, index) => ({
                ...item,
                srNo: index + 1
            }));
        }
        setMatchedItems(itemList)

    }
    // ✅ Handle Pagination Data
    const totalPages = Math.ceil(matchedItems?.length / itemsPerPage);
    const paginatedData = useMemo(() => {
        const start = (currentPage - 1) * itemsPerPage;
        const data = matchedItems?.slice(start, start + itemsPerPage);

        // Append a new row (Example: Total Row or Custom Data)
        return [...data]

    }, [matchedItems, currentPage, itemsPerPage]);

    // ✅ Sort Caret Function
    const sortCaret = (order) => (
        <span className="d-inline-flex align-items-center ms-2">
            {!order ? (
                <Icon icon="mdi:swap-vertical" className="text-muted" />
            ) : order === "asc" ? (
                <Icon icon="mdi:arrow-up-thick" className="text-muted" />
            ) : (
                <Icon icon="mdi:arrow-down-thick" className="text-muted" />
            )}
        </span>
    );

    const { SearchBar } = Search;

    // ✅ BootstrapTable Columns
    const columns = [
        {
            dataField: 'srNo',
            text: 'S.R',
            headerAlign: "center",
            align: "center",
            sort: true,
            sortCaret: sortCaret,
            footer: "Total",
            footerAlign: "center",
            footerClasses: paginatedData.length > 0 ? "custom-table-footer" : "custom-table-footer-hiden",
        },

        {
            dataField: 'name',
            text: 'Item Name',
            sort: true,
            sortCaret: sortCaret,
            footer: "",
            footerAlign: "center",
            footerClasses: paginatedData.length > 0 ? "custom-table-footer" : "custom-table-footer-hiden",
        },
        {
            dataField: 'quantity',
            text: 'Quantity',
            headerAlign: "center",
            align: "center",
            sort: true,
            sortCaret: sortCaret,
            footerAlign: "center",
            footer: columnData => columnData.reduce((sum, row) => sum + parseFloat(row), 0),
            footerClasses: paginatedData.length > 0 ? "custom-table-footer" : "custom-table-footer-hiden",
        },
        {
            dataField: 'price',
            text: 'Price',
            formatter: (cell, row) => (
                <p className="text-md mb-0 fw-medium flex-grow-1 text-wrap">
                    {state?.SYMBOL} {row.price}
                </p>
            ),
            sort: true,
            style: { minWidth: "120px" },
            sortCaret: sortCaret,
                footer: columnData => `${state?.SYMBOL} ${parseFloat(columnData.reduce((sum, row) => sum + (parseFloat(row) || 0), 0)).toFixed(2)}`,
            footerClasses: paginatedData.length > 0 ? "custom-table-footer" : "custom-table-footer-hiden",
        },
        {
            dataField: 'amount',
            text: 'Total Amount',
            formatter: (cell, row) => (
                <p className="text-md mb-0 fw-medium flex-grow-1 text-wrap">
                    {state?.SYMBOL} {row.amount}
                </p>
            ),
            sort: true,
            style: { minWidth: "150px" },
            sortCaret: sortCaret,
            footer: columnData => `${state?.SYMBOL} ${parseFloat(columnData.reduce((sum, row) => sum + (parseFloat(row) || 0), 0)).toFixed(2)}`,
            footerClasses: paginatedData?.length > 0 ? "custom-table-footer" : "custom-table-footer-hiden",
        }
    ];

    // ✅ Handle Items Per Page Selection
    const handleSelection = (val) => {
        setItemsPerPage(val);
    };

    const handleBusinessSelection = (businessId) => {
        dispatch({type: actionType.SELECTEDBUSINESS, payload: businessId})
        setSelectedBusiness(businessId);
    };

    return (
        <div className="card">
            <div className="card-body">
                <ToolkitProvider keyField="srNo" data={paginatedData} columns={columns} search>
                    {props => (
                        <>
                            {/* ✅ Search + Length Change */}
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <SearchBar
                                    {...props.searchProps}
                                    placeholder="Search items..."
                                    className="form-control w-auto"
                                />

                                <div className="dropdown">
                                    <button
                                        className="btn btn-primary-600 bg-primary-50 border-primary-50 text-primary-600 hover-text-primary not-active px-18 py-11 dropdown-toggle toggle-icon"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        {(state?.SELECTEDBUSINESS == null || state?.SELECTEDBUSINESS == "null") ? "All Business" : state?.BUSINESSES?.find(business => business.companyId == state?.SELECTEDBUSINESS)?.companyName}
                                    </button>
                                    <ul className="dropdown-menu">
                                        {state?.BUSINESSES?.length > 0 && (<li>
                                            <Link
                                                onClick={() => handleBusinessSelection("null")}
                                                className={`dropdown-item px-16 py-8 rounded text-secondary-light bg-hover-neutral-200 text-hover-neutral-900 ${(state?.SELECTEDBUSINESS == null || state?.SELECTEDBUSINESS == "null") && "bg-primary-50"}`}
                                                to="#"
                                            >
                                                All Business
                                            </Link>
                                        </li>)}
                                        {state?.BUSINESSES?.length > 0 ? (
                                            state?.BUSINESSES?.map((business) => (
                                                <li key={business?.companyId}>
                                                    <Link
                                                        onClick={() => handleBusinessSelection(business?.companyId)}
                                                        className={`dropdown-item px-16 py-8 rounded text-secondary-light bg-hover-neutral-200 text-hover-neutral-900 ${business?.companyId == selectedBusiness && "bg-primary-50"}`}
                                                        to="#"
                                                    >
                                                        {business?.companyName}
                                                    </Link>
                                                </li>
                                            ))
                                        ) : (
                                            <li className="dropdown-item text-muted">No businesses available</li>
                                        )}
                                    </ul>
                                </div>

                            </div>

                            {/* ✅ Data Table */}
                            <div className="table-responsive">
                                <BootstrapTable
                                    {...props.baseProps}
                                    bootstrap4
                                    bordered={false}
                                    wrapperClasses={`custom-table-border invoice-table ${localStorage.getItem("theme") === "dark" ? "table-dark" : "table-light"}`}
                                    defaultSorted={[{
                                        dataField: "srNo",
                                        order: "asc"
                                    }]}
                                    noDataIndication={() => <DataNotFoundView height={400} />}
                                />
                            </div>

                            {/* ✅ Pagination */}
                            {paginatedData?.length > 0 &&(<div className="d-flex justify-content-between align-items-center mt-3">
                                <div className="d-flex align-items-center gap-2 entry_per_page">
                                    <div className="dropdown">
                                        <button
                                            className="btn btn-outline-primary-600 not-active px-18 py-6 dropdown-toggle toggle-icon"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            {itemsPerPage}
                                        </button>
                                        <ul className="dropdown-menu shadow-lg">
                                            {[5, 10, 15, 20].map((value) => (
                                                <li key={value}>
                                                    <Link
                                                        className={`dropdown-item px-16 py-8 rounded text-secondary-light bg-hover-neutral-200 text-hover-neutral-900 ${itemsPerPage == value && "bg-primary-50"}`}
                                                        onClick={() => handleSelection(value)}
                                                    >
                                                        {value}
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <span className="text-secondary-light">entries per page</span>
                                </div>

                                <div className="ms-auto">
                                    <PaginationView
                                        currentPage={currentPage}
                                        totalPages={totalPages}
                                        handlePageChange={setCurrentPage}
                                    />
                                </div>
                            </div>)}
                        </>
                    )}
                </ToolkitProvider>
            </div>
        </div>
    );
};

export default SoldItemsTableDataLayer;
