import * as actionType from "../../context/actions";

const GetCustomersData = async (state, dispatch, data) => {
    if (!data || !Array.isArray(data)) return null;

    try {
        const customerMap = new Map();

        data.forEach(([id, info]) => {

            if (!info || !info.companyId || !info.customer) return;

            const key = `${info.companyId}-${info.customer}-${info.email}-${info.phone}`;

            const amount = parseFloat(info.totalAmount) || 0; // Extract amount

            if (!customerMap.has(key)) {
                // First occurrence, initialize with invoice array and amount
                customerMap.set(key, {
                    customer: info.customer,
                    companyId: info.companyId,
                    phone: info.phone,
                    email: info.email,
                    customerGSTNo: info.customerGSTNo || null,
                    address: info.address || null,
                    invoices: info.invoiceId ? [info.invoiceId] : [],
                    totalAmount: amount, // Store total amount
                    date: info.creationDate
                });
            } else {
                const existing = customerMap.get(key);
                
                customerMap.set(key, {
                    ...existing,
                    customerGSTNo: info.customerGSTNo || existing.customerGSTNo, // Keep latest if available
                    address: info.address || existing.address, // Keep latest if available
                    invoices: info.invoiceId 
                        ? Array.from(new Set([...existing.invoices, info.invoiceId])) // Avoid duplicates
                        : existing.invoices,
                    totalAmount: existing.totalAmount + amount // Sum the amounts
                });
            }
        });

        // Convert map to an array and add serial numbers
        const uniqueCustomers = Array.from(customerMap.values()).map((item, index) => ({
            srNo: index + 1, // Serial Number
            ...item
        }));
        
        dispatch({ type: actionType.CUSTOMERS, payload: uniqueCustomers });

        return uniqueCustomers;
    } catch (error) {
        console.error("Error fetching customer data:", error);
        return null;
    }
};

export default GetCustomersData;
