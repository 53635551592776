import React, { useEffect, useState, useContext } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import { Link, NavLink, useLocation } from "react-router-dom";
import ThemeToggleButton from "../helper/ThemeToggleButton";
import { ConfigContext } from "../context/GlobalContext";
import PremiumCard from "../components/PremiumCard";
import { LogoutUser } from "../data/Firebase/Authentication";

const MasterLayout = ({ children }) => {

  let [mobileMenu, setMobileMenu] = useState(false);
  const location = useLocation();
  const { state, dispatch } = useContext(ConfigContext);
  const Emailshow = state?.USERID ? state?.SET_USER?.providerData[0].email: "";
  const UserShow = state?.USERID ? state?.SET_USER?.providerData[0].displayName ||"Unkown" : "";

  useEffect(() => {
    const handleDropdownClick = (event) => {
      event.preventDefault();
      const clickedLink = event.currentTarget;
      const clickedDropdown = clickedLink.closest(".dropdown");

      if (!clickedDropdown) return;

      const isActive = clickedDropdown.classList.contains("open");

      // Close all dropdowns
      const allDropdowns = document.querySelectorAll(".sidebar-menu .dropdown");
      allDropdowns.forEach((dropdown) => {
        dropdown.classList.remove("open");
        const submenu = dropdown.querySelector(".sidebar-submenu");
        if (submenu) {
          submenu.style.maxHeight = "0px"; // Collapse submenu
        }
      });

      // Toggle the clicked dropdown
      if (!isActive) {
        clickedDropdown.classList.add("open");
        const submenu = clickedDropdown.querySelector(".sidebar-submenu");
        if (submenu) {
          submenu.style.maxHeight = `${submenu.scrollHeight}px`; // Expand submenu
        }
      }
    };

    // Attach click event listeners to all dropdown triggers
    const dropdownTriggers = document.querySelectorAll(
      ".sidebar-menu .dropdown > a, .sidebar-menu .dropdown > Link"
    );

    dropdownTriggers.forEach((trigger) => {
      trigger.addEventListener("click", handleDropdownClick);
    });

    const openActiveDropdown = () => {
      const allDropdowns = document.querySelectorAll(".sidebar-menu .dropdown");
      allDropdowns.forEach((dropdown) => {
        const submenuLinks = dropdown.querySelectorAll(".sidebar-submenu li a");
        submenuLinks.forEach((link) => {
          if (
            link.getAttribute("href") === location.pathname ||
            link.getAttribute("to") === location.pathname
          ) {
            dropdown.classList.add("open");
            const submenu = dropdown.querySelector(".sidebar-submenu");
            if (submenu) {
              submenu.style.maxHeight = `${submenu.scrollHeight}px`; // Expand submenu
            }
          }
        });
      });
    };

    // Open the submenu that contains the active route
    openActiveDropdown();

    // Cleanup event listeners on unmount
    return () => {
      dropdownTriggers.forEach((trigger) => {
        trigger.removeEventListener("click", handleDropdownClick);
      });
    };
  }, [location.pathname]);
  const mailtoLink = `mailto:support@billnama.com?subject=Account deletion request for user%0D%0A${state?.USERID}&body=Hello,%0D%0A%0D%0A%0D%0APlease delete my account from BillNama having following details%0D%0A%0D%0AUserId: ${state?.USERID}%0D%0AEmail: ${Emailshow}%0D%0AReason: <Write Here your Reason>%0D%0A%0D%0A%0D%0ABest%0D%0A%0D%0A%0D%0ASent from Website`;

  let mobileMenuControl = () => {
    setMobileMenu(!mobileMenu);
  };

  return (
    <section className={mobileMenu ? "overlay active" : "overlay "}>
      {/* sidebar */}
      <aside
        className={
          mobileMenu
            ? "sidebar sidebar-open"
            : "sidebar"
        }
      >
        <button
          onClick={mobileMenuControl}
          type="button"
          className="sidebar-close-btn"
        >
          <Icon icon="radix-icons:cross-2" />
        </button>
        <div>
          <Link to="/" className="sidebar-logo">
            <img
              src="assets/images/billnama-logo_dark.png"
              alt="site logo"
              className="light-logo"
            />
            <img
              src="assets/images/billnama-logo_light.png"
              alt="site logo"
              className="dark-logo"
            />
            <img
              src="assets/images/billnama_icon.png"
              alt="site logo"
              className="logo-icon"
            />
          </Link>
        </div>
        <div className="sidebar-menu-area">
          <ul className="sidebar-menu" id="sidebar-menu">
            <li>
              <NavLink
                to="/dashboard"
                className={`${location.pathname.includes("dashboard") ? "active-page" : ""}`}
              >
                <Icon
                  icon="solar:home-smile-angle-outline"
                  className="menu-icon"
                />
                Dashboard
              </NavLink>
            </li>

            <li className="mt-3">
              <NavLink
                to="/business-list"
                className={`${location.pathname.includes("business") ? "active-page" : ""}`}
              >
                <Icon icon="tabler:building-store" className="menu-icon" />
                Business
              </NavLink>
            </li>

            <li className="mt-3">
              <NavLink
                to="/invoice-list"
                className={`${location.pathname.includes("invoice") ? "active-page" : ""}`}
              >
                <Icon icon="hugeicons:invoice-03" className="menu-icon" />
                Invoice
              </NavLink>
            </li>

            <li className="mt-3">
              <NavLink
                to="/customer-list"
                className={`${location.pathname.includes("customer") ? "active-page" : ""}`}
              >
                <Icon icon="heroicons-outline:users" className="menu-icon" />
                Customers
              </NavLink>
            </li>

            <li className="mt-3">
              <NavLink
                to="/sold-items-list"
                className={`${location.pathname.includes("sold-items") ? "active-page" : ""}`}
              >
                <Icon icon="mdi:cart-check" className="menu-icon" />
                Sold Items
              </NavLink>
            </li>

            <li className="mt-3">
              <NavLink
                to="/currency"
                className={(navData) =>
                  navData.isActive ? "active-page mt-3" : ""
                }
              >
                <Icon icon="mdi:cash-multiple" className="menu-icon" />
                Currency
              </NavLink>
            </li>
            
            <PremiumCard />

          </ul>
        </div>
      </aside>

      <main
        className={"dashboard-main"}
      >
        <div className="navbar-header">
          <div className="row align-items-center justify-content-between">
            <div className=" col-auto d-flex gap-2" >
              <button
                onClick={mobileMenuControl}
                type="button"
                className="sidebar-mobile-toggle"
              >
                <Icon icon="heroicons:bars-3-solid" className="icon" />
              </button>
              <div className="d-flex flex-wrap align-items-center gap-3">
                {state.USERID ? (<button
                  className="w-40-px h-40-px rounded-circle d-flex justify-content-center align-items-center"
                  type="button"
                  style={{ backgroundColor: "var(--brand)", color: "white" }}
                >
                  {Emailshow
                    ? Emailshow?.charAt(0).toUpperCase()
                    : UserShow?.charAt(0).toUpperCase() }
                </button>) :""}

                <p style={{ margin: "0px", color: "var(--brand)", fontFamily: 'sans-serif', fontWeight: 500, fontSize: 18 }}>
                  {state?.SET_USER?.providerData[0]?.displayName}
                </p>
               
              </div>
            </div>

            <div className="col-auto">
              <div className="d-flex flex-wrap align-items-center gap-3">
                {/* ThemeToggleButton */}
                <ThemeToggleButton />
                
                {state?.USERID ? (<div className="dropdown">
                  <button
                    className="w-40-px h-40-px bg-neutral-200 rounded-circle d-flex justify-content-center align-items-center"
                    type="button"
                    data-bs-toggle="dropdown"
                  >
                    <Icon
                      icon="icon-park-outline:setting-two"
                      className="  object-fit-cover rounded-circle"
                    />
                  </button>
                  <div className="dropdown-menu to-top dropdown-menu-sm">
                    <ul className="to-top-list">
                      <li>
                        <Link
                          className="dropdown-item text-black px-0 py-8 hover-bg-transparent hover-text-primary d-flex align-items-center gap-3"
                          to="/sessions"
                        >
                          <Icon
                            icon="mdi:clock-time-five-outline"
                            className="icon text-xl"
                          />{" "}
                          Sessions
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item text-black px-0 py-8 hover-bg-transparent hover-text-primary d-flex align-items-center gap-3"
                          to="/payment"
                        >
                          <Icon
                            icon="mdi:credit-card-outline"
                            className="icon text-xl"
                          />
                          Payment History
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item text-black px-0 py-8 hover-bg-transparent hover-text-primary d-flex align-items-center gap-3"
                          to={mailtoLink}
                        >
                          <Icon
                            icon="mdi:account-remove"
                            className="icon text-xl text-danger"
                          />{" "}
                          Delete my account
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item text-black px-0 py-8 hover-bg-transparent hover-text-danger d-flex align-items-center gap-3"
                          to="/login"
                          onClick={() => LogoutUser(dispatch)}
                        >
                          <Icon icon="lucide:power" className="icon text-xl" />{" "}
                          Log Out
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>) : (
                  <Link
                    to="/login"
                  // className="bg-primary-600 text-white font-semibold py-2 px-4 rounded-lg shadow-md hover:bg-primary-700 transition duration-300 ease-in-out transform hover:scale-105"
                  >
                    <button
                      type="reset"
                      className="border btn-sm border-primary-600 bg-hover-primary-200 text-primary-600 text-md px-40 py-6 radius-8"
                    >
                      Login
                    </button>
                  </Link>


                )}
                {/* Profile dropdown end */}
              </div>
            </div>
          </div>
        </div>

        {/* dashboard-main-body */}
        <div className="dashboard-main-body">{children}</div>

        {/* Footer section */}
        <footer className="d-footer">
          <div className="row align-items-center justify-content-between">
            <div className="col-auto">
              <p className="mb-0">
                © {new Date().getFullYear()}{" "}
                <Link to="https://billnama.com/" style={{ color: "#4c5fdf" }}>
                  billnama.com
                </Link>
                . All Rights Reserved.
              </p>
            </div>
          </div>
        </footer>
      </main>
    </section>
  );
};

export default MasterLayout;
