import React from "react";
import MasterLayout from "../masterLayout/MasterLayout";
import Breadcrumb from "../components/Breadcrumb";
import PricingPlanLayer from "../components/PricingPlanLayer";

const PricingPlansPage = () => {
  return (
    <>

      {/* MasterLayout */}
      <MasterLayout>

        {/* PricingLayer */}
        <PricingPlanLayer />

      </MasterLayout>

    </>
  );
};

export default PricingPlansPage; 
