import React from 'react'
import ReactApexChart from 'react-apexcharts'
import DataNotFoundView from '../DataNotFoundView';

const DonutChart = ({ state, totalPaidEarning, totalUnpaidEarning }) => {
    // Ensure the values are numbers and avoid NaN errors
    const paidEarnings = Number(totalPaidEarning) || 0;
    const unpaidEarnings = Number(totalUnpaidEarning) || 0;

    const isEmpty = paidEarnings === 0 && unpaidEarnings === 0;


    const customOptions = {
        chart: {
            type: "donut",
        },
        labels: ["Paid Earnings", "Unpaid Earnings"],
        colors: ["#ff9f29", "#8252e9"],
        legend: {
            position: "bottom",
        },
        dataLabels: {
            enabled: true,
            style: {
                fontSize: "14px",
                colors: ["#fff"],
            },
        }
    };

    return (
        <div className="col-md-6 h-100 w-100">
            <div className="card h-100 p-0 shadow-none">
                <div className="card-body p-24 text-center d-flex flex-wrap align-items-start gap-5 justify-content-center">


                    {isEmpty ? (<DataNotFoundView height={"200px"} />) : (<ReactApexChart id="pieChart" className="d-flex justify-content-center" options={customOptions} series={[paidEarnings, unpaidEarnings]} type="pie"
                        height={"100%"} width={"100%"}
                    />)}


                </div>
            </div>
        </div>
    );
};

export default DonutChart;
