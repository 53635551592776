import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ConfigContext } from "../context/GlobalContext";
import { Icon } from "@iconify/react/dist/iconify.js";
import * as actionType from '../context/actions';

const CustomerView = () => {
  const { state, dispatch } = useContext(ConfigContext);
  const navigate = useNavigate();

  const customer_data = state?.SELECTEDCUSTOMER;


  const onBack = () => {
    navigate(-1);
  };

  return (
    <div className="card shadow-lg p-4 w-80 mt-20 position-relative">
      <div className="card-header">

        <div className="d-flex flex-wrap align-items-center justify-content-between gap-2">
          {/* Left Side - Back/Close Button */}
          <div className="d-flex align-items-center gap-2">
            <button
              onClick={() => { navigate(-1) }}
            >
              <i class="fa fa-arrow-left" style={{ fontSize: 18 }}></i>
            </button>
            <p className='fw-semibold mb-0' style={{ fontSize: 20 }}>View Customer</p>
          </div>

          {/* Right Side - Other Buttons */}
          {/* <div className="d-flex gap-2">
            <button
              type="button"
              className="btn btn-md bg-success-focus text-success-main radius-8 d-inline-flex align-items-center px-40 py-6 radius-8 gap-2"
              onClick={() => navigate("/customer-edit")}
            >
              <Icon icon="lucide:edit" />
              Edit
            </button>
          </div> */}
        </div>

      </div>
      <div className="card-body">
        <div className="container">
          <div className="row">
            {/* First Row: Name & Phone */}
            <div className="col-12 col-lg-6 d-flex gap-2 mb-3">
              <span className="fw-medium text-primary-light">Name</span>
              <span className="text-secondary-light fw-regular">: {customer_data.customer}</span>
            </div>
            <div className="col-12 col-lg-6 d-flex gap-2 mb-3">
              <span className="fw-medium text-primary-light">Phone</span>
              <span className="text-secondary-light fw-regular">: {customer_data.phone}</span>
            </div>

            {/* Second Row: Email & GST */}
            <div className="col-12 col-lg-6 d-flex gap-2 mb-3">
              <span className="fw-medium text-primary-light">Email</span>
              <span className="text-secondary-light fw-regular">: {customer_data.email}</span>
            </div>
            <div className="col-12 col-lg-6 d-flex gap-2 mb-3">
              <span className="fw-medium text-primary-light">GST</span>
              <span className="text-secondary-light fw-regular">: {customer_data.customerGSTNo}</span>
            </div>

            {/* Third Row: Address (Full Width) */}
            <div className="col-12 d-flex gap-2 mb-3">
              <span className="fw-medium text-primary-light">Address</span>
              <span className="text-secondary-light fw-regular">: {customer_data.address}</span>
            </div>

            {/* Invoices */}
            <div className="col-12">
              <span className="fw-medium text-primary-light">Related Invoices:</span>
              <div className="row mt-3">
                {customer_data?.invoices?.map((id, index) => (
                  <div className="col-12 col-md-6" key={index}>
                    <Link
                      onClick={() => {
                        dispatch({ type: actionType.SELECTEDINVOICE, payload: id });
                      }}
                      to={"/invoice-preview"}
                      className="d-flex gap-2 mt-2 align-items-center"
                    >
                      <Icon icon="mdi:file-document" className="text-primary" />
                      <span className="text-secondary-light fw-regular text-decoration-underline">Invoice No. {state?.INVOICES?.find(invoice => invoice.invoiceId == id)?.id}</span>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>

        </div>
      </div>


      {/* Back Button */}
      <div className="align-self-end m-3">
        <button
          type="reset"
          onClick={() => {
            navigate(-1);
          }}
          className="border btn-sm border-danger-600 bg-hover-danger-200 text-danger-600 text-md px-40 py-6 radius-8"
        >
          Back
        </button>
      </div>
    </div>
  );
};

export default CustomerView;
