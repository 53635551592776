import { Icon } from '@iconify/react/dist/iconify.js'
import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { ConfigContext } from '../context/GlobalContext';
import LoaderView from './LoaderView';
import { CreateSubscription } from '../data/Payment/CreateSubscription';
import { HandlePayment } from '../data/Payment/HandlePayment';

const PricingPlanLayer = () => {

    const { state, dispatch } = useContext(ConfigContext);

    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState("INR");
    const [isLoading, setIsLoading] = useState(false);
    let Type;

    useEffect(() => {
        const handleTabChange = (event) => {
            const activeTabId = event.target.id; // Get the ID of the clicked tab
            setActiveTab(activeTabId === "pills-monthly-tab" ? "INR" : "USA");
        };

        const tabs = document.querySelectorAll('[data-bs-toggle="pill"]');
        tabs.forEach((tab) => tab.addEventListener("shown.bs.tab", handleTabChange));

        return () => {
            tabs.forEach((tab) => tab.removeEventListener("shown.bs.tab", handleTabChange));
        };
    }, []);

    const handleSubscriptionAndPayment = async (planId, type) => {
        setIsLoading(true);
        localStorage.setItem("type", type);
        Type = localStorage.getItem("type");
        const subscriptionId = await CreateSubscription(planId);
        if (subscriptionId) {
            HandlePayment(subscriptionId, state, dispatch, setIsLoading, navigate);
        }
    };

    return (
        <div className="card h-100 p-0 radius-12 overflow-hidden">
            
            {isLoading && (
                <LoaderView />
            )}
            <div className="card-body">
                <div className="row justify-content-center">
                    <div className="col-xxl-10">
                        <div className="text-center">
                            <h4 className="mb-16">Pricing Plan</h4>
                            <p className="mb-0 text-lg text-secondary-light">
                                No contracts. No surprise fees.
                            </p>
                        </div>
                        <ul
                            className="nav nav-pills button-tab mt-10 pricing-tab justify-content-center mb-3"
                            id="pills-tab"
                            role="tablist"
                        >
                            <li className="nav-item" role="presentation">
                                <button
                                    className="nav-link px-24 py-10 text-md rounded-pill text-secondary-light fw-medium active"
                                    id="pills-monthly-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-monthly"

                                    role="tab"
                                    aria-controls="pills-monthly"
                                    aria-selected="true"
                                >
                                    INR
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                    className="nav-link px-24 py-10 text-md rounded-pill text-secondary-light fw-medium"
                                    id="pills-yearly-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-yearly"

                                    role="tab"
                                    aria-controls="pills-yearly"
                                    aria-selected="false"
                                    tabIndex={-1}
                                >
                                    USA
                                </button>
                            </li>
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                            <div
                                className={`tab-pane fade show ${activeTab == "INR" && "active"}`}
                                id="pills-monthly"
                                role="tabpanel"
                                aria-labelledby="pills-monthly-tab"
                                tabIndex={0}
                            >
                                <div className="d-flex justify-content-center align-items-center flex-wrap">
                                    <div className="row gy-4 justify-content-center">
                                        <div className="col-xxl-4 col-sm-5 pricing-plan-wrapper">
                                            <div className="pricing-plan position-relative radius-24 overflow-hidden border bg-lilac-100">
                                                <div className="d-flex align-items-center gap-16">
                                                    {/* <span className="w-72-px h-72-px d-flex justify-content-center align-items-center radius-16 bg-base">
                                                        <img
                                                            src="assets/images/pricing/price-icon1.png"
                                                            alt=""
                                                        />
                                                    </span> */}
                                                    <div className="">
                                                        <h6 className="mb-0">Monthly Plan</h6>
                                                    </div>
                                                </div>
                                                <p className="mt-16 mb-0 text-secondary-light mb-4">
                                                    Start with a 3-day free trial. Stay hassle-free, ad-free, and unlimited—your journey starts here!{" "}
                                                </p>
                                                <h3 className="mb-3">
                                                    Rs.179{" "}
                                                    <span className="fw-medium text-md text-secondary-light">
                                                        /monthly
                                                    </span>{" "}
                                                </h3>
                                                <span className="mb-20 fw-medium">What’s included</span>
                                                <ul className="list-unstyled">
                                                    {[
                                                        "No Advertisement",
                                                        "Unlimited business registrations",
                                                        "Unlimited invoices",
                                                        "Unlimited invoice previews"
                                                    ].map((item, index) => (
                                                        <li key={index} className="d-flex align-items-center gap-16 mb-16">
                                                            <span className="w-24-px h-24-px d-flex justify-content-center align-items-center bg-lilac-600 rounded-circle">
                                                                <Icon icon="iconamoon:check-light" className="text-white text-lg" />
                                                            </span>
                                                            <span className="text-secondary-light text-lg">{item}</span>
                                                        </li>
                                                    ))}
                                                </ul>

                                                <button
                                                    onClick={() => {
                                                        handleSubscriptionAndPayment(
                                                            "plan_P49Nh8gVYB4WhK",
                                                            "monthly"
                                                        )
                                                    }}
                                                    className="bg-lilac-600 bg-hover-lilac-700 text-white text-center border border-lilac-600 text-sm btn-sm px-12 py-10 w-100 radius-8 mt-28"

                                                >
                                                    Get started
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-xxl-1 col-sm-1 pricing-plan-wrapper" />
                                        <div className="col-xxl-4 col-sm-5 pricing-plan-wrapper">
                                            <div className="pricing-plan position-relative radius-24 overflow-hidden border bg-success-100">
                                                <div className="d-flex align-items-center gap-16">
                                                    {/* <span className="w-72-px h-72-px d-flex justify-content-center align-items-center radius-16 bg-base">
                                                        <i class="bi bi-calendar-check text-success"></i>
                                                    </span> */}
                                                    <div className="">
                                                        <h6 className="mb-0">Yearly Plan</h6>
                                                    </div>
                                                </div>
                                                <p className="mt-16 mb-0 text-secondary-light mb-28">
                                                    Unlock 12 months of access and save up to 35%!, Stay hassle-free, ad-free, and unlimited!{" "}
                                                </p>
                                                <h3 className="mb-24">
                                                    Rs.1649 {" "}
                                                    <span className="fw-medium text-md text-secondary-light">
                                                        /yearly
                                                    </span>
                                                </h3>
                                                <span className="mb-20 fw-medium">What’s included</span>
                                                <ul className="list-unstyled">
                                                    {[
                                                        "No Advertisement",
                                                        "Unlimited business registrations",
                                                        "Unlimited invoices",
                                                        "Unlimited invoice previews"
                                                    ].map((item, index) => (
                                                        <li key={index} className="d-flex align-items-center gap-16 mb-16">
                                                            <span className="w-24-px h-24-px d-flex justify-content-center align-items-center bg-success-600 rounded-circle">
                                                                <Icon icon="iconamoon:check-light" className="text-white text-lg" />
                                                            </span>
                                                            <span className="text-secondary-light text-lg">{item}</span>
                                                        </li>
                                                    ))}
                                                </ul>
                                                <button
                                                    onClick={() =>
                                                        handleSubscriptionAndPayment(
                                                            "plan_P49OUSq59c09S2",
                                                            "yearly"
                                                        )
                                                    }
                                                    className="bg-success-600 bg-hover-success-700 text-white text-center border border-success-600 text-sm btn-sm px-12 py-10 w-100 radius-8 mt-28"

                                                >
                                                    Get Now
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`tab-pane fade show ${activeTab == "USA" && "active"}`}
                                id="pills-monthly"
                                role="tabpanel"
                                aria-labelledby="pills-monthly-tab"
                                tabIndex={0}
                            >
                                <div className="d-flex justify-content-center align-items-center flex-wrap">
                                    <div className="row gy-4 justify-content-center">
                                        <div className="col-xxl-4 col-sm-6 pricing-plan-wrapper">
                                            <div className="pricing-plan position-relative radius-24 overflow-hidden border bg-lilac-100">
                                                <div className="d-flex align-items-center gap-16">
                                                    {/* <span className="w-72-px h-72-px d-flex justify-content-center align-items-center radius-16 bg-base">
                                                        <img
                                                            src="assets/images/pricing/price-icon1.png"
                                                            alt=""
                                                        />
                                                    </span> */}
                                                    <div className="">
                                                        <h6 className="mb-0">Monthly Plan</h6>
                                                    </div>
                                                </div>
                                                <p className="mt-16 mb-0 text-secondary-light mb-28">
                                                    Start with a 3-day free trial. Stay hassle-free, ad-free, and unlimited—your journey starts here!{" "}
                                                </p>
                                                <h3 className="mb-24">
                                                    $3.99{" "}
                                                    <span className="fw-medium text-md text-secondary-light">
                                                        /monthly
                                                    </span>{" "}
                                                </h3>
                                                <span className="mb-20 fw-medium">What’s included</span>
                                                <ul className="list-unstyled">
                                                    {[
                                                        "No Advertisement",
                                                        "Unlimited business registrations",
                                                        "Unlimited invoices",
                                                        "Unlimited invoice previews"
                                                    ].map((item, index) => (
                                                        <li key={index} className="d-flex align-items-center gap-16 mb-16">
                                                            <span className="w-24-px h-24-px d-flex justify-content-center align-items-center bg-lilac-600 rounded-circle">
                                                                <Icon icon="iconamoon:check-light" className="text-white text-lg" />
                                                            </span>
                                                            <span className="text-secondary-light text-lg">{item}</span>
                                                        </li>
                                                    ))}
                                                </ul>

                                                <button
                                                    onClick={() => { navigate("/download-app") }}
                                                    className="bg-lilac-600 bg-hover-lilac-700 text-white text-center border border-lilac-600 text-sm btn-sm px-12 py-10 w-100 radius-8 mt-28"

                                                >
                                                    Get started
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-xxl-1 col-sm-6 pricing-plan-wrapper" />
                                        <div className="col-xxl-4 col-sm-6 pricing-plan-wrapper">
                                            <div className="pricing-plan position-relative radius-24 overflow-hidden border bg-success-100">
                                                <div className="d-flex align-items-center gap-16">
                                                    {/* <span className="w-72-px h-72-px d-flex justify-content-center align-items-center radius-16 bg-base">
                                                        <i class="bi bi-calendar-check text-success"></i>
                                                    </span> */}
                                                    <div className="">
                                                        <h6 className="mb-0">Yearly Plan</h6>
                                                    </div>
                                                </div>
                                                <p className="mt-16 mb-0 text-secondary-light mb-28">
                                                    Unlock 12 months of access and save up to 35%!, Stay hassle-free, ad-free, and unlimited—your journey starts here!{" "}
                                                </p>
                                                <h3 className="mb-24">
                                                    $32.99 {" "}
                                                    <span className="fw-medium text-md text-secondary-light">
                                                        /yearly
                                                    </span>
                                                </h3>
                                                <span className="mb-20 fw-medium">What’s included</span>
                                                <ul className="list-unstyled">
                                                    {[
                                                        "No Advertisement",
                                                        "Unlimited business registrations",
                                                        "Unlimited invoices",
                                                        "Unlimited invoice previews"
                                                    ].map((item, index) => (
                                                        <li key={index} className="d-flex align-items-center gap-16 mb-16">
                                                            <span className="w-24-px h-24-px d-flex justify-content-center align-items-center bg-success-600 rounded-circle">
                                                                <Icon icon="iconamoon:check-light" className="text-white text-lg" />
                                                            </span>
                                                            <span className="text-secondary-light text-lg">{item}</span>
                                                        </li>
                                                    ))}
                                                </ul>
                                                <button

                                                    className="bg-success-600 bg-hover-success-700 text-white text-center border border-success-600 text-sm btn-sm px-12 py-10 w-100 radius-8 mt-28"

                                                >
                                                    Get Now
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container text-center py-3">
                <a
                    href='https://www.billnama.com/privacy-policy/'
                    target="_blank"
                >
                    <p class="small mb-0 text-decoration-underline">
                        Terms and Privacy Policy
                    </p>
                </a>
            </div>
        </div>
    )
}

export default PricingPlanLayer