import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getImage } from "../Utils/image_helper";
import { ConfigContext } from '../context/GlobalContext'
import { GetAllData } from "../data";
import { LoginFunc } from "../data/Firebase/Authentication";
import { PaymentPopup } from "./PaymentPopup";
import LoaderView from './LoaderView';

const SignInLayer = () => {
  const { state, dispatch } = useContext(ConfigContext);
  const [paymentPopup, setPaymentPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(false)
  }, []);

  const DisplayPopup = () => {
    setPaymentPopup(true);
  };

  return (
    <section className="auth d-flex flex-wrap bg-light min-vh-100 align-items-center">
      {/* Left Section - Hidden on Small Screens */}
      <div className="auth-left d-none d-lg-flex align-items-center justify-content-center col-lg-5 p-4 rounded">
        <img
          src={getImage("banner")}
          alt="banner"
          className="img-fluid w-75"
        />
      </div>

      {/* Right Section */}
      <div className="auth-right col-lg-7 col-12 d-flex flex-column justify-content-center align-items-center p-5">
        <div className="max-w-464-px mx-auto w-100 text-center" style={{ maxWidth: "400px" }}>
          {/* Logo */}
          <Link to="/" className="mb-4 d-block">
            <img src={getImage("logo")} alt="logo" className="img-fluid" style={{ width: "70%" }} />
          </Link>

          {/* Heading */}
          <h4 className="mb-3 text-dark fw-bold fs-4 mt-5">Welcome back!</h4>
          <p className="mb-4 text-secondary">Sign in using your preferred method below</p>

          {/* Sign-in Buttons */}
          <div className="d-flex flex-column gap-3 mt-3 mb-3">
            {[
              { provider: "google", color: "#DB4437", icon: "logos:google-icon", text: "Sign In with Google" },
              { provider: "facebook", color: "#1877F2", icon: "ic:baseline-facebook", text: "Sign In with Facebook" },
              { provider: "apple", color: "#000", icon: "logos:apple", text: "Sign In with Apple" },
            ].map(({ provider, color, icon, text }) => (
              <button
                key={provider}
                type="button"
                className="btn d-flex align-items-center justify-content-center gap-2 py-3 rounded shadow-sm"
                onClick={() => {
                  setIsLoading(true)
                  LoginFunc(state, dispatch, provider, DisplayPopup)
                }}
                style={{ backgroundColor: "#fff", border: `1px solid ${color}`, color, fontWeight: "bold" }}
              >
                <Icon icon={icon} className="text-xl" />
                {text}
              </button>
            ))}
          </div>

          {/* Continue as Guest */}
          <div className="mt-4 text-center">
            <Link
              onClick={() => {
                localStorage.setItem("isLocal", "true");
                GetAllData(state, dispatch);
              }}
              to="/dashboard"
            >
              <button className="btn btn-link text-dark fw-bold text-decoration-underline" style={{ fontSize: "1rem" }}>
                Continue as Guest
              </button>
            </Link>
          </div>
        </div>
      </div>

      {isLoading && (<LoaderView />)}

      <PaymentPopup isOpen={paymentPopup} onClose={() => setPaymentPopup(false)} />
    </section>
  );
};

export default SignInLayer;
