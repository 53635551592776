import { ref, remove } from "firebase/database";
import { database } from "../../../Utils/firebase";
import GetInvoiceDataFirebase from "./GetInvoiceDataFirebase";

const DeleteInvoiceDataInFirebase = async (state, dispatch, uniqueID) => {

    if (!state?.USERID || state?.USERID == null) return null;

    try {
        
        // Reference to the specific invoice in Firebase
        const userRef = ref(database, `invoiceGenerator/users/${state?.USERID}/billInvoice/${uniqueID}`);

        // Remove the invoice
        await remove(userRef);

        // Fetch updated invoice data after deletion
        await GetInvoiceDataFirebase(state, dispatch);

        return { success: true, message: "Invoice deleted successfully!" };
    } catch (error) {
        return { success: false, message: error.message || "Failed to delete invoice." };
    }
};

export default DeleteInvoiceDataInFirebase;
