import React from "react";
import { Hourglass } from "react-loader-spinner";

const LoaderView = () => {
  return (
    <div className="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center bg-dark bg-opacity-50" style={{ zIndex: 1050 }}>
      <div
        className="d-flex justify-content-center align-items-center rounded-3 shadow"
        style={{
          width: "150px",
          height: "150px",
          backgroundColor: localStorage.getItem("theme") === "dark" ? "#1b2431" : "#f5f6fa",
        }}
      >
        <Hourglass
          visible={true}
          height="80"
          width="80"
          ariaLabel="hourglass-loading"
          colors={['#3b82f6', '#72a1ed']}
        />
      </div>
    </div>
  );
};

export default LoaderView;
