import React, { useContext } from "react";
import MasterLayout from "../masterLayout/MasterLayout";
import Breadcrumb from "../components/Breadcrumb";
import { ConfigContext } from "../context/GlobalContext";
import SoldItemsListLayer from "../components/SoldItemsListLayer";


const SoldItemsListPage = () => {

  const { state } = useContext(ConfigContext);

  return (
    <>

      {/* MasterLayout */}
      <MasterLayout>

        {/* Breadcrumb */}
        <Breadcrumb title="Sold Items - List" />

        {/* InvoiceListLayer */}
        <SoldItemsListLayer SoldItemsData={state?.SOLDITEMS} />

      </MasterLayout>

    </>
  );
};

export default SoldItemsListPage;
