import axios from "axios";

export const CreateSubscription = async (planId) => {
    const url = "https://backbone.billnama.com/api/createSubscription";
    const data = {
        plan_id: planId,
        total_count: 6,
        quantity: 1,
    };

    try {
        const response = await axios.post(url, data, {
            headers: {
                "Content-Type": "application/json",
            },
        });
        const { subscriptionId } = response.data;
        return subscriptionId;
    } catch (error) {
        console.error("Error creating subscription:", error);
        return null;
    }
};