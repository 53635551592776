import React,{useContext, useEffect}from "react";
import MasterLayout from "../masterLayout/MasterLayout";
import Breadcrumb from "../components/Breadcrumb";
import DashBoardLayerSeven from "../components/DashBoardLayerSeven";
import { ConfigContext } from '../context/GlobalContext'


const HomePageSeven = () => {
  
  const { state } = useContext(ConfigContext);
  
  return (
    <>
      {/* MasterLayout */}
      <MasterLayout>

        {/* Breadcrumb */}
        <Breadcrumb title="Dashboard" state={state}/>

        {/* DashBoardLayerSeven */}
        <DashBoardLayerSeven />

      </MasterLayout>
    </>
  );
};

export default HomePageSeven;
