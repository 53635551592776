import React, { useContext } from "react";
import MasterLayout from "../masterLayout/MasterLayout";
import Breadcrumb from "../components/Breadcrumb";
import InvoiceListLayer from "../components/InvoiceListLayer";
import { ConfigContext } from "../context/GlobalContext";


const InvoiceListPage = () => {

  const { state } = useContext(ConfigContext);

  return (
    <>

      {/* MasterLayout */}
      <MasterLayout>

        {/* Breadcrumb */}
        <Breadcrumb title="Invoice - List" />

        {/* InvoiceListLayer */}
        <InvoiceListLayer businessData={state?.BUSINESSES} invoiceData={state?.INVOICES}/>

      </MasterLayout>

    </>
  );
};

export default InvoiceListPage;
