import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ConfigContext } from "../context/GlobalContext";
import { getImage } from "../Utils/image_helper";

const PremiumCard = () => {
    const { state } = useContext(ConfigContext);
    const navigate = useNavigate();

    return (
        <div className="mt-3 px-2">
            {!state?.PREMIUM ? (
                <button
                    className="btn fw-bold border-0 px-4 py-3 position-relative"
                    style={{
                        borderRadius: "12px",
                        background: `url(${getImage("gradient")})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        color: "#fff",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "10px",
                        width: "100%", // Makes it responsive
                        maxWidth: "280px", // Adjust max width
                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Soft shadow for depth
                        padding: "12px 20px", // Proper padding for better touch experience
                    }}
                    onClick={() => {
                      
                        if(state?.USERID != null && state?.USERID != "null"){
                            navigate("/pricing")
                        }
                        else{
                            navigate("/login")
                        }
                            
                    }}
                >
                    <i className="fas fa-crown" style={{ fontSize: "18px", color: "gold" }}></i>
                    <span style={{ fontSize: "16px", fontWeight: "600" }}>Upgrade to Premium</span>
                </button>
            ) : (
                <button 
                    className="container mt-3"
                    onClick={() => {
                        navigate("/payment")
                    }}
                >
                    <div className="alert p-3 rounded bg-light border border-success shadow-sm" style={{
                         background: `url(${getImage("premium")})`,
                        backgroundSize: "cover",
                    }}>

                        <div className="d-flex align-items-center ">
                            <svg
                                aria-hidden="true"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                                className="text-white"
                                width="22"
                                height="22"
                            >
                                <path
                                    clipRule="evenodd"
                                    fillRule="evenodd"
                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                ></path>
                            </svg>
                            <p className="fw-semibold mb-0 ms-2 fs-5 text-white" style={{ fontSize: 16}}>Premium Access</p>
                        </div>


                        <div className="mt-2">
                            <span className="ms-5 text-white " style={{ fontSize: 14, marginLeft: 20 }}>Valid Until</span>
                            <div className="mt-1 p-2  text-white fw-bold rounded text-center" style={{background:"#4226c3"}}>
                                {state?.PREMIUMLASTDATE || "N/A"}
                            </div>
                        </div>
                    </div>
                </button>

            )}

        </div>
    );
};

export default PremiumCard;
