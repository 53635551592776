import React from "react";
import { useLottie } from "lottie-react";
import data_not_found from "../Utils/data_not_found.json";

const DataNotFoundView = ({ height = 300, width = "100%" }) => {
  const options = {
    animationData: data_not_found,
    loop: true,
    style: { height, width } // ✅ Setting height dynamically
  };

  const { View } = useLottie(options);

  return <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>{View}</div>;
};

export default DataNotFoundView;
