import { ref, update } from "firebase/database";
import { database } from "../../../Utils/firebase";
import GetInvoiceDataFirebase from "../Invoice/GetInvoiceDataFirebase";
import * as actionType from '../../../context/actions';

const EditCustomerDataInFirebase = async (state, dispatch, customerData) => {

    if (!state?.USERID || state?.USERID == null || state?.USERID == undefined) return null;

    try {


        await Promise.all(
            customerData.invoices.map(async (id) => {
                const Invoice_data = state?.INVOICES?.find(invoice => invoice.invoiceId == id);
                if (!Invoice_data) return; // Skip if invoice data is not found

                const userRef = ref(database, `invoiceGenerator/users/${state?.USERID}/billInvoice/${Invoice_data?.uniqueID}`);

                await update(userRef, {
                    companyId: Invoice_data.companyId,
                    creationDate: Invoice_data.creationDate,
                    customInvoiceId: Invoice_data.customInvoiceId,
                    date: Invoice_data.date,
                    discountAmount: Invoice_data.discountAmount,
                    discountPercetage: Invoice_data.discountPercetage,
                    dueDate: Invoice_data.dueDate,
                    gstAmount: Invoice_data.gstAmount,
                    gstPercetage: Invoice_data.gstPercetage,
                    id: Invoice_data.id,
                    invoiceId: Invoice_data.invoiceId,
                    isigst: Invoice_data.isigst,
                    items: Invoice_data.items,
                    note: Invoice_data.note,
                    paidFlagKey: Invoice_data.paidFlagKey,
                    totalAmount: Invoice_data.totalAmount,
                    updateDate: new Date().getTime().toString(),
                    customer: customerData?.customer || "",
                    phone: customerData?.phone || "",
                    email: customerData?.email || "",
                    customerGSTNo: customerData?.customerGSTNo || "",
                    address: customerData?.address || ""
                });
            })
        );

        GetInvoiceDataFirebase(state, dispatch);

        return { success: true, message: "Customer data updated successfully!" };
    } catch (error) {
        return { success: false, message: error.message || "An error occurred while updating the company" };
    }
};

export default EditCustomerDataInFirebase;
