
import React, { useContext, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import { ConfigContext } from "../context/GlobalContext";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import * as actionType from "../context/actions"
import { storeSessionData } from "../data/Firebase/Session/AddSession";
import { GetAllData } from "../data";
import { LogoutUser } from "../data/Firebase/Authentication";

export const PaymentPopup = ({ isOpen, onClose }) => {

  const { state, dispatch } = useContext(ConfigContext)

  const OnCancle = () => {
    onClose()
    dispatch({ type: actionType.EMPTY_ALL_STATE })
    LogoutUser(dispatch)
    window.location.replace("/dashboard");
  }

  const DeleteData = async () => {
    localStorage.removeItem("LOCAL_BUSINESSES")
    localStorage.removeItem("LOCAL_INVOICES")
    localStorage.removeItem("isLocal");
    await storeSessionData(state?.USERID);
    await GetAllData(state, dispatch);

    // ✅ Redirect after login
    window.location.replace("/dashboard");

  }

  return (
    <>



      <Modal show={isOpen} onHide={() => onClose()} centered>
        <Modal.Header>
          <Modal.Title className="fw-bold">Attention Please</Modal.Title>
        </Modal.Header>

        <Modal.Body className="text-muted">
          Your current account is limited to managing only one business.
          <strong> Upgrade to Premium</strong> to unlock multiple business
          management and exclusive features.
          <br />
          <br />
          If you continue without upgrading, your existing data will be lost.
          <br />
          <strong>What would you like to do?</strong>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => OnCancle()}>
            Cancel
          </Button>
          <Button variant="primary" className="text-white" onClick={() => DeleteData()} >
            Continue
          </Button>
          {/* <Button variant="danger" className="text-white" onClick={() => navigate("pricing")}>
            Get Premium
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  )
}
