
import React, { useEffect, useState } from 'react'
import { formatDate } from '../Utils/formate_date';
import { detectBrowser } from '../data/Firebase/Session/AddSession';

import { Icon } from '@iconify/react/dist/iconify.js';
import { FilterdData } from '../helper/FilterdData';
import { useNavigate } from 'react-router-dom';
import DataNotFoundView from './DataNotFoundView';
const SessionDatas = ({ state }) => {

  const currentBrowser = detectBrowser(navigator.userAgent);

  const getDeviceIcon = (deviceName) => {
    if (!deviceName) return <Icon icon="mdi:web" width="40" height="40" color="blue" />; // Default Web/Desktop icon
    const lowerDeviceName = deviceName.toLowerCase();

    if (lowerDeviceName.includes("android")) return <Icon icon="mdi:android" width="40" height="40" color="green" />;
    if (lowerDeviceName.includes("ios") || lowerDeviceName.includes("iphone")) return <Icon icon="mdi:apple" width="40" height="40" color="black" />;

    return <Icon icon="mdi:web" width="40" height="40" color="blue" />; // Default Web/Desktop icon
  };

  const [sessionData, setSessionData] = useState(state?.SESSIONS);

  useEffect(() => {
    setSessionData(FilterdData(state, state?.SESSIONS, "updateDate"));
  }, [state?.ACTIVEFILTER])

  const sortedSessions = sessionData
    ? [...sessionData].sort((a, b) =>
      a.browserName === currentBrowser ? -1 : 1
    )
    : [];

  const navigate = useNavigate()
  return (
    <div className="">
      <div className="card h-100 p-0">
        <div className="card-body p-24">
          <ul className="list-group radius-8 mt-3">
            {sortedSessions?.length > 0 ? (
              sortedSessions.map((session, index) => (
                <li
                  key={index}
                  className="list-group-item d-flex align-items-center border text-secondary-light p-16 bg-base"
                >
                  {/* Left Side - Green Dot */}

                  <div>
                    {session.browserName === currentBrowser && (
                      <span style={{ color: "#33f433", fontWeight: "bold" }}>⬤</span>
                    )}
                  </div>

                  {/* Middle - Device Info */}
                  <div className="flex-grow-1 d-flex flex-column px-3">
                    <span><span className="fw-bold">{session.deviceType}:</span>{session.deviceName || ""}</span>
                    <span>
                      <strong>Login at:</strong>{" "}
                      {session.updateDate ? formatDate(session.updateDate) : "N/A"}
                    </span>
                    <span>
                      <strong>For ID:</strong> {state?.USERID}
                    </span>
                  </div>

                  {/* Right Side - Device Image */}
                  <div className="d-flex align-items-center gap-3 " style={{ fontSize: "32px" }}>
                    {getDeviceIcon(session.deviceType)}
                  </div>
                </li>
              ))
            ) : (
              <DataNotFoundView />
            )}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default SessionDatas