import React, { useState, useContext } from "react";
import BusinessDataValidation from "../helper/BusinessDataValidation";
import { ConfigContext } from "../context/GlobalContext";
import { useNavigate } from "react-router-dom";
import EditBusinessData from "../data/Firebase/Business/EditBusinessDataInFirebase";
import { Icon } from "@iconify/react/dist/iconify.js";
import { getImage } from "../Utils/image_helper";
import { toast } from "react-toastify";
import { EditBusiness } from "../data";

const BusinessEditForm = () => {

  const { state, dispatch } = useContext(ConfigContext);

  const navigate = useNavigate();

  const business_data = state?.BUSINESSES?.find(business => business.companyId === state?.SELECTEDBUSINESS);

  const [businessData, setBusinessData] = useState({
    companyPhoto: null,
    companyPhotoURL: business_data?.companyPhoto || "",
    companyName: business_data?.companyName || "",
    gstNumber: business_data?.gstNumber || "",
    address: business_data?.address || "",
    companyId: business_data?.companyId || "",
    creationDate: business_data?.creationDate || "",
    isArchive: business_data?.isArchive || "",
    terms: business_data?.terms || "",
    updateDate: new Date().getTime(),
    id: business_data?.id
  });

  const [error, setError] = useState({
    companyName: null,
  })

  const HandleChangeInBusinessData = (field, value) => {
    setBusinessData((prevData) => ({
      ...prevData,
      [field]: value,
    }));

    setError((prevData) => ({
      ...prevData,
      [field]: null,
    }));
  };

  const OnUpdatePress = async () => {
    const { isValid, errors } = BusinessDataValidation(businessData);

    if (isValid) {
      const { success, message } = await EditBusiness(state, dispatch, businessData);
      if (success && message == "Company data updated successfully!") {
        navigate(-1)
        toast.success("Business successfully updated!")
      }
      else {
        toast.error("Failed to update the business, Please try again!")
      }

    }
    else {
      console.log(errors);
      setError(errors)
    }

  }
  const onBack = async () => {
    navigate(-1);
  }


  return (
    <div className="card shadow-lg p-4 w-80 mt-3">
      <div className="card-header">

        <div className="d-flex flex-wrap align-items-center justify-content-between gap-2">
          {/* Left Side - Back/Close Button */}
          <div className="d-flex align-items-center gap-2">
            <button
              onClick={() => {navigate(-1)}}
            >
              <i class="fa fa-arrow-left" style={{ fontSize: 18}}></i>
            </button>
            <p className='fw-semibold mb-0' style={{fontSize: 20}}>Edit Business</p>
          </div>

          {/* Right Side - Other Buttons */}
          <div className="d-flex gap-2">
          </div>
        </div>
        {/* <div className="d-flex flex-wrap align-items-center justify-content-end gap-2">
    <button
        type="button"
        className="btn btn-sm btn-primary-600 radius-8 d-inline-flex align-items-center gap-1"
    >
        <Icon icon="simple-line-icons:check" className="text-xl" />
        Save
    </button>
</div> */}
      </div>
      <div className="card-body">
        <div className="row">
          <form>
            <div className="row">
              <div className="col-sm-12 col-md-3 text-center">
                <label className="form-label">
                  Business Logo
                </label>
                {/* Image Preview */}
                <div className="mb-3 mt-3">
                  {(businessData.companyPhoto || businessData.companyPhotoURL) ? (
                    <label
                      htmlFor="file"
                      className="position-relative d-inline-block image-container"
                    >
                      <div className="position-relative">
                        {/* Image Preview */}
                        <img
                          id="preview"
                          className="img-fluid rounded-circle shadow-sm image-hover"
                          alt="Company Logo"
                          src={businessData.companyPhoto ? URL.createObjectURL(businessData.companyPhoto) : businessData.companyPhotoURL}
                          style={{
                            width: "160px",
                            height: "160px",
                            objectFit: "cover",
                            cursor: "pointer",
                            transition: "opacity 0.3s ease-in-out",
                          }}
                        />

                        {/* Overlay Effect */}
                        <div className="image-overlay position-absolute top-0 start-0 w-100 h-100 rounded-circle"></div>

                        {/* Edit Icon on Hover */}
                        <div className="edit-icon position-absolute top-50 start-50 translate-middle d-flex align-items-center justify-content-center">
                          <Icon
                            icon="lucide:edit"
                            width="30"
                            height="30"
                            color="white"
                          />
                        </div>
                      </div>
                    </label>
                  ) : (
                    <label htmlFor="file">
                      <div
                        className="d-flex align-items-center justify-content-center rounded-circle shadow-mg"
                        style={{
                          width: "150px",
                          height: "150px",
                        }}
                      >
                        < img
                          src={getImage("businessform")}
                          style={{
                            width: "80px",
                            height: "80px",
                          }}
                        />
                      </div>
                    </label>
                  )}

                  <input
                    type="file"
                    id="file"
                    name="companyPhoto"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      HandleChangeInBusinessData(
                        "companyPhoto",
                        e.target.files[0]
                      );
                    }}
                  />
                </div>
              </div>

              <div className="col-sm-12 col-md-9">
                <div className="row mb-3">
                  <div className="col-sm-12 col-md-6">
                    <label className="form-label">
                      Business Name <span className="text-danger"> *</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control wizard-required ${error.companyName ? "border-danger" : ""}`}
                      placeholder="Enter Business Name"
                      value={businessData.companyName}
                      onChange={(e) =>
                        HandleChangeInBusinessData(
                          "companyName",
                          e.target.value
                        )
                      }
                    />
                    {error.companyName && (
                      <div className="text-danger small mt-1">
                        {error.companyName}
                      </div>
                    )}
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <label className="form-label">GST Number</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter GST Number"
                      value={businessData.gstNumber}
                      onChange={(e) =>
                        HandleChangeInBusinessData(
                          "gstNumber",
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <label className="form-label">Address</label>
                  <textarea
                    className="form-control"
                    rows="3"
                    placeholder="Enter Address"
                    value={businessData.address}
                    onChange={(e) =>
                      HandleChangeInBusinessData("address", e.target.value)
                    }
                  ></textarea>
                </div>
              </div>
            </div>

            <div>
              {/* Terms & Conditions */}
              <div className="mb-3">
                <label className="form-label">T&C / Account Details</label>
                <textarea
                  className="form-control"
                  rows="3"
                  placeholder="Enter Terms & Conditions"
                  value={businessData.terms}
                  onChange={(e) =>
                    HandleChangeInBusinessData("terms", e.target.value)
                  }
                ></textarea>
              </div>
            </div>

            <div className="d-flex flex-wrap align-items-center justify-content-end gap-2">
              <div className="d-flex gap-2 ">
                <button
                  type="reset"
                  onClick={() => {
                    navigate(-1);
                  }}
                  className="border btn-sm border-danger-600 bg-hover-danger-200 text-danger-600 text-md px-40 py-6 radius-8"
                >
                  Back
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-primary-600 radius-8 d-inline-flex align-items-center px-40 py-6 radius-8 gap-2"
                  onClick={OnUpdatePress}
                >
                  Update
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

  );
};

export default BusinessEditForm;
