import FetchAllDataFromFirebase from "./Firebase/FetchAllDataFromFirebase";
import AddBusinessDataInLocal from "./LocalStorage/Business/AddBusinessDataInLocal"
import FetchAllDataFromLocalStorage from "./LocalStorage/FetchAllDataFromLocalStorage";
import AddInvoiceDataInLocal from "./LocalStorage/Invoice/AddInvoiceDataInLocal";
import EditBusinessDataLocal from "./LocalStorage/Business/EditBusinessDataLocal";
import EditInvoiceDataInLocal from "./LocalStorage/Invoice/EditInvoiceDataInLocal";
import DeleteInvoiceData from "./Firebase/Invoice/DeleteInvoiceDataInFirebase";
import DeleteInvoiceDataInLocal from "./LocalStorage/Invoice/DeleteInvoiceDataInLocal";
import AddBusinessDataInFirebase from "./Firebase/Business/AddBusinessDataInFirebase";
import EditBusinessDataInFirebase from "./Firebase/Business/EditBusinessDataInFirebase";
import AddInvoiceDataInFirebase from "./Firebase/Invoice/AddInvoiceDataInFirebase";
import EditInvoiceDataInFirebase from "./Firebase/Invoice/EditInvoiceDataInFirebase";
import EditCustomerDataInFirebase from "./Firebase/Customers/EditCustomerData";
import EditCustomerInLocal from "./LocalStorage/Customer/EditCustomerInLocal";
import AddPaymentDataInFirebase from "./Firebase/Payment/AddPaymentDataInFirebase";

export const GetAllData = async (state, dispatch) => {
    
    if (state?.USERID) {
        const { companyData, invoiceData, SessionData, PaymentData } = await FetchAllDataFromFirebase(state, dispatch);
        return { companyData, invoiceData, SessionData, PaymentData };
    }
    else {
        localStorage.removeItem("appState")
        // dispatch({ type: actionType.EMPTY_ALL_STATE })
        const { companyData, invoiceData, SessionData, PaymentData } = await FetchAllDataFromLocalStorage(state, dispatch);
        return { companyData, invoiceData, SessionData, PaymentData };
    }
}

export const AddBusiness = async (state, dispatch, businessdata) => {
    if (state?.USERID != null) {
        const { success, message } = await AddBusinessDataInFirebase(state, dispatch, businessdata);
        return { success: success, message: message };
    }
    else {
        const { success, message } = await AddBusinessDataInLocal(state, dispatch, businessdata);
        return { success: success, message: message };
    }
}

export const EditBusiness = async (state, dispatch, businessdata) => {
    
    if (state?.USERID != null) {
        const { success, message } = await EditBusinessDataInFirebase(state, dispatch, businessdata);
        return { success: success, message: message };
    }
    else {
        const { success, message } = await EditBusinessDataLocal(state, dispatch, businessdata);
        return { success: success, message: message };
    }
}

export const AddInvoice = async(state, dispatch, invoiceData) => {
    if (state?.USERID != null) {
        const { success, message } = await AddInvoiceDataInFirebase(state, dispatch, invoiceData);
        return { success: success, message: message };
    }
    else {
        const { success, message } = await AddInvoiceDataInLocal(state, dispatch, invoiceData);
        return { success: success, message: message };
    }
}

export const EditInvoice = async(state, dispatch, id, invoiceData) => {
    
    if (state?.USERID != null) {
        const { success, message } = await EditInvoiceDataInFirebase(state, dispatch, id, invoiceData);
        return { success: success, message: message };
    }
    else {
        const { success, message } = await EditInvoiceDataInLocal(state, dispatch, id, invoiceData);
        return { success: success, message: message };
    }
}

export const DeleteInvoice = async(state, dispatch, uniqueID) => {
    
    if (state?.USERID != null) {
        const { success, message } = await DeleteInvoiceData(state, dispatch, uniqueID);
        return { success: success, message: message };
    }
    else {
        const { success, message } = await DeleteInvoiceDataInLocal(state, dispatch, uniqueID);
        return { success: success, message: message };
    }
}

export const EditCustomer = async(state, dispatch, customerData) => {
    
    if (state?.USERID != null) {
        const { success, message } = await EditCustomerDataInFirebase(state, dispatch, customerData);
        return { success: success, message: message };
    }
    else {
        const { success, message } = await EditCustomerInLocal(state, dispatch, customerData);
        return { success: success, message: message };
    }
}

export const UpdatePremium = async(state, dispatch, paymentData) => {
    if (state?.USERID != null) {
        const { success, message } = await AddPaymentDataInFirebase(state, dispatch, paymentData);
        return { success: success, message: message };
    }
}