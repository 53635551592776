import * as actionType from "../../../context/actions";
import GetCustomersData from "../../Comman/GetCustomersData";
import GetEarnings from "../../Comman/GetEarnings";
import GetSoldItemsData from "../../Comman/GetSoldItesmData";

const GetInvoiceDataLocal = async (state, dispatch) => {
    try {

        const INVOICE_DATA = localStorage.getItem("LOCAL_INVOICES");

        if (INVOICE_DATA) {
            const data = Object.entries(JSON.parse(INVOICE_DATA)).map(([key, item]) => [
                key,
                {
                    localId: (new Date().getTime() / 1000).toFixed(0),
                    id: key,
                    srNo: 1, // You can modify this if needed
                    ...item
                }
            ]);

            GetEarnings(state, dispatch, data);
            GetCustomersData(state, dispatch, data);
            GetSoldItemsData(state, dispatch, data);

            const formattedData = Object.entries(data).map(([localId, info]) => ({
                ...info[1],
            }));


            const sortedInvoiceData = formattedData.sort((a, b) => b.id - a.id)
                .map((item, index) => ({
                    srNo: index + 1, // Assign serial number after sorting
                    ...item,
                }));

            dispatch({ type: actionType.INVOICES, payload: sortedInvoiceData });
        
            
            return sortedInvoiceData;
        }
        else {
            dispatch({ type: actionType.INVOICES, payload: [] });
            return []
        }


    } catch (error) {
        console.error("Error fetching company data:", error);
        return null;
    }
};

export default GetInvoiceDataLocal;