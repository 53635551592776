import React,{useContext}from 'react'
import SessionDatas from '../components/SessionData'
import MasterLayout from '../masterLayout/MasterLayout'
import Breadcrumb from '../components/Breadcrumb'
import { ConfigContext } from '../context/GlobalContext'
import PaymentData from '../components/PaymentData'
const PaymentPage = () => {
    const { state } = useContext(ConfigContext);

  return (
    <div>
        <MasterLayout>
            <Breadcrumb title="Payment"/>
      <PaymentData state={state}/>
      </MasterLayout>
    </div>
  )
}

export default PaymentPage
