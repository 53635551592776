import { signInWithPopup } from "firebase/auth";
import { auth, googleProvider } from "../../../Utils/firebase";

export const LoginWithGoogle = async () => {
    try {
        const result = await signInWithPopup(auth, googleProvider);
        return { sucess: true, message: "Login Successfully", data: result };
    }
    catch (error) {
        console.log("Google Login Error:", error.message);
        return { sucess: false, message: error.message, data: null };
    }
}